// <!-- VUETIFY2 - OK -->
import VDatatableAffectationInte from "./VDatatableAffectationInte";
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";
import Utils from "@/utils";
import {TOAST} from "@/plugins/toast/definition";

/**
 * Composant affichant l'affectation d'un bénéficiaire connecté
 * @display_name Intéressement - VDatatableAffectationInteEspaceIndividuel
 */
export default {
  extends: VDatatableAffectationInte,
  name: "v-datatable-affectation-inte-espace-individuel",
  data() {
    return {
        displayAffectedGrey: false,
        isEspacePersonnel: true,
    };
  },
  mounted(){
      this.canEditVersementImmediat = this.$store.getters.hasDroitAcces(
        constantes.droits_acces.noms.espace_inte_affectation_versement_immediat,
        [constantes.droits_acces.valeurs_possibles.edition],
      )
      this.canEditAffectationSupports = this.$store.getters.hasDroitAcces(
        constantes.droits_acces.noms.espace_inte_affectation_supports,
        [constantes.droits_acces.valeurs_possibles.edition],
      )
  },
  methods: {
      updateDataTable() {
          this.loadingPeriodes = true;

          return new Promise((resolve, reject) => {

              if (this.selectedExercice == null) {
                // No exercice selected
                resolve({
                    'items': [],
                    'total': 0
                });
                this.loadingPeriodes = false;
              }

              const {sortBy, sortDesc, page, itemsPerPage} = this.options;

              internalApi.affectationInte.getInteAffectationIndividuelByInteExerciceId(
                  this.selectedExercice.id,
                  page,
                  itemsPerPage,
                  sortBy[0],
                  sortDesc[0],
              ).then(res => {
                  this.localInteSupports = res.supports;
                  this.allowsEditVersementImmediat = res.allows_edit_versement_immediat;
                  this.initPlacementsTemp(res);
                  resolve({
                      'items': res.affectations,
                      'total': res.total_items
                  });
              }).catch(err => {
                  console.error("Erreur lors de la récupération des affectations de l'intéressement : ", err);
                  reject({
                      'items': [],
                      'total': 0
                  });
              }).finally(() => {
                  this.loadingPeriodes = false;
              });
          });
      },
  },
  watch: {
    selectedExercice: function(){
      if (! _.isNil(this.selectedExercice.id)) {
        if (!this.selectedExercice.inte_exercice.is_repartition_inte_valide){
          TOAST.warning("Affectation inaccessible. Cet exercice n'a pas encore été validé en AG.", Utils.EMPTY_STRING);
          this.localAffectations = [];
        } else {
          this.getAllAffectationParBeneficiaireSync();
        }
      }
    }
  }
};