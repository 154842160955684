var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fieldset',[_c('legend',[_vm._v("Identification")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localCivilite","name":"Civilité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Civilité","items":_vm.selectItems.civilite,"error-messages":errors},model:{value:(_vm.localCivilite),callback:function ($$v) {_vm.localCivilite=$$v},expression:"localCivilite"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localFirstName","name":"Prénom","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Prénom","error-messages":errors},model:{value:(_vm.localFirstName),callback:function ($$v) {_vm.localFirstName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"localFirstName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localLastName","name":"Nom","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nom","error-messages":errors},model:{value:(_vm.localLastName),callback:function ($$v) {_vm.localLastName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"localLastName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localNumeroTiers","name":"Numéro de tiers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Numéro de tiers","error-messages":errors,"disabled":_vm.isEspacePersonnel},model:{value:(_vm.localNumeroTiers),callback:function ($$v) {_vm.localNumeroTiers=$$v},expression:"localNumeroTiers"}})]}}])})],1)],1)],1),_c('fieldset',[_c('legend',[_vm._v("Naissance")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localDateNaissance","name":"Date de naissance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":"Date de naissance","errors":errors},model:{value:(_vm.localDateNaissance),callback:function ($$v) {_vm.localDateNaissance=$$v},expression:"localDateNaissance"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localCodePostalNaissance","name":"Code postal de naissance","rules":{ max: 20, required: this.infos_ville_naissance_required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Code postal de naissance","error-messages":errors},model:{value:(_vm.localCodePostalNaissance),callback:function ($$v) {_vm.localCodePostalNaissance=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"localCodePostalNaissance"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localCommuneNaissance","name":"Commune de naissance","rules":{ regex: /^([\xC0-\xFFa-zA-Z- '()]+)$/, max: 80, required: this.infos_ville_naissance_required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Commune de naissance","error-messages":errors},model:{value:(_vm.localCommuneNaissance),callback:function ($$v) {_vm.localCommuneNaissance=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"localCommuneNaissance"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localPaysNaissance","name":"Pays de naissance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-countries-list',{attrs:{"label":"Pays de naissance","errors":errors},model:{value:(_vm.localPaysNaissance),callback:function ($$v) {_vm.localPaysNaissance=$$v},expression:"localPaysNaissance"}})]}}])})],1)],1)],1),_c('fieldset',[_c('legend',[_vm._v("Contact")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localEmail","name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors},model:{value:(_vm.localEmail),callback:function ($$v) {_vm.localEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"localEmail"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localAcceptEnvoiDemat","name":"Accepte les envois dématérialisés","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Accepte les envois dématérialisés","false-value":"false","error-messages":errors},model:{value:(_vm.localAcceptEnvoiDemat),callback:function ($$v) {_vm.localAcceptEnvoiDemat=$$v},expression:"localAcceptEnvoiDemat"}})]}}])})],1)],1)],1),(!_vm.isEspacePersonnel)?_c('fieldset',[_c('legend',[_vm._v("Spécificités fiscales")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-checkbox',{attrs:{"label":"Renonciation au prélèvement forfaitaire","hide-details":"","false-value":"false"},model:{value:(_vm.localRenonciationPf),callback:function ($$v) {_vm.localRenonciationPf=$$v},expression:"localRenonciationPf"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},Object.assign({}, on)),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,false,79527337)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageRenonciationPrelevementForfaitaire)}})])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-checkbox',{attrs:{"label":"Exonération des charges sociales","hide-details":"","false-value":"false"},model:{value:(_vm.localExonerationCs),callback:function ($$v) {_vm.localExonerationCs=$$v},expression:"localExonerationCs"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{staticClass:"ml-n4",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},Object.assign({}, on)),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,false,79527337)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageExonerationsChargesSociales)}})])],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }