var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var reset = ref.reset;
return [_c('base-layout',{attrs:{"headerSize":"50px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.getTitle()))])]},proxy:true},{key:"main",fn:function(){return [_c('loading-screen',{ref:"loadingComponent"}),_c('v-container',{attrs:{"fluid":""}},[_c('v-date-associe-header',{ref:"date_annu",attrs:{"valueSelectedItem":_vm.annulation.associe_id,"labelDate":"Date d'annulation","valueDateMouvement":_vm.annulation.date_mouvement,"isDisabled":!_vm.isEditable,"canEditDate":_vm.canEditDate,"allowedDateAfterLastExercice":_vm.allowedDateAfterLastExercice},on:{"update-selected-associe":function($event){return _vm.onUpdateSelectedAssocie($event)},"update-date-mouvement":function($event){return _vm.onUpdateDateMouvement($event)}}}),(_vm.isEditable)?_c('v-portefeuille',{ref:"portefeuille",attrs:{"isBrouillon":_vm.isBrouillon,"valueSelectedItem":_vm.annulation.associe_id,"valueDateMouvement":_vm.annulation.date_mouvement}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"category_id","name":"Catégorie de capital","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-categories-capital-list',{attrs:{"errors":errors,"isDisabled":!_vm.isEditable},on:{"change":_vm.updateMontantARembourser},model:{value:(_vm.annulation.category_id),callback:function ($$v) {_vm.$set(_vm.annulation, "category_id", $$v)},expression:"annulation.category_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"nb_parts_sociales","name":"Nombre de parts annulées","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"labelValue":"Nombre de parts annulées","errors":errors,"disabledValue":!_vm.isEditable},on:{"input":_vm.updateMontantARembourser},model:{value:(_vm.annulation.nb_parts_sociales),callback:function ($$v) {_vm.$set(_vm.annulation, "nb_parts_sociales", $$v)},expression:"annulation.nb_parts_sociales"}})]}}],null,true)})],1),(!_vm.isBrouillon)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('v-formatted-number-text-field',{attrs:{"labelValue":"Montant à rembourser","prefixValue":"€","reverseValue":true,"disabledValue":true,"hint":_vm.message,"persistent-hint":""},model:{value:(_vm.annulation.montant_remboursement_parts_sociales),callback:function ($$v) {_vm.$set(_vm.annulation, "montant_remboursement_parts_sociales", $$v)},expression:"annulation.montant_remboursement_parts_sociales"}})],1):_vm._e(),(_vm.isBrouillon)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"date_signa_bulle","name":"Date de signature du bulletin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateField',{ref:"date_sign",attrs:{"label":"Date de signature du bulletin","errors":errors,"disabled":!_vm.isEditable},model:{value:(_vm.annulation.date_signa_bulle),callback:function ($$v) {_vm.$set(_vm.annulation, "date_signa_bulle", $$v)},expression:"annulation.date_signa_bulle"}})]}}],null,true)})],1):_vm._e(),(!_vm.isBrouillon)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"motif","name":"motif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.motifItems,"label":"Motif légal d'annulation","disabled":!_vm.isEditable,"error-messages":errors},model:{value:(_vm.annulation.motif),callback:function ($$v) {_vm.$set(_vm.annulation, "motif", $$v)},expression:"annulation.motif"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"commentaire","name":"commentaire","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Commentaire","error-messages":errors,"counter":"50","disabled":!_vm.isEditable},model:{value:(_vm.annulation.commentaire),callback:function ($$v) {_vm.$set(_vm.annulation, "commentaire", $$v)},expression:"annulation.commentaire"}})]}}],null,true)})],1)],1)],1)]},proxy:true}],null,true)},[_c('template',{slot:"footer"},[(_vm.isEditable)?_c('v-row',{attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset,"errorsList":errors,"showToast":false},on:{"emit-cancel":_vm.onBackClick,"emit-valid-save":_vm.submit}})],1)],1):_c('v-row',{attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.onBackClick}},[_vm._v("Retour")])],1)],1)],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }