<!-- VUETIFY2 - OK -->
<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col class="text-center">
                <img src="/logo.png" style="max-width: 100%"/>
                <v-row class="mt-5">
                    <v-col>
                        <span class="display-1">Bienvenue sur l'application EZISCOP</span>
                    </v-col>
                </v-row>
                <v-row class="mt-5">
                    <v-col>
                        <span v-if="isMobile">
                            Utilisez le menu latéral
                            «&nbsp;<v-icon dark style="width: 30px; background-color: #00b5d8;">{{ "$vuetify.icons.bars" }}</v-icon>&nbsp;»
                            en haut à gauche pour naviguer dans l'application.
                        </span>
                        <span v-else>
                            Utilisez le menu latéral à gauche pour naviguer dans l'application.
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        computed: {
            companyName() {
                return this.$store.getters.getCompanyName();
            },
            isMobile() {
                return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
            },
        },
    };
</script>