<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withHeader="false" :withFooter="false" datatableExcludedSizeDefault="100px">
        <template v-slot:main="{ datatableSize }">
            <v-container fluid>
                <v-datatable-deblocages-espace-beneficiaire ref="deblocages"
                                                            :showBeneficiaires="false"
                                                            :height="datatableSize"/>
                </v-container>
        </template>
    </base-layout>
</template>
<script>
    import VDatatableDeblocagesEspaceBeneficiaire from "@/components/deblocages/VDatatableDeblocagesEspaceBeneficiaire";
    import BaseLayout from "@/components/BaseLayout";

    export default {
        components: {
            BaseLayout,
            VDatatableDeblocagesEspaceBeneficiaire,
        },
        name: "DeblocagesEspaceBeneficiaire",
    }
</script>
