<!-- VUETIFY2 - OK -->
<template>
    <div>
        <h3>Nouveau mot de passe</h3>
        <span>Le nouveau mot de passe doit contenir au moins :</span>
        <ul>
            <li>1 chiffre&nbsp;;</li>
            <li>1 lettre en minuscule&nbsp;;</li>
            <li>1 lettre en majuscule&nbsp;;</li>
            <li>entre 6 et 50 caractères.</li>
        </ul>
        <v-row no-gutters>
            <v-col>
                <ValidationProvider vid="wrong_password"
                                    name="nouveau mot de passe"
                                    rules="required|regex:((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))|min:6|max:50"
                                    v-slot="{ valid, errors }"
                >
                    <v-password-visualiser
                        v-model="newPasswordLocal"
                        label="Nouveau mot de passe"
                        :errorMessages="errors"
                        :success="valid"
                    />
                </ValidationProvider>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <ValidationProvider vid="wrong_password_2"
                                    name="confirmation nouveau mot de passe"
                                    :rules="{required: true, is: value}"
                                    v-slot="{valid, errors }"
                >
                    <v-password-visualiser
                        v-model="newPassword2"
                        label="Confirmation du mot de passe"
                        :errorMessages="errors"
                        :success="valid"
                    />
                </ValidationProvider>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import VPasswordVisualiser from '@/components/VPasswordVisualiser';
    export default {
        name: "VNewPasswordTextAndConfirmation",
        props: {
            value: {
                type: String,
                required: true,
                twoWay: true
            },
        },
        data() {
            return {
                newPassword2: null,
                showPassword: false
            }
        },
        components:{
            VPasswordVisualiser
        },
        computed: {
            newPasswordLocal: {
                get: function () {
                    return this.value;
                },
                set: function (newvalue) {
                    this.$emit("input", newvalue);
                }
            }            
        },
    }
</script>
