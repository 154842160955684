<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-data-table :headers="headers"
                      :mobile-breakpoint="0"
                      :items="localAffectations"
                      :no-data-text="noDataTextMessage"
                      :loading="loadingAffectations"
                      :server-items-length="total_items"
                      :height="height"
                      fixed-header
                      :options.sync="options"
                      :footer-props="footerProps"
                      :hide-default-footer="localAffectations.length < 10"
                      class="elevation-1"
                      :dense="$vuetify.breakpoint.smAndDown"
        >
            <template v-for="h in headers" v-slot:[`header.${h.value}`]>
                <v-tooltip max-width="50%"
                           v-if="h.tooltip"
                           top
                           :key="h.value"
                           :open-on-hover="!$vuetify.breakpoint.smAndDown"
                           :open-on-click="$vuetify.breakpoint.smAndDown"
                >
                    <template v-slot:activator="{ on }">
                        <span class="ml-2">
                        <span>
                            {{ h.text }}
                        </span>
                            <v-icon small right v-on="{ ...on }" class="header-tooltip">
                                {{ "$vuetify.icons.info" }}
                            </v-icon>
                        </span>
                    </template>
                    <span v-html="h.tooltip"/>
                </v-tooltip>
                <span v-else :key="h.value">
                    {{ h.text }}
                </span>
            </template>
            <template v-slot:item="{ item }">
                <tr :class="{custom_gray_line: getReste(item)===0 && displayAffectedGrey}">
                    <td class="text-left grey lighten-2" v-if="! isPersonal">{{ item.associe_name }}</td>
                    <td class="text-left grey lighten-2" v-if="! isPersonal">{{ item.numero_tiers }}</td>
                    <td class="text-right grey lighten-2">{{ item.montant_net | roundEuroFilter }}</td>
                    <template v-if="!selectedExercice.cap_exercice.is_affectation_valide && !disabledValue">
                        <td class="text-right" v-if="allowAffectationIpsMonteeCapital">
                            <v-row align="center" justify="space-between" no-gutters>
                                <v-col cols="10">
                                    <ValidationProvider :vid="'montant_capital_bene' + item.associe_id"
                                                        :name="'Montée au capital de ' + item.associe_name"
                                                        rules="required|currency|min_value:0"
                                                        v-slot="{ errors }">
                                        <v-formatted-number-text-field slot="input"
                                                                       v-model="item.montant_montee_capital"
                                                                       class="mt-0 pt-0"
                                                                       prefixValue="€"
                                                                       labelValue="Montant à affecter"
                                                                       :reverseValue="true"
                                                                       :isSingleLine="true"
                                                                       :isAutoFocus="false"
                                                                       :min="0"
                                                                       :errors="errors"
                                                                       :hideDetails="true"
                                                                       :disabledValue="!item.can_affect_montee_capital || !canEditMonteeCapital"
                                                                       specificClass="v-text-field-inline-datatable-editing"
                                                                       @input="localDataChanged = true"/>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="1">
                                    <v-tooltip top
                                               v-if="!item.can_affect_montee_capital"
                                               :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                               :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>
                                            Ce tiers n'est plus associé(e) à la fin de l'exercice ou est bloqué(e) pour les nouvelles souscriptions
                                        </span>
                                    </v-tooltip>
                                    <v-tooltip top
                                               v-if="!canEditMonteeCapital"
                                               :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                               :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.droitsAccesVerrouille" }}</v-icon>
                                        </template>
                                        <span v-html="`L'option d'affectation est verrouillée`"/>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </td>
                        <td class="text-right grey lighten-2" v-if="allowAffectationIpsMonteeCapital">
                            {{getNbParts(item.montant_montee_capital)}}
                        </td>
                        <td class="text-right" v-if="allowAffectationIpsVersementImmediat">
                            <v-row align="center" justify="space-between" no-gutters>
                                <v-col cols="10">
                                    <ValidationProvider :vid="'montant_brut_deblocage_bene' + item.associe_id"
                                                        :name="'Versement immédiat de ' + item.associe_name"
                                                        rules="required|currency|min_value:0"
                                                        v-slot="{ errors }">
                                        <v-formatted-number-text-field slot="input"
                                                                       v-model="item.montant_versement_immediat"
                                                                       class="mt-0 pt-0"
                                                                       prefixValue="€"
                                                                       labelValue="Montant à affecter"
                                                                       :reverseValue="true"
                                                                       :isSingleLine="true"
                                                                       :isAutoFocus="false"
                                                                       :min="0"
                                                                       :errors="errors"
                                                                       :hideDetails="true"
                                                                       specificClass="v-text-field-inline-datatable-editing"
                                                                       :disabledValue="!canEditVersementImmediat"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="1">
                                    <v-tooltip top
                                               v-if="!canEditVersementImmediat"
                                               :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                               :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.droitsAccesVerrouille" }}</v-icon>
                                        </template>
                                        <span v-html="`L'option d'affectation est verrouillée`"/>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </td>
                        <td class="text-right" v-if="allowAffectationIpsCompteCourant">
                            <v-row align="center" justify="space-between" no-gutters>
                                <v-col cols="10">
                                    <ValidationProvider :vid="'montant_compte_courant_' + item.associe_id"
                                                        :name="`Placement en Compte courant d'associé(e) ${item.associe_name}`"
                                                        rules="required|currency|min_value:0"
                                                        v-slot="{ errors }">
                                        <v-formatted-number-text-field slot="input"
                                                                       v-model="item.montant_compte_courant"
                                                                       class="mt-0 pt-0"
                                                                       prefixValue="€"
                                                                       labelValue="Montant à affecter"
                                                                       :reverseValue="true"
                                                                       :isSingleLine="true"
                                                                       :isAutoFocus="false"
                                                                       :min="0"
                                                                       :errors="errors"
                                                                       :hideDetails="true"
                                                                       specificClass="v-text-field-inline-datatable-editing"
                                                                       :disabledValue="!canEditCompteCourant"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="1">
                                    <v-tooltip top
                                               v-if="!canEditCompteCourant"
                                               :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                               :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.droitsAccesDeverrouille" }}</v-icon>
                                        </template>
                                        <span v-html="`L'option d'affectation est verrouillée`"/>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                    <template v-else>
                        <td class="text-right grey lighten-2" v-if="allowAffectationIpsMonteeCapital">
                            {{ item.montant_montee_capital | roundEuroFilter }}
                        </td>
                        <td class="text-right grey lighten-2" v-if="allowAffectationIpsMonteeCapital">
                            {{ getNbParts(item.montant_montee_capital) }}
                        </td>
                        <td class="text-right grey lighten-2" v-if="allowAffectationIpsVersementImmediat">
                            <v-row align="center" justify="space-between" no-gutters>
                                <v-col cols="10">
                                    {{ item.montant_versement_immediat | roundEuroFilter }}
                                </v-col>
                            </v-row>
                        </td>
                        <td class="text-right grey lighten-2" v-if="allowAffectationIpsCompteCourant">
                            <v-row align="center" justify="space-between" no-gutters>
                                <v-col cols="10">
                                    {{ item.montant_compte_courant | roundEuroFilter }}
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                    <td class="text-right grey lighten-2">{{ getReste(item) | roundEuroFilter }}</td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="confirmationDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>Avertissement</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            Vous avez saisi des données qui n'ont pas été enregistrées, êtes vous sûr de vouloir
                            continuer ?
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" dense>
                        <!-- Bouton modal -->
                        <v-col cols="12" sm="6" md="4" lg="4" xl="4" xxl="2">
                            <v-btn @click="closePopUp" block>NON, ANNULER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="12" sm="6" md="4" lg="4" xl="4" xxl="2">
                            <v-btn color="primary" @click="confirmChangePagination" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import authService from "@/services/authService";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import _ from "lodash";

    /**
     * Composant affichant une table d'affectations de capital
     */
    export default {
        name: "VDatatableAffectationCapital",
        components: {
            VFormattedNumberTextField,
            loadingScreen,
        },
        props: {
            /**
             * Message si table vide
             */
            noDataTextMessage: {
                type: String,
                required: false,
                default: "Aucune donnée disponible.",
            },
            /**
             * L'exercice sélectionné
             */
            selectedExercice: {
                type: Object,
            },
            /**
             * Contenu de la table
             */
            affectations: {
                type: Array,
                required: true,
            },
            /**
             * Hauteur de la data-table
             */
            height: {
                type: String,
                required: false,
            },
            /**
             * Si les données ont été modifiées et non enregistrées
             */
            dataChanged: {
                type: Boolean,
                required: true,
            },
            /**
             * Désactiver la saisie
             */
            disabledValue: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                total_items: -1,
                loadingAffectations: false,
                options: {
                    sortBy: ['name'],
                    sortDesc: [false],
                    page: 1,
                    itemsPerPage: 10,
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                        100,
                        200,
                    ],
                },
                capCompany: undefined,
                displayAffectedGrey: true,
                confirmationDialog: false,
                nextPagination: {},
                allowAffectationIpsVersementImmediat: undefined,
                allowAffectationIpsMonteeCapital: undefined,
                allowAffectationIpsCompteCourant: undefined,
                canEditMonteeCapital: true,
                canEditVersementImmediat: true,
                canEditCompteCourant: true,
                isPersonal: false,
            };
        },
        computed: {
            /**
             * Intitulés de colonne
             */
            headers() {
                let headers = [];
                if (! this.isPersonal) {
                    headers.push({
                        text: "Associé(e)",
                        value: "name",
                    });
                    headers.push({
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                        sortable: false,
                    });
                }
                headers.push({
                    text: "Montant net des intérêts aux parts sociales",
                    value: "montant_net",
                    align: "right",
                    sortable: false,
                });
                if (this.allowAffectationIpsMonteeCapital) {
                    headers.push({
                        text: "Montée au capital",
                        value: "montant_montee_capital",
                        align: "right",
                        sortable: false,
                    });
                    headers.push({
                        text: "Nb parts sociales",
                        value: "nb_parts_sociales",
                        align: "right",
                        sortable: false,
                    });
                }
                if (this.allowAffectationIpsVersementImmediat) {
                    headers.push({
                        text: "Versement immédiat",
                        value: "montant_versement_immediat",
                        align: "right",
                        sortable: false,
                    });
                }
                if (this.allowAffectationIpsCompteCourant) {
                    headers.push({
                        text: "Compte courant d'associé(e)",
                        value: "montant_compte_courant",
                        align: "right",
                        sortable: false,
                    });
                }

                headers.push({
                    text: "Reste à affecter",
                    value: "reste_a_affecter",
                    align: "right",
                    sortable: false,
                });

                return headers;
            },
            localAffectations: {
                get: function () {
                    return this.affectations;
                },
                set: function (newvalue) {
                    this.$emit("update-affectations", newvalue);
                }
            },
            localDataChanged: {
                get: function () {
                    return this.dataChanged;
                },
                set: function (newvalue) {
                    this.$emit("update-data-changed", newvalue);
                }
            }
        },
        mounted() {
            if (authService.hasAccessCapital()) {
                internalApi.capCompany.getCurrentStatutCapCompany()
                    .then(result => {
                        this.capCompany = result;
                        this.getAllAffectationParAssocie();
                    });
            }
        },
        watch: {
            selectedExercice(new_exercice, old_exercice) {
                if (! _.isNil(new_exercice.id)) {
                    this.getAllAffectationParAssocie();
                }
            },
            /**
             *  Vérifie si les données ont été modifiées par l'utilisateur. Si c'est le cas, on revient à la
             *  pagination initiale et on enregistre la nouvelle pagination. Sinon on récupère l'affectation
             */
            options: {
                handler(newV, oldV) {
                    if (! _.isNil(this.selectedExercice) && ! _.isNil(this.selectedExercice.id)) {
                        if (!this.confirmationDialog) {
                            if (this.localDataChanged) {
                                this.confirmationDialog = true;

                                // On enregistre la pagination qui sera utilisée si l'utilisateur confirme
                                // le changement
                                this.nextPagination.sortBy = newV.sortBy;
                                this.nextPagination.sortDesc = newV.sortDesc;
                                this.nextPagination.page = newV.page;
                                this.nextPagination.itemsPerPage = newV.itemsPerPage;

                                // Cette assignation trigger une nouvelle fois le watch. Une condition a été
                                // rajoutée pour ne pas repasser par ici : if (!this.confirmationDialog)
                                this.options.sortBy = oldV.sortBy;
                                this.options.sortDesc = oldV.sortDesc;
                                this.options.page = oldV.page;
                                this.options.itemsPerPage = oldV.itemsPerPage;
                            }
                            else {
                                this.getAllAffectationParAssocie();
                            }
                        }
                    }
                },
                deep: true
            }
        },
        methods: {
            /**
             * Récupère les affectations de capital par pagination
             */
            getAllAffectationParAssocie() {
                if (!_.isNil(this.selectedExercice) && ! _.isNil(this.selectedExercice.id)) {
                    this.updateDataTable().then(data => {
                        this.localAffectations = data.items;
                        this.total_items = data.total;
                    });
                }
            },
            /**
             * Ferme la pop up et récupère l'affectation paginée à partir de la nouvelle pagination.
             * Met également le localDataChanged à false
             */
            confirmChangePagination() {
                this.options.sortBy = this.nextPagination.sortBy;
                this.options.sortDesc = this.nextPagination.sortDesc;
                this.options.page = this.nextPagination.page;
                this.options.itemsPerPage = this.nextPagination.itemsPerPage;

                this.closePopUp();
                this.getAllAffectationParAssocie();
                this.localDataChanged = false;
            },
            /**
             * Ferme la pop up
             */
            closePopUp() {
                this.confirmationDialog = false;
            },
            updateDataTable() {
                this.loadingAffectations = true;

                return new Promise((resolve, reject) => {
                    const {sortBy, sortDesc, page, itemsPerPage} = this.options;

                    internalApi.capExercice.affectation.getCapAffectationByExerciceId(
                        this.selectedExercice.id,
                        page,
                        itemsPerPage,
                        sortBy[0],
                        sortDesc[0],
                    ).then(res => {
                        this.allowAffectationIpsVersementImmediat = res.allow_affectation_ips_versement_immediat;
                        this.allowAffectationIpsMonteeCapital = res.allow_affectation_ips_montee_capital;
                        this.allowAffectationIpsCompteCourant = res.allow_affectation_ips_compte_courant;
                        resolve({
                            'items': res.affectations,
                            'total': res.total_items
                        });
                    }).catch(err => {
                        console.error("Erreur lors de la récupération des répartitions de capital : ", err);
                        reject({
                            'items': [],
                            'total': 0
                        });
                    }).finally(() => {
                        this.loadingAffectations = false;
                    });
                });
            },
            /**
             * Méthode récursive vérifiant que seuls des nombres sont saisis
             */
            areCorrect(fields) {
                if (fields.length === 0)
                    return true;
                return _.isNumber(fields.pop()) && this.areCorrect(fields);
            },
            /**
             * Méthode calculant le nombre de parts sociales correspondant au montant affecté (si accès capital)
             */
            getNbParts(montantCapital) {
                let nbParts = "-";
                if (authService.hasAccessCapital() && _.isNumber(montantCapital)) {
                    nbParts = (montantCapital / this.capCompany.capital_montant_souscription_part).toString();
                }
                return nbParts;
            },
            /**
             * Méthode calculant le reste à affecter
             */
            getReste(repartition) {
                let reste = repartition.montant_net - repartition.montant_versement_immediat - repartition.montant_montee_capital - repartition.montant_compte_courant;
                return Utils.roundNumber(reste);
            }
        }
    }
</script>
