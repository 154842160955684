<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-row no-gutters class="ml-5">
            <v-col cols="3">
                <ValidationProvider vid="valueSelectedItem"
                                    name="Associé(s)"
                                    rules="required"
                                    v-slot="{ errors }">
                    <v-associes-list :value="selectedAssocie"
                                     label="Associé(s)"
                                     @input="updateSelectedAssocie($event)"
                                     :errors="errors"
                                     :multiple="true"
                    />
                </ValidationProvider>
            </v-col>
        </v-row>
        <v-row no-gutters class="ml-5">
            <v-col cols="2">
                <h3>Attestation fiscale</h3>
            </v-col>
            <!-- Petit bouton -->
            <v-col md="3" lg="3" xl="2">
                <v-button-with-validation :validateForm="validateForm"
                                         :errorsList="errorsList"
                                         @emit-valid-click="downloadAttestationFiscale"
                                         btnLabel="Télécharger"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VButtonWithValidation from "@/components/VButtonWithValidation";
import constantes from "@/utils/constantes";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import VAssociesList from "@/components/VAssociesList.vue"
import loadingScreen from "@/components/LoadingScreen.vue";

export default {
    name: "VScicFiscalite",
    components: {
        VButtonWithValidation,
        VAssociesList,
        loadingScreen,
    },
    props: {
        selectedExercice: {
            type: Number,
            note: "Id de l'exercice sélectionné."
        },
        validateForm: {
            type: Function,
            required: false
        },
        errorsList: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            selectedAssocie: undefined,
        }
    },
    methods: {
        downloadAttestationFiscale() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            const body = {
                exercice_id: this.selectedExercice,
                associes_ids: this.selectedAssocie,
            }
            internalApi.scicDocuments.getAttestationFiscaleForAssocieList(body)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "Attestations_fiscale.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        updateSelectedAssocie(newValue) {
            this.selectedAssocie = newValue;
        },
    }
}
</script>

<style scoped>

</style>