var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formGestionDroitsAcces",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
var validate = ref.validate;
return [_c('base-layout',{attrs:{"withHeader":false,"withFooter":true,"footerSize":"50px","datatableExcludedSizeDefault":"200px"},scopedSlots:_vm._u([{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"3","lg":"3","xl":"2"}},[_c('v-select',{attrs:{"label":"Type de droits d'accès","items":_vm.types,"item-text":"text","item-value":"value"},model:{value:(_vm.selected_type),callback:function ($$v) {_vm.selected_type=$$v},expression:"selected_type"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.droits_acces,"loading":_vm.loading,"search":_vm.selected_type,"custom-filter":_vm.filterOnSelectedType,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"nom","sort-by":"nom","sort-desc":false,"fixed-header":"","height":datatableSize},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([_vm._l((_vm.valeurs_possibles),function(h){return {key:("header." + (h.value)),fn:function(){return [(h.tooltip)?_c('v-tooltip',{key:h.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{staticClass:"ml-2"},[_c('span',[_vm._v(" "+_vm._s(h.text)+" ")]),_c('v-icon',_vm._g({staticClass:"header-tooltip",attrs:{"small":"","right":""}},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.info")+" ")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(h.tooltip)}})]):_c('span',{key:h.value},[_vm._v(" "+_vm._s(h.text)+" ")])]},proxy:true}}),{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',[(item.children.length)?_c('v-icon-button-with-tool-tip',{attrs:{"disabled":!item.children.length,"icon":isExpanded ? '$vuetify.icons.up_arrow' : '$vuetify.icons.down_arrow',"btnRound":"","btnText":"","btnSmall":"","iconSmall":"","tooltipBottom":"","btnColorClass":"transparent","iconColorClass":"grey darken-1","detail":isExpanded ? ("Masquer " + _vm.subDetailExpendedIconText) : ("Afficher " + _vm.subDetailExpendedIconText)},on:{"click":function($event){return expand(!isExpanded)}}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.nom_affichage))]),_vm._l((_vm.valeurs_possibles),function(valeur_possible){return _c('td',{key:valeur_possible.value,staticClass:"text-lg-center"},[_c('v-radio-group',{staticClass:"justify-center",model:{value:(item.valeur),callback:function ($$v) {_vm.$set(item, "valeur", $$v)},expression:"item.valeur"}},[_c('v-radio',{staticClass:"justify-center",attrs:{"value":valeur_possible.value,"disabled":!item.valeurs_autorisees.includes(valeur_possible.value)}})],1)],1)})],2)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":item.children,"hide-default-footer":"","hide-default-header":"","item-key":"nom","fixed-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.value,staticClass:"text-start sortable",style:({width: header.width, 'min-width': header.width}),attrs:{"role":"columnheader"}})}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td'),_c('td',[_vm._v(_vm._s(item.nom_affichage))]),_vm._l((_vm.valeurs_possibles),function(valeur_possible){return _c('td',{key:valeur_possible.value,staticClass:"text-lg-center"},[_c('v-radio-group',{staticClass:"justify-center",model:{value:(item.valeur),callback:function ($$v) {_vm.$set(item, "valeur", $$v)},expression:"item.valeur"}},[_c('v-radio',{staticClass:"justify-center",attrs:{"value":valeur_possible.value,"disabled":!item.valeurs_autorisees.includes(valeur_possible.value) || _vm.isConsultation(item.get_parent().valeur)}})],1)],1)})],2)]}}],null,true)})],1)]}}],null,true)})],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset},on:{"emit-cancel":_vm.resetForm,"emit-valid-save":_vm.saveForm}})],1)],1)]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }