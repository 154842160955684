<!-- VUETIFY2 - OK -->
<template>
    <base-layout datatableExcludedSizeDefault="85px">
        <template slot="header">
            <h1>Liste des Exercices</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <v-container fluid>
                <v-data-table class="elevation-1"
                              no-data-text="Aucun exercice n'a été créé."
                              :height="datatableSize"
                              :headers="computeHeaders()"
                              :footer-props="footerProps"
                              :items="exercicesList"
                              :options.sync="pagination"
                              :loading="isDataLoading"
                              fixed-header>
                    <template v-for="h in headers" v-slot:[`header.${h.value}`]>
                        <v-tooltip top :key="h.value">
                            <template v-slot:activator="{ on }">
                                <span v-on="{ ...on }">
                                    {{ h.text }}
                                </span>
                            </template>
                            <span>
                                {{ h.tooltip }}
                            </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr :class="{custom_gray_line: item.cap_exercice.is_repartition_valide && item.cap_exercice.is_affectation_valide}">
                            <td>{{ item.date_debut | dateIsoToFrFilter }}</td>
                            <td>{{ item.date_fin | dateIsoToFrFilter }}</td>
                            <td>{{ item.libelle }}</td>
                            <td class="text-lg-right">{{ item.participation_brute | roundEuroFilter }}</td>
                            <td class="text-lg-right">
                                {{ item.cap_exercice.valeur_remboursement_part_sociale | roundEuroFilter }}
                            </td>
                            <td class="text-lg-right">
                                {{ item.cap_exercice.montant_global_interets_part_sociale | roundEuroFilter }}
                            </td>
                            <td class="text-lg-center" v-if="hasAccessAbondement()">
                                <v-icon-button-with-tool-tip
                                    :routeCible="{name: 'abo-attribution', params: { exerciceId: item.id }}"
                                    detail="Attribution de l'abondement"
                                    :icon="'$vuetify.icons.repartition'"
                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                    btnColorClass="transparent"
                                    iconColorClass="grey darken-1"
                                />
                            </td>
                            <td class="text-lg-center">
                                <v-icon-button-with-tool-tip
                                    :routeCible="{name: 'souscriptions-obligatoires',params: { exerciceId: item.id }}"
                                    detail="Conversion en parts sociales"
                                    :icon="'$vuetify.icons.affectation_pb'"
                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                    btnColorClass="transparent"
                                    iconColorClass="grey darken-1"
                                />
                            </td>
                            <td class="text-lg-center">
                                <v-icon-button-with-tool-tip
                                    :routeCible="{name: 'cap-edition-exercice', params: { exerciceId: item.id }}"
                                    detail="Paramétrage de l'exercice"
                                    :icon="'$vuetify.icons.edit'"
                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                    btnColorClass="transparent"
                                    iconColorClass="grey darken-1"
                                />
                                <v-icon-button-with-tool-tip
                                    @click.stop="distributeInterest(item)"
                                    detail="Répartition des intérêts aux parts sociales"
                                    :icon="'$vuetify.icons.regulation'"
                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                    btnColorClass="transparent"
                                    iconColorClass="grey darken-1"
                                />
                                <v-icon-button-with-tool-tip
                                    :routeCible="{name: 'cap-affectation-interets', params: { exerciceId: item.id }}"
                                    detail="Affectation des intérêts aux parts sociales"
                                    :icon="'$vuetify.icons.ventilation'"
                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                    btnColorClass="transparent"
                                    iconColorClass="grey darken-1"
                                />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-container>
        </template>
        <template slot="footer">
            <v-row justify="end" no-gutters>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn dark color="primary" @click="createExercice()" block>
                        <v-icon left>{{'$vuetify.icons.add'}}</v-icon>
                        Créer un exercice
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import authService from '@/services/authService.js';
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Vue affichant la liste des exercices côtés capital
     * @displayName Capital - CapExercicesList
     */
    export default {
        components: {
            BaseLayout,
            VIconButtonWithToolTip
        },
        data() {
            return {
                exercicesList: [],
                headers: [
                    {
                        text: "Date de début",
                        tooltip: "Date de début de l'exercice",
                        value: "date_debut",
                        width: "130",
                        showColumn: true,
                    },
                    {
                        text: "Date de fin",
                        tooltip: "Date de fin de l'exercice",
                        value: "date_fin",
                        showColumn: true,
                    },
                    {
                        text: "Libellé",
                        tooltip: "Libellé de l'exercice",
                        value: "libelle",
                        showColumn: true,
                    },
                    {
                        text: "Participation aux bénéfices",
                        tooltip: "Montant annuel de la participation brute aux bénéfices",
                        value: "participation_brute",
                        showColumn: true,
                    },
                    {
                        text: "Remb. part",
                        tooltip: "Valeur de remboursement de la part",
                        value: "valeur_remboursement_part_sociale",
                        showColumn: true,
                    },
                    {
                        text: "Int. parts soc.",
                        tooltip: "Montant annuel d'intérêts aux parts sociales",
                        value: "montant_global_interets_part_sociale",
                        width: "130",
                        showColumn: true,
                    },
                    {
                        text: "Abondement",
                        tooltip: "Abondement",
                        value: "abondement",
                        align: "center",
                        sortable: false,
                        showColumn: this.hasAccessAbondement(),
                    },
                    {
                        text: "Conversion en parts sociales",
                        tooltip: "Conversion en parts sociales",
                        value: "conversion_parts_sociales",
                        align: "center",
                        sortable: false,
                        showColumn: true,
                    },
                    {
                        text: "Actions",
                        tooltip: "Actions",
                        value: "actions",
                        align: "center",
                        sortable: false,
                        showColumn: true,
                    }
                ],
                footerProps: {
                    itemsPerPageOptions: [10, 20, 50]
                },
                pagination: {
                    sortBy: ['date_debut'],
                    sortDesc: [true]
                },
                isDataLoading: true,
            };
        },
        mounted() {
            this.isDataLoading = true;
            this.getExercicesList().finally(() => {
                this.isDataLoading = false;
            });
        },
        methods: {
            /**
             * Récupère la liste des exercices pour leurs affichage dans la table
             * @returns {[]}
             */
            getExercicesList() {
                return internalApi.capExercice.getAll().then(result => {
                    this.exercicesList = _.isNil(result) ? [] : result;
                });
            },
            /**
             * Affiche la vue de création des exercices
             */
            createExercice() {
                this.$router.push({
                    name: "cap-creation-exercice"
                });
            },
            /**
             * Affiche la vue de la répartition des intérêts ou de la proposition
             * @param item l'exercice avec le statut validé ou non
             */
            distributeInterest(item) {
                if (item.is_repartition_valide) {
                    this.$router.push({
                        name: "repartition",
                        params: {id: item.id}
                    });
                } else {
                    this.$router.push({
                        name: "proposition-repartition",
                        params: {id: item.id}
                    });
                }
            },
            computeHeaders() {
                return _.filter(this.headers, function (h) {
                    return h.showColumn
                })
            },
            hasAccessAbondement() {
                return authService.checkHasAbondementExtensionAccess();
            }
        }
    };
</script>
