<!-- VUETIFY2 - OK -->
<template>
    <v-data-table
        :headers="rapports_headers"
        :items="rapports"
        hide-default-header
        hide-default-footer
        class="elevation-1"
    >
        <template v-slot:header="{}">
            <tr>
                <th v-for="header in rapports_headers" :key="header.value" class="text-start sortable" role="columnheader" scope="col" :style="{width: header.width, 'min-width': header.width}"></th>
            </tr>
        </template>
        <template v-slot:item="{item}">
            <tr>
                <td class="text-left">
                    <span>{{item.name}}</span>
                </td>
                <td class="text-right">
                    <v-btn color="primary" @click="item.downloadFunction" block :disabled="disabled">
                        <v-icon left dark>{{item.icon}}</v-icon>
                        Télécharger
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        name: "v-data-table-download-reports",
        props: {
            /**
             * Données des rapports téléchargeables telles que :
                rapports: [
                    {
                        name: "Évolution du capital",
                        downloadFunction: this.downloadExcelEvolution,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "Portefeuille des associé(e)s à date",
                        downloadFunction: this.downloadExcelPortefeuille,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "Rapport du commissaire aux comptes",
                        downloadFunction: this.downloadRapportCac,
                        icon: "$vuetify.icons.pdf",
                    },
                ],
             */
            rapports: {
                type: Array,
                required: true,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                rapports_headers: [
                    {text: "Nom du rapport", value: "name", align: "start", width: "70%"},
                    {text: "Fonction à exécuter", value: "downloadFunction", width: "30%"},
                ],
            }
        },
    }
</script>