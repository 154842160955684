<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors: errorsForm, reset }" ref="form">
        <base-layout datatableExcludedSizeDefault="50vh"
                     datatableExcludedSizeXsOnly="40vh"
                     datatableExcludedSizeSmOnly="20vh"
                     :headerSize="$vuetify.breakpoint.smAndDown ? '50px' : '60px'"
        >
            <template slot="header">
                <h1 class="ml-3">{{title}}</h1>
            </template>
            <template v-slot:main="{ datatableSize }">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <ValidationProvider v-if="!isEspaceBeneficiaire"
                                                vid="beneficiaire_id"
                                                name="Bénéficiaire"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-beneficiaires-list v-model="deblocage.beneficiaire_id"
                                                      label="Bénéficiaire"
                                                      :errors="errors"
                                                      :disabled="!isEditable"
                                />
                            </ValidationProvider>
                            <v-text-field v-else
                                          :value="beneficiaire_name"
                                          label="Bénéficiaire"
                                          disabled
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <ValidationProvider vid="date_mouvement"
                                                name="Date de l'opération"
                                                rules="required"
                                                v-slot="{ errors }">
                                <date-field v-model="deblocage.date_mouvement"
                                            label="Date de l'opération"
                                            :errors="errors"
                                            :clearable="false"
                                            :disabled="!isEditable"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <v-pb-supports-list v-model="deblocage.support_id"
                                             withDefaultValue
                                             label="Support"
                                             :supportFilter="'actif_placement_interne'"
                                             :isEnabled="isEditable"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <v-taux-remu-for-deblocage  :tauxRemu="deblocage.taux_de_remuneration_interets_courus"
                                                        :isEditable="isEditable"
                                                        :dateReferenceTauxRemu="deblocage.date_mouvement"
                                                        @update-taux-remu="deblocage.taux_de_remuneration_interets_courus = $event"/>
                        </v-col>
                    </v-row>
                    <!-- On veut obtenir un aperçu des montants disponibles à terme, donc on ne passe pas la dateOperation -->
                    <v-creation-mouvement-supports-simplifie  v-if="isEditable"
                                                              :beneficiaireId="deblocage.beneficiaire_id"
                                                              :supportId="deblocage.support_id"
                                                              :dateOperation="deblocage.date_mouvement"
                                                              :tauxRemu="deblocage.taux_de_remuneration_interets_courus"
                                                              :aTerme="true"
                                                              :isEditable="isEditable"
                                                              :height="datatableSize"
                                                              ref="mouvementsSupports"
                    />
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <v-row no-gutters align="center">
                                <v-col>
                                    <ValidationProvider vid="montant_debloque" name="montant débloqué"
                                                        rules="required|currency|min_value:0" v-slot="{ errors }">
                                        <v-formatted-number-text-field v-model="deblocage.montant"
                                                                    prefixValue="€"
                                                                    labelValue="Montant débloqué"
                                                                    :reverseValue="true"
                                                                    :errors="errors"
                                                                    :disabledValue="!isEditable"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="ml-3 flex-grow-0">
                                    <v-tooltip bottom
                                               :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                               :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon small right v-on="{...on}">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>Le montant débloqué est prélevé en priorité sur la participation placée, puis sur les intérêts les plus anciens</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="!isEspaceBeneficiaire && isEditable">
                            <v-row no-gutters align="center">
                                <v-col>
                                    <ValidationProvider vid="montant_deblocage_net" name="Montant net du déblocage"
                                                        rules="required|currency|min_value:0" v-slot="{ }">
                                        <v-formatted-number-text-field v-model="montantDeblocageNet"
                                                                        prefixValue="€"
                                                                        labelValue="Montant net du déblocage"
                                                                        :reverseValue="true"
                                                                        :disabledValue="true"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <!-- Info bulle -->
                                <v-col class="ml-3 flex-grow-0">
                                    <v-tooltip-table-taxe :detail="detail"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="!isEspaceBeneficiaire && isEditable">
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <v-row dense justify="space-between">
                                <v-col cols="12" sm="6">
                                    <v-tooltip bottom
                                               :disabled="canComputeMontantNet"
                                               :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                               :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <v-btn @click="computeCurrentMontantNet" color="primary" :disabled="!canComputeMontantNet" block>
                                                Générer le montant net
                                            </v-btn>
                                        </div>
                                        </template>
                                        <span>{{ textMissingDataMontantNet }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-btn text @click="solderPortefeuille" color="primary" block>
                                        Solder le portefeuille
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
                <v-row no-gutters justify="end">
                    <!-- Petits boutons -->
                    <v-col cols="12" md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errorsForm"
                                                      @emit-cancel="onBackClick"
                                                      @emit-valid-save="submit"
                                                      :disabledValue="!isEditable"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import VCreationMouvementSupportsSimplifie from "@/components/mouvementsSupports/VCreationMouvementSupportsSimplifie";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation";
    import DateField from "@/components/DateField";
    import VBeneficiairesList from "@/components/VBeneficiairesList";
    import VPbSupportsList from "@/components/supportsList/VPbSupportsList";
    import VTooltipTableTaxe from "@/components/tooltip/VTooltipTableTaxe"
    import VTauxRemuForDeblocage from "@/components/tauxRemunerationSupport/VTauxRemuForDeblocage"
    import internalApi from "@/api/internalApi";

    export default {
        name: "DeblocageTerme",
        components: {
            VSaveButonWithValidation,
            VCreationMouvementSupportsSimplifie,
            BaseLayout,
            DateField,
            VBeneficiairesList,
            VFormattedNumberTextField,
            VPbSupportsList,
            VTooltipTableTaxe,
            VTauxRemuForDeblocage,
        },
        data() {
            return {
                isEditable: true,
                montantDeblocage: undefined,
                isEspaceBeneficiaire: false,
                deblocage: {
                    id: undefined,
                    support_id: undefined,
                    support_name: undefined,
                    beneficiaire_id: undefined,
                    date_mouvement: null,  // Pour éviter le rechargement quand on focus la date
                    montant: undefined,
                    taux_de_remuneration_interets_courus: undefined,
                },
                detail: {
                    montant_interets_echus_brut: 0,
                    montant_interets_courus_brut: 0,
                    montant_charges_sociales: 0,
                    montant_prelevement_forfaitaire: 0,
                    montant_total_interets_net: 0,
                },
                montantDeblocageNet: undefined,
            }
        },
        computed: {
            title() {
                return "Déblocage à terme";
            },
            canComputeMontantNet() {
                let canCompute = true;
                const fields =  [
                    this.deblocage.taux_de_remuneration_interets_courus,
                    this.deblocage.date_mouvement,
                    this.deblocage.montant,
                    this.deblocage.beneficiaire_id,
                    this.deblocage.support_id,
                ];
                fields.forEach(function (element) {
                    if (_.isNil(element)) {
                        canCompute = false;
                    }
                });
                return canCompute;
            },
            textMissingDataMontantNet() {
                let text = "Veuillez renseigner ";
                let missingData = [];
                if (_.isNil(this.deblocage.beneficiaire_id)){
                    missingData.push("le bénéficiaire");
                }if (_.isNil(this.deblocage.support_id)){
                    missingData.push("le bénéficiaire");
                }
                if (_.isNil(this.deblocage.date_mouvement)){
                    missingData.push("la date du déblocage");
                }
                if (_.isNil(this.deblocage.taux_de_remuneration_interets_courus)){
                    missingData.push("le taux de rémunération");
                }
                if (_.isNil(this.deblocage.montant)){
                    missingData.push("le montant à débloquer");
                }
                text += missingData.join(", ") + ".";

                return text;
            },
        },
        watch: {
            "deblocage.montant": {
                handler() {
                    this.resetDetailMontant();
                },
            },
            "deblocage.date_mouvement": {
                handler() {
                    this.resetDetailMontant();
                },
            },
            "deblocage.taux_de_remuneration_interets_courus": {
                handler() {
                    this.resetDetailMontant();
                },
            },
            "deblocage.beneficiaire_id": {
                handler() {
                    this.resetMontantDebloque();
                },
            },
            "deblocage.support_id": {
                handler() {
                    this.resetMontantDebloque();
                },
            }
        },
        methods: {
            resetMontantDebloque(){
                if(this.isEditable){
                    this.deblocage.montant = undefined;
                }
            },
            resetDetailMontant() {
                this.detail.montant_interets_echus_brut = 0;
                this.detail.montant_interets_courus_brut = 0;
                this.detail.montant_charges_sociales = 0;
                this.detail.montant_prelevement_forfaitaire = 0;
                this.detail.montant_total_interets_net = 0;
                this.montantDeblocageNet = undefined;
            },
            onBackClick() {
                this.$router.back();
            },
            submit() {
            },
            /**
             * Remplit le montant débloqué avec le montant disponible à la date du déblocage
             */
            solderPortefeuille() {
                this.deblocage.montant = this.$refs.mouvementsSupports.$refs.mouvementsSupports._data.montantTotalDispoAdate;
            },
            computeCurrentMontantNet(){
                if(this.canComputeMontantNet){
                    internalApi.deblocageTerme.getMontantDeblocageNetTerme(
                        this.deblocage.beneficiaire_id, this.deblocage.support_id,
                        this.deblocage.montant, this.deblocage.date_mouvement,
                        this.deblocage.taux_de_remuneration_interets_courus,
                    ).then(result => {

                        let montant_charges_sociales = null;
                        let montant_prelevement_forfaitaire = null;
                        if (!_.isNil(result.montant_cs_interets_echus) && !_.isNil(result.montant_cs_interets_courus)) {
                            montant_charges_sociales = result.montant_cs_interets_echus + result.montant_cs_interets_courus;
                        }
                        if (!_.isNil(result.montant_pf_interets_echus) && !_.isNil(result.montant_pf_interets_courus)) {
                            montant_prelevement_forfaitaire = result.montant_pf_interets_echus + result.montant_pf_interets_courus;
                        }

                        this.detail.montant_interets_echus_brut = result.montant_interets_echus_brut;
                        this.detail.montant_interets_courus_brut = result.montant_interets_courus_brut;
                        this.detail.montant_charges_sociales = montant_charges_sociales;
                        this.detail.montant_prelevement_forfaitaire = montant_prelevement_forfaitaire;
                        this.detail.montant_total_interets_net = result.montant_interets_echus_net + result.montant_interets_courus_net;
                        this.montantDeblocageNet = result.montant_deblocage_net;
                    });
                } else {
                    this.resetDetailMontant();
                }
            },
        }
    }
</script>