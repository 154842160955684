<template>
    <v-expansion-panel>
        <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
            <h3>{{title}}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row no-gutters>
                <v-col md="4" lg="4" xl="4">
                    <ValidationProvider :vid="'clef_repart'+type" name="Clef de répartition"
                                        rules="required|between:0,100" v-slot="{ errors }">
                        <v-formatted-number-text-field
                            v-model="localPartRepartieValue"
                            prefixValue="%"
                            :reverseValue="true"
                            labelValue="Clef de répartition"
                            :errors="errors"
                            :disabledValue="isOldInteCompany"
                        />
                    </ValidationProvider>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";

    export default {
        name: "ClefRepartitionValoriseeInteressement",
        components: {
            VFormattedNumberTextField
        },
        props: {
            type: {
                type: String,
                required: true
            },
            partRepartitionValue: {
                type: Number,
                required: false
            },
            
            title: {
                type: String,
                required: true
            },
            inteCompanyId: {
                type: Number,
                required: true
            },
            isOldInteCompany: {
                type: Boolean,
                required: false,
                default: false
            }
        },
         computed: {
            localPartRepartieValue: {
                get: function () {
                    return this.partRepartitionValue;
                },
                set: function (newvalue) {
                    this.$emit("update-part-repartie-value", newvalue);
                }
            },
            
         }
    }
</script>