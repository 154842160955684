<!-- VUETIFY2 - OK -->
<template>
    <v-row v-if="categorieCapital" justify="center" no-gutters>
        <v-col md="12" lg="12" xl="10">
            <ValidationObserver v-slot="{ validate, errors, reset }">
                <base-layout>
                    <template v-slot:header>
                        <v-row>
                            <h1 class="pb-3">Catégorie de Capital</h1>
                        </v-row>
                    </template>
                    <template v-slot:main>
                        <v-row no-gutters>
                            <v-col md="3" lg="3" xl="3">
                                <ValidationProvider vid="code" name="Code catégorie de capital"
                                                    rules="required|max:10"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="categorieCapital.code"
                                                  label="Code catégorie de capital"
                                                  counter="10"
                                                  :error-messages="errors"
                                                  :disabled="!isEditable"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-spacer/>
                            <v-col md="6" lg="6" xl="6">
                                <ValidationProvider vid="libelle" name="Libellé catégorie de capital"
                                                    rules="required|max:100"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="categorieCapital.libelle"
                                                  label="Libellé catégorie de capital"
                                                  :error-messages="errors"
                                                  :disabled="!isEditable"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-spacer/>
                            <v-col md="2" lg="2" xl="2">
                                <ValidationProvider vid="type_capital" name="Type de capital"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <v-select v-model="categorieCapital.type_capital" label="Type de capital"
                                              :items="selectItems.typeCapital"
                                              :error-messages="errors"
                                              :disabled="!isEditable"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                        <!--Plancher et Plafond -->
                        <v-container>
                            <v-expansion-panels multiple>
                                <v-expansion-panel>
                                    <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                        <b>Plancher et Plafond</b>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row justify="center">
                                            <v-col md="10" lg="10" xl="10">
                                                <v-row justify="space-between" no-gutters align="center">
                                                    <v-col md="3" lg="3" xl="3">
                                                        <ValidationProvider vid="plan_is_montant_fixe"
                                                                            name="Montant plancher fixe"
                                                                            rules="required"
                                                                            v-slot="{ errors }">
                                                            <v-checkbox
                                                                v-model="categorieCapital.plan_is_montant_fixe"
                                                                label="Montant plancher fixe"
                                                                :error-messages="errors"
                                                                @change="switchMontantPlancher"
                                                                :disabled="!isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col md="3" lg="3" xl="3">
                                                        <ValidationProvider vid="plan_montant"
                                                                            name="Montant plancher"
                                                                            rules="currency|min_value:0"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="categorieCapital.plan_montant"
                                                                prefixValue="€"
                                                                labelValue="Montant plancher"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="!categorieCapital.plan_is_montant_fixe || !isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col md="4" lg="4" xl="4">
                                                        <v-row align="center">
                                                            <v-col md="10" lg="10" xl="8">
                                                                <ValidationProvider
                                                                    vid="plan_compte_dans_montant_global"
                                                                    name="Inclus dans le plancher global"
                                                                    rules="required"
                                                                    v-slot="{ errors }">
                                                                    <v-checkbox
                                                                        v-model="categorieCapital.plan_compte_dans_montant_global"
                                                                        label="Inclus dans le plancher global"
                                                                        :error-messages="errors"
                                                                        :disabled="!isEditable"
                                                                    />
                                                                </ValidationProvider>
                                                            </v-col>
                                                            <v-col md="1" lg="1" xl="1">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon small left v-on="{...on}">
                                                                            {{"$vuetify.icons.info"}}
                                                                        </v-icon>
                                                                    </template>
                                                                    <span v-html="messageInclusPlancherGlobal"/>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <v-row justify="space-between" no-gutters align="center">
                                                    <v-col md="3" lg="3" xl="3">
                                                        <ValidationProvider vid="plaf_is_montant_fixe"
                                                                            name="Montant plafond fixe"
                                                                            rules="required"
                                                                            v-slot="{ errors }">
                                                            <v-checkbox
                                                                v-model="categorieCapital.plaf_is_montant_fixe"
                                                                label="Montant plafond fixe"
                                                                :error-messages="errors"
                                                                @change="switchMontantPlafond"
                                                                :disabled="!isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col md="3" lg="3" xl="3">
                                                        <ValidationProvider vid="plaf_montant"
                                                                            name="Montant plafond"
                                                                            rules="currency|min_value:0"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="categorieCapital.plaf_montant"
                                                                prefixValue="€"
                                                                labelValue="Montant plafond"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="!categorieCapital.plaf_is_montant_fixe || !isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col md="4" lg="4" xl="4">
                                                        <ValidationProvider vid="plaf_compte_dans_plaf_global"
                                                                            name="Inclus dans le plafond global"
                                                                            rules="required"
                                                                            v-slot="{ errors }">
                                                            <v-checkbox
                                                                v-model="categorieCapital.plaf_compte_dans_plaf_global"
                                                                label="Inclus dans le plafond global"
                                                                :error-messages="errors"
                                                                :disabled="!isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <!-- Souscription -->
                                <v-expansion-panel>
                                    <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                        <b>Souscription</b>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row justify="center">
                                            <v-col md="10" lg="10" xl="10">
                                                <v-row>
                                                    <v-col md="4" lg="4" xl="4">
                                                        <ValidationProvider vid="sous_unique"
                                                                            name="Souscription Unique"
                                                                            rules="required"
                                                                            v-slot="{ errors }">
                                                            <v-checkbox v-model="categorieCapital.sous_unique"
                                                                        label="Souscription Unique" prefix="€"
                                                                        reverse
                                                                        :error-messages="errors"
                                                                        :disabled="!isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <!-- Rémunération -->
                                <v-expansion-panel>
                                    <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                        <b>Rémunération</b>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row justify="center">
                                            <v-col md="10" lg="10" xl="10">
                                                <v-row justify="space-between">
                                                    <v-col md="4" lg="4" xl="4">
                                                        <ValidationProvider vid="is_remunere"
                                                                            name="Peut être rémunéré"
                                                                            rules="required"
                                                                            v-slot="{ errors }">
                                                            <v-checkbox v-model="categorieCapital.is_remunere"
                                                                        label="Peut être rémunéré"
                                                                        :error-messages="errors"
                                                                        @change="clearFieldsIfNonRemunere()"
                                                                        :disabled="!isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col md="4" lg="4" xl="4">
                                                        <ValidationProvider vid="remu_rang"
                                                                            name="Rang de rémunération"
                                                                            :rules="{ required: categorieCapital.is_remunere, currency: true, min_value: 0}"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="categorieCapital.remu_rang"
                                                                labelValue="Rang de rémunération"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="!categorieCapital.is_remunere || !isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                                <v-row justify="space-between">
                                                    <v-col md="4" lg="4" xl="4">
                                                        <v-radio-group v-model="categorieCapital.remu_type"
                                                                       @change="changeRemunerationType"
                                                                       :disabled="!categorieCapital.is_remunere || !isEditable">
                                                            <v-radio v-for="item in typeRemuList"
                                                                     :label="item.label"
                                                                     :value="item.value"
                                                                     :key="item.key"
                                                            />
                                                        </v-radio-group>
                                                    </v-col>
                                                    <v-col md="4" lg="4" xl="4">
                                                        <v-row align="center">
                                                            <v-col md="10" lg="10" xl="10">
                                                                <ValidationProvider vid="remu_taux_fixe"
                                                                                    name="Taux fixe de rémunération"
                                                                                    :rules="{ required: categorieCapital.remu_type === 'fixe', between: {'min': 0, 'max': 100}}"
                                                                                    v-slot="{ errors }">
                                                                    <v-formatted-number-text-field
                                                                        v-model="categorieCapital.remu_taux_fixe"
                                                                        prefixValue="%"
                                                                        labelValue="Taux fixe de rémunération"
                                                                        :reverseValue="true"
                                                                        :errors="errors"
                                                                        :disabledValue="categorieCapital.remu_type !=='fixe'"
                                                                    />
                                                                </ValidationProvider>
                                                            </v-col>
                                                            <v-col md="1" lg="1" xl="1">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon small left v-on="{...on}">
                                                                            {{"$vuetify.icons.info"}}
                                                                        </v-icon>
                                                                    </template>
                                                                    <span v-html="messageTauxFixeRemu"/>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <!-- Remboursement -->
                                <v-expansion-panel>
                                    <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                        <b>Remboursement</b>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row justify="center">
                                            <v-col md="10" lg="10" xl="10">
                                                <v-row justify="space-between">
                                                    <v-col md="4" lg="4" xl="4">
                                                        <ValidationProvider vid="is_rembourse"
                                                                            name="Peut être remboursé"
                                                                            rules="required"
                                                                            v-slot="{ errors }">
                                                            <v-checkbox v-model="categorieCapital.is_rembourse"
                                                                        label="Peut être remboursé"
                                                                        :error-messages="errors"
                                                                        @change="clearFieldsIfNonRembourse()"
                                                                        :disabled="!isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col md="6" lg="6" xl="6">
                                                        <ValidationProvider vid="rembt_delai"
                                                                            name="Délai de remboursement"
                                                                            :rules="{ currency: true, min_value: 0}"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="categorieCapital.rembt_delai"
                                                                prefixValue="Mois"
                                                                labelValue="Délai de remboursement"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="!categorieCapital.is_rembourse || !isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                                <v-row justify="space-between" align="center">
                                                    <v-col md="3" lg="3" xl="3">
                                                        <v-row align="center">
                                                            <v-col md="10" lg="10" xl="10">
                                                                <ValidationProvider
                                                                    vid="is_valeur_rmbt_liee_exercice"
                                                                    name="Valeur de remboursement liée à l'exercice"
                                                                    :rules="{ required: categorieCapital.is_rembourse}"
                                                                    v-slot="{ errors }">
                                                                    <v-checkbox
                                                                        v-model="categorieCapital.is_valeur_rmbt_liee_exercice"
                                                                        label="Valeur de remboursement liée à l'exercice"
                                                                        :error-messages="errors"
                                                                        :disabled="!categorieCapital.is_rembourse || !isEditable"
                                                                    />
                                                                </ValidationProvider>
                                                            </v-col>
                                                            <v-col md="1" lg="1" xl="1">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon small left v-on="{...on}">
                                                                            {{"$vuetify.icons.info"}}
                                                                        </v-icon>
                                                                    </template>
                                                                    <span v-html="messageValeurRemboursement"/>
                                                                </v-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col md="3" lg="3" xl="3">
                                                        <ValidationProvider vid="is_valeur_rmbt_fixe"
                                                                            name="Valeur de remboursement fixe"
                                                                            :rules="{ required: categorieCapital.is_rembourse}"
                                                                            v-slot="{ errors }">
                                                            <v-checkbox
                                                                v-model="categorieCapital.is_valeur_rmbt_fixe"
                                                                label="Valeur de remboursement fixe"
                                                                :error-messages="errors"
                                                                :disabled="!categorieCapital.is_rembourse || !isEditable"
                                                                @change="clearFieldIfNonValeurRemboursementFixe()"/>
                                                        </ValidationProvider>

                                                    </v-col>
                                                    <v-col md="4" lg="4" xl="3">
                                                        <ValidationProvider vid="rembt_valeur_fixe_part"
                                                                            name="Valeur de remboursement fixe"
                                                                            :rules="{ required: categorieCapital.is_rembourse && categorieCapital.is_valeur_rmbt_fixe, currency: true, min_value: 0}"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="categorieCapital.rembt_valeur_fixe_part"
                                                                prefixValue="€"
                                                                labelValue="Valeur de remboursement fixe"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="!categorieCapital.is_rembourse || !categorieCapital.is_valeur_rmbt_fixe || !isEditable"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-container>
                    </template>
                    <template v-slot:footer>
                        <v-row justify="end" no-gutters>
                            <!-- Petits boutons -->
                            <v-col md="6" lg="6" xl="4">
                                <v-save-buton-with-validation :validateForm="validate"
                                                              :resetForm="reset"
                                                              :errorsList="errors"
                                                              @emit-cancel="retourStatuts"
                                                              @emit-valid-save="saveCategorieCapital"
                                                              :disabledValue="!isEditable"
                                />
                            </v-col>
                        </v-row>
                    </template>
                </base-layout>
            </ValidationObserver>
        </v-col>
    </v-row>
</template>

<script>
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import BaseLayout from "@/components/BaseLayout"
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VCheckbox from "vuetify/lib/components/VCheckbox/VCheckbox";

    /**
     * Vue affichant les données d'une catégorie de capital. Permet la modification
     * de ces données
     * @displayName Capital - CatégorieCapital
     */
    export default {
        components: {
            VCheckbox,
            VFormattedNumberTextField,
            BaseLayout,
            VSaveButonWithValidation
        },
        data() {
            return {
                selectItems: {},
                categorieCapital: {
                    code: undefined,
                    libelle: undefined,
                    type_capital: undefined,
                    plan_is_montant_fixe: false,
                    plan_montant: null,
                    plan_compte_dans_montant_global: false,
                    plaf_is_montant_fixe: false,
                    plaf_montant: null,
                    plaf_compte_dans_plaf_global: false,
                    sous_unique: false,
                    sous_duree_limitee: false,
                    is_remunere: false,
                    remu_rang: null,
                    remu_type: null,
                    remu_taux_fixe: null,
                    is_rembourse: false,
                    rembt_delai: null,
                    is_valeur_rmbt_liee_exercice: false,
                    is_valeur_rmbt_fixe: false,
                    rembt_valeur_fixe_part: null
                },
                messageTauxFixeRemu: "Le taux fixe de rémunération est indexé sur la valeur nominale de la part sociale. \n" +
                    "<i>Exemple : Si la valeur nominale est de 20€, que le taux fixe de rémunération est de 10%, alors la rémunération par part sociale sera de 2€.</i>\n",
                messageValeurRemboursement: "Lorsque la valeur de remboursement liée à l’exercice est activée l’application prend en compte la valeur dérogatoire inscrite dans les paramétrages des exercices lorsqu’il y en a une.",
                messageInclusPlancherGlobal: "L’option « Inclus dans le plancher global » permet de décider si les parts possédées sur cette catégorie de capital sont prises en compte dans le contrôle des seuils globaux. ",
                isEditable: true,
                typeRemuList: [
                    {
                        key: 1,
                        label: "Taux fixe",
                        value: "fixe"
                    },
                    {
                        key: 2,
                        label: "Répartition égalitaire des intérêts aux parts sociales",
                        value: "aucun"
                    }
                ]
            };
        },
        mounted() {
            this.getSelectItems();
        },
        methods: {
            /**
             * Récupère les données des catégories de capitaux pour les sélecteurs
             * @returns {{}}
             */
            getSelectItems() {
                return internalApi.categorieCapital.getLabels().then(result => {
                    this.selectItems = {
                        typeRemuneration: Utils.fromLabelToSelect(result.type_remuneration),
                        typeRemboursement: Utils.fromLabelToSelect(result.type_remboursement),
                        typeCapital: Utils.fromLabelToSelect(result.type_capital)
                    };
                });
            },
            /**
             * Enregistre les modifications
             */
            saveCategorieCapital() {
                this.submitForm();
            },
            /**
             * Clear les champs concernant la rémunération lorsque la catégorie n'est
             * pas rémunérée
             */
            clearFieldsIfNonRemunere() {
                let categorieCapital = this.categorieCapital;
                if (!categorieCapital.is_remunere) {
                    categorieCapital.remu_type = null;
                    categorieCapital.remu_rang = null;
                    categorieCapital.remu_taux_fixe = null;
                } else {
                    this.categorieCapital.remu_type = "aucun";
                    this.changeRemunerationType();
                }
            },
            /**
             * Clear le champ concernant la rémunération à taux fixe s'il n'y a aucun
             * type de rémunération
             */
            changeRemunerationType() {
                let categorieCapital = this.categorieCapital;
                if (categorieCapital.remu_type === "aucun") {
                    categorieCapital.remu_taux_fixe = null;
                }
            },
            /**
             * Clear les champs concernant le remboursement lorsque la catégorie n'est
             * pas remboursée
             */
            clearFieldsIfNonRembourse() {
                let categorieCapital = this.categorieCapital;
                if (!categorieCapital.is_rembourse) {
                    categorieCapital.rembt_valeur_fixe_part = null;
                    categorieCapital.rembt_delai = null;
                }
            },
            /**
             * Clear le champ concernant la valeur de remboursement fixe si le type de
             * remboursement n'est pas fixe
             */
            clearFieldIfNonValeurRemboursementFixe() {
                let categorieCapital = this.categorieCapital;
                if (!categorieCapital.is_valeur_rmbt_fixe) {
                    this.categorieCapital.rembt_valeur_fixe_part = null;
                }
            },
            /**
             * Retourne à l'écran des statuts
             */
            retourStatuts() {
                this.$router.push({
                    name: "statuts"
                });
            },
            /**
             * Met à jour le champ correspondant lorsque le switch passe à true
             */
            switchMontantPlancher() {
                if (!this.categorieCapital.plan_is_montant_fixe) {
                    this.categorieCapital.plan_montant = null;
                }
            },
            /**
             * Met à jour le champ correspondant lorsque le switch passe à true
             */
            switchMontantPlafond() {
                if (!this.categorieCapital.plaf_is_montant_fixe) {
                    this.categorieCapital.plaf_montant = null;
                }
            },
            submitForm() {}
        }
    };
</script>
