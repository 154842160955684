var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"id":"administration","withFooter":false,"headerSize":"60px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('loading-screen',{ref:"loadingComponent"}),_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{key:"documents"},[_vm._v("Paramétrage des documents")]),_c('v-tab',{key:"scic_documents",attrs:{"disabled":!_vm.hasScicExtensionAccess()}},[_vm._v("Scic")]),_c('v-tab',{key:"data_management",attrs:{"disabled":!_vm.showGestionDonneesTab()}},[_vm._v("Gestion des données")]),_c('v-tab',{key:"asynchronous_task"},[_vm._v("Monitoring traitements")]),(_vm.isAdministrateur)?_c('v-tab',{key:"child_task"},[_vm._v("Monitoring tâches enfants")]):_vm._e()],1)]},proxy:true},{key:"main",fn:function(){return [_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{key:"documents"},[_c('ValidationObserver',{ref:"formDocuments",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
var validate = ref.validate;
var errors = ref.errors;
return [_c('base-layout',{attrs:{"withHeader":false,"withFooter":true},scopedSlots:_vm._u([{key:"main",fn:function(){return [(_vm.isAdministrateur || _vm.isResponsable)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Tous les documents")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-import-export-file',{attrs:{"detail":"Importer le logo","isIcon":false,"typesFile":_vm.getTypesExtensionsFiles().img,"allowedExport":false},on:{"import-file":function(formData){_vm.onFileChange(formData, _vm.uploadGeneratedDocumentsLogo)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-import-export-file',{attrs:{"detail":"Importer le pied de page","isIcon":false,"typesFile":_vm.getTypesExtensionsFiles().img,"allowedExport":false},on:{"import-file":function(formData){_vm.onFileChange(formData, _vm.uploadGeneratedDocumentsFooter)}}})],1)],1)],1)],1)],1):_vm._e(),_c('v-container',[_c('v-expansion-panels',{staticClass:"mb-2",attrs:{"multiple":""}},[(_vm.hasCapitalAccess())?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":'$vuetify.icons.chevronDown'}},[_c('h3',[_vm._v("Capital")])]),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('h3',[_vm._v("Bulletins de souscription")]),_c('ValidationProvider',{attrs:{"vid":"texte_libre_bulletin_souscription","name":"Texte libre en fin de bulletins de souscription","rules":"max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","label":"Texte libre en fin de bulletins de souscription","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.texte_libre_bulletin_souscription),callback:function ($$v) {_vm.texte_libre_bulletin_souscription=$$v},expression:"texte_libre_bulletin_souscription"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('h3',[_vm._v("Bulletins d'annulation")]),_c('ValidationProvider',{attrs:{"vid":"texte_libre_bulletin_annulation","name":"Texte libre en fin de bulletins d'annulation","rules":"max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","label":"Texte libre en fin de bulletins d'annulation","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.texte_libre_bulletin_annulation),callback:function ($$v) {_vm.texte_libre_bulletin_annulation=$$v},expression:"texte_libre_bulletin_annulation"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"11"}},[_c('h3',[_vm._v("Fiches individuelles capital")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink text-no-wrap"},[_c('v-checkbox',{attrs:{"label":"Affichage de l'affectation des intérêts aux parts sociale par défaut"},model:{value:(_vm.affichage_affectation_cap_fiches_individuelles),callback:function ($$v) {_vm.affichage_affectation_cap_fiches_individuelles=$$v},expression:"affichage_affectation_cap_fiches_individuelles"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"md":"1","lg":"1","xl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info")+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s("La désactivation de l’affichage de l’affectation des intérêts aux parts sociale par défaut conduit à masquer les options d’affectation sur la fiche individuelle.")}})])],1)],1),_c('ValidationProvider',{attrs:{"vid":"texte_libre_fiches_individuelles_affectation_interets_parts_sociales","name":"Texte libre sur les fiches individuelles concernant les options d’affectation des intérêts aux parts sociales","rules":"max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","label":"Texte libre sur les fiches individuelles concernant les options d’affectation des intérêts aux parts sociales","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.texte_libre_fiches_individuelles_affectation_interets_parts_sociales),callback:function ($$v) {_vm.texte_libre_fiches_individuelles_affectation_interets_parts_sociales=$$v},expression:"texte_libre_fiches_individuelles_affectation_interets_parts_sociales"}})]}}],null,true)})],1)],1)],1)],1):_vm._e(),(_vm.hasParticipationAccess())?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":'$vuetify.icons.chevronDown'}},[_c('h3',[_vm._v("Participation")])]),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('h3',[_vm._v("Fiches individuelles participation")]),_c('ValidationProvider',{attrs:{"vid":"texte_libre_fiches_individuelles_conditions_repartition_participation","name":"Texte libre sur les fiches individuelles concernant les conditions de répartition de la participation","rules":"max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","label":"Texte libre concernant les conditions de répartition de la participation","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.texte_libre_fiches_individuelles_conditions_repartition_participation),callback:function ($$v) {_vm.texte_libre_fiches_individuelles_conditions_repartition_participation=$$v},expression:"texte_libre_fiches_individuelles_conditions_repartition_participation"}})]}}],null,true)}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink text-no-wrap"},[_c('v-checkbox',{attrs:{"label":"Affichage de l'affectation de la participation aux bénéfices par défaut"},model:{value:(_vm.affichage_affectation_pb_fiches_individuelles),callback:function ($$v) {_vm.affichage_affectation_pb_fiches_individuelles=$$v},expression:"affichage_affectation_pb_fiches_individuelles"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"md":"1","lg":"1","xl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info")+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s("La désactivation de l'affichage de l'affectation de la participation aux bénéfices par défaut conduit à masquer les options d'affectation par défaut sur la fiche individuelle participation")}})])],1)],1),_c('ValidationProvider',{attrs:{"vid":"texte_libre_fiches_individuelles_options_affectation_participation","name":"Texte libre sur les fiches individuelles concernant les options d'affectation de la participation","rules":"max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","label":"Texte libre sur les fiches individuelles concernant les options d'affectation de la participation aux bénéfices","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.texte_libre_fiches_individuelles_options_affectation_participation),callback:function ($$v) {_vm.texte_libre_fiches_individuelles_options_affectation_participation=$$v},expression:"texte_libre_fiches_individuelles_options_affectation_participation"}})]}}],null,true)})],1)],1)],1)],1):_vm._e(),(_vm.hasInteressementAccess())?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":'$vuetify.icons.chevronDown'}},[_c('h3',[_vm._v("Intéressement")])]),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('h3',[_vm._v("Fiches individuelles intéressement")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink text-no-wrap"},[_c('v-checkbox',{attrs:{"label":"Affichage de l’affectation de l’intéressement par défaut"},model:{value:(_vm.affichage_affectation_inte_fiches_individuelles),callback:function ($$v) {_vm.affichage_affectation_inte_fiches_individuelles=$$v},expression:"affichage_affectation_inte_fiches_individuelles"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"md":"1","lg":"1","xl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info")+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s("La désactivation de l’affichage de l’affectation de l’intéressement par défaut conduit à masquer les options d’affectation par défaut sur les fiches individuelles")}})])],1)],1),_c('ValidationProvider',{attrs:{"vid":"texte_libre_fiches_individuelles_options_affectation_interessement","name":"Texte libre sur les fiches individuelles concernant les options d’affectation de l’intéressement","rules":"max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","label":"Texte libre sur les fiches individuelles concernant les options d’affectation de l’intéressement","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.texte_libre_fiches_individuelles_options_affectation_interessement),callback:function ($$v) {_vm.texte_libre_fiches_individuelles_options_affectation_interessement=$$v},expression:"texte_libre_fiches_individuelles_options_affectation_interessement"}})]}}],null,true)})],1)],1)],1)],1):_vm._e()],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"resetForm":reset,"validateForm":validate,"errorsList":errors},on:{"emit-cancel":function($event){return _vm.cancelDocuments()},"emit-valid-save":function($event){return _vm.saveDocuments()}}})],1)],1)]},proxy:true}],null,true)})]}}])})],1),_c('v-tab-item',{key:"scic_documents"},[(_vm.hasScicExtensionAccess())?_c('v-scic-administration'):_vm._e()],1),_c('v-tab-item',{key:"data_management"},[_c('data-management')],1),_c('v-tab-item',{key:"asynchronous_task"},[_c('asynchronous-batch-task-monitoring')],1),(_vm.isAdministrateur)?_c('v-tab-item',{key:"child_task"},[(_vm.isAdministrateur)?_c('asynchronous-task-monitoring'):_vm._e()],1):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }