<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-row>
            <v-col cols="12" sm="6" md="9" lg="9" xl="10">
                <h3 style="display: inline-block;">État du capital</h3>
                <v-tooltip top
                           :open-on-hover="!$vuetify.breakpoint.smAndDown"
                           :open-on-click="$vuetify.breakpoint.smAndDown"
                >
                    <template v-slot:activator="{ on }">
                        <v-icon class="pl-2" v-on="on" small left>{{ "$vuetify.icons.info" }}</v-icon>
                    </template>
                    <span v-if="hasAccessCapital()"
                        v-html="`L'état du capital est constitué du portefeuille et de l'historique des mouvements`">
                    </span>
                    <span v-else
                        v-html="`Nécessite l'accès à l'univers Capital`">
                    </span>
                </v-tooltip>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" xl="2">
                <v-btn color="primary"
                       @click="downloadEtatDuCapital()"
                       block
                       :disabled="!hasAccessCapital()"
                >
                    Télécharger
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import authService from "@/services/authService";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";
    import loadingScreen from "@/components/LoadingScreen.vue";

    export default {
        name: "v-etat-du-capital-individuel",
        components: {
            loadingScreen
        },
        methods: {
            /**
             * Vérifie l'accès à l'univers capital
             */
            hasAccessCapital: function () {
                return authService.hasAccessCapital();
            },
            downloadEtatDuCapital() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.associe.getEtatDuCapitalIndividuel()
                    .then(response => {
                        Utils.downloadFile(response, "État du capital.pdf", Utils.pdfMimetype);
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            }
        }
    }
</script>