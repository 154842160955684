<template>
    <base-layout :withFooter="false" headerSize="110px">
        <template v-slot:header>
            <h1>Fiscalité</h1>
            <v-tabs v-model="currentTab">
                <v-tab key="general">Général</v-tab>
                <v-tab key="tmop">Tmop</v-tab>
                <v-tab key="scic" :disabled="!hasScicExtensionAccess()">Scic</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="general">
                    <ValidationObserver v-slot="{ validate, errors, reset }">
                        <base-layout :withHeader="false">
                            <template v-slot:main="{ datatableSize }">
                                <v-data-table
                                    :headers="headers"
                                    :items="tauxFiscaliteList"
                                    :loading="loading"
                                    no-data-text="Aucun taux de fiscalité enregistré."
                                    class="elevation-1"
                                    :height="datatableSize"
                                    fixed-header
                                    :options.sync="pagination"
                                >
                                    <template v-slot:item="{item}">
                                        <tr>
                                            <td class="text-lg-left">{{item.date_debut | dateIsoToFrFilter}}</td>
                                            <td class="text-lg-right">
                                                {{item.taux_csg_crds | roundPercentFilter(1, true)}}
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                                    </template>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <th class="text-lg-left">Taux CSG</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;"></span></td>
                                                                <td class="text-lg-right">{{item.taux_contribution_sociale_generalisee | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-lg-left">Taux CRDS</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;">+</span></td>
                                                                <td class="text-lg-right">{{item.taux_contribution_remboursement_dette_sociale | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-lg-left">Taux CSG-CRDS</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;">=</span></td>
                                                                <td class="text-lg-right">{{item.taux_csg_crds | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </v-tooltip>
                                            </td>
                                            <td class="text-lg-right">
                                                {{item.taux_charges_sociales | roundPercentFilter(1, true)}}
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                                    </template>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <th class="text-lg-left">Taux CSG</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;"></span></td>
                                                                <td class="text-lg-right">{{item.taux_contribution_sociale_generalisee | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-lg-left">Taux CRDS</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;">+</span></td>
                                                                <td class="text-lg-right">{{item.taux_contribution_remboursement_dette_sociale | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-lg-left">Taux CAP Solidarité</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;">+</span></td>
                                                                <td class="text-lg-right">{{item.taux_contribution_add_prelevement_solidarite | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-lg-left">Taux Prélèvement Social</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;">+</span></td>
                                                                <td class="text-lg-right">{{item.taux_prelevement_social | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-lg-left">Taux CAP Social</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;">+</span></td>
                                                                <td class="text-lg-right">{{item.taux_cap_social | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                            <tr>
                                                                <th class="text-lg-left">Taux charges sociales</th>
                                                                <td class="text-lg-center"><span style="display: inline-block; width: 30px;">=</span></td>
                                                                <td class="text-lg-right">{{item.taux_charges_sociales | roundPercentFilter(1, true)}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </v-tooltip>
                                            </td>
                                            <td class="text-lg-right">{{item.taux_prelevement_forfaitaire | roundPercentFilter(1, true)}}</td>
                                            <td class="text-lg-right">
                                                <ValidationProvider v-if="item.are_forfaits_sociaux_editables"
                                                                    name="Forfait social standard" vid="forfait_social_standard"
                                                                    v-slot="{ errors }"
                                                                    rules="required|between:0,100">
                                                    <v-formatted-number-text-field v-model="item.forfait_social_standard"
                                                                                   labelValue="Forfait social standard"
                                                                                   prefixValue="%"
                                                                                   :reverseValue="true"
                                                                                   :errors="errors"
                                                    />
                                                </ValidationProvider>
                                                <template v-else>
                                                    {{item.forfait_social_standard | roundPercentFilter(1, true)}}
                                                </template>
                                            </td>
                                            <td class="text-lg-right">
                                                <ValidationProvider v-if="item.are_forfaits_sociaux_editables"
                                                                    name="Forfait social versement immédiat"
                                                                    vid="forfait_social_vers_immediat" v-slot="{ errors }"
                                                                    rules="required|between:0,100">
                                                    <v-formatted-number-text-field v-model="item.forfait_social_vers_immediat"
                                                                                   labelValue="Forfait social versement immédiat"
                                                                                   prefixValue="%"
                                                                                   :reverseValue="true"
                                                                                   :errors="errors"
                                                    />
                                                </ValidationProvider>
                                                <template v-else>
                                                    {{item.forfait_social_vers_immediat | roundPercentFilter(1, true)}}
                                                </template>
                                            </td>
                                            <td class="text-lg-left">{{item.date_fin | dateIsoToFrFilter}}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </template>
                            <template v-slot:footer>
                                <v-row no-gutters>
                                    <v-col cols="3">
                                        <v-new-taux-fiscalite :previousTauxFiscalite="lastTauxFiscalite"
                                                              @taux-fiscalite-created="getTauxFiscalite()"
                                        />
                                    </v-col>
                                    <v-spacer/>
                                    <!-- Petits boutons -->
                                    <v-col md="6" lg="6" xl="4">
                                        <v-save-buton-with-validation :validateForm="validate"
                                                                      :resetForm="reset"
                                                                      :errorsList="errors"
                                                                      @emit-cancel="cancel"
                                                                      @emit-valid-save="saveData"/>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item key="tmop">
                    <tmop-list/>
                </v-tab-item>
                <v-tab-item key="scic">
                    <v-scic-taux-fiscalite v-if="hasScicExtensionAccess()"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VNewTauxFiscalite from "@/components/VNewTauxFiscalite";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import TmopList from "@/components/tmop/TmopList";
    import VScicTauxFiscalite from "@/components/scic/tauxFiscalite/VScicTauxFiscalite";
    import authService from "@/services/authService";

    export default {
        name: "TauxFiscalite",
        components: {
            VFormattedNumberTextField,
            VSaveButonWithValidation,
            VScicTauxFiscalite,
            VNewTauxFiscalite,
            BaseLayout,
            loadingScreen,
            TmopList,
        },
        data() {
            return {
                tauxFiscaliteList: undefined,
                headers: [
                    {text: "Date de début", value: "date_debut", sortable: true},
                    {text: "Taux CSG-CRDS", value: "taux_csg_crds", sortable: false, align: "end"},
                    {text: "Taux charges sociales", value: "taux_charges_sociales", sortable: false, align: "end"},
                    {text: "Taux prélèvement forfaitaire", value: "taux_prelevement_forfaitaire", sortable: false, align: "end"},
                    {text: "Forfait social standard", value: "forfait_social_standard", sortable: false, align: "end"},
                    {text: "Forfait social versement immédiat", value: "forfait_social_vers_immediat", sortable: false, align: "end"},
                    {text: "Date de fin", value: "date_fin", sortable: false},
                ],
                pagination: {
                    sortBy: ['date_debut'],
                    sortDesc: [true]
                },
                tauxFiscalitesToCreate: [],
                tauxFiscalitesIdsToDelete: [],
                currentTab: undefined,
                loading: false,
                lastTauxFiscalite: null,
            }
        },
        mounted() {
            this.getTauxFiscalite();
        },
        methods: {
            hasScicExtensionAccess() {
                return authService.checkHasScicExtensionAccess();
            },
            getTauxFiscalite() {
                this.loading = true;
                internalApi.tauxFiscalite.getAll()
                    .then(result => {
                        this.tauxFiscaliteList = result;
                        const lastTauxFiscalite = this.tauxFiscaliteList.find(taux => {
                            return taux.is_last;
                        });
                        if (!_.isNil(lastTauxFiscalite)) {
                            this.lastTauxFiscalite = lastTauxFiscalite;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            /**
             * Calls form field validations and then call API to update
             * models if all of them filled with the right format.
             */
            saveData() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                const tauxFiscaliteToUpdate = this.tauxFiscaliteList.filter(taux => {
                    return taux.are_forfaits_sociaux_editables;
                });
                internalApi.tauxFiscalite.putAllWithToastConfirmation(tauxFiscaliteToUpdate)
                    .then(result => {
                        this.getTauxFiscalite();
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            cancel() {
                this.getTauxFiscalite();
            },
        },
    }
</script>
