<!-- VUETIFY2 - OK -->
<template>
    <v-app-bar app clipped-left fixed dark color="primary">
        <loading-screen ref="loadingComponent"/>
        <v-app-bar-nav-icon @click="$emit('toggle-navigation')">
            <v-icon>{{"$vuetify.icons.bars"}}</v-icon>
        </v-app-bar-nav-icon>
        <v-row no-gutters>
            <v-col v-if="!isMobile" cols="4">
                <logo-svg-complet-blanc width="170px"/>
            </v-col>
            <v-col class="text-center" align-self="center" cols="10" sm="11" md="4">
                <span class="app-title" style="text-transform: uppercase;">{{this.companyName}}</span>
            </v-col>
            <!-- Desktop Toolbar Items -->
            <v-col align-self="center" v-if="!isMobile" cols="4">
                <v-row class="header-buttons" justify="end" align="center">
                    <v-toolbar-items>
                        <v-menu offset-y nudge-bottom="5">
                            <template v-slot:activator="{ on }">
                                <v-btn text v-on="on">
                                    <v-icon left size="22">{{"$vuetify.icons.help"}}</v-icon>
                                    Aide
                                    <v-icon right small>{{"$vuetify.icons.down_arrow"}}</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-icon-button-with-tool-tip
                                        detailButtonText="Télécharger le manuel utilisateur"
                                        :icon="'$vuetify.icons.download'"
                                        btnRound btnText btnSmall iconSmall tooltipBottom
                                        btnColorClass="transparent"
                                        iconColorClass="grey darken-1"
                                        @click="downloadManuelUtilisateur"
                                        :isTooltipDisabled="true"
                                    />
                                </v-list-item>
                                <v-list-item>
                                    <v-icon-button-with-tool-tip detailButtonText="Rapport de version"
                                                                 :icon="'$vuetify.icons.version'"
                                                                 btnRound btnText btnSmall iconSmall
                                                                 tooltipBottom
                                                                 btnColorClass="transparent"
                                                                 iconColorClass="grey darken-1"
                                                                 @click="goToRapportVersion"
                                                                 :isTooltipDisabled="true"
                                    />
                                </v-list-item>
                                <v-list-item>
                                    <v-about-dialog/>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn id="user_header_btn" text>
                            <v-icon left size="22">{{"$vuetify.icons.per_phy"}}</v-icon>
                            {{username}}
                            <v-icon right small>{{"$vuetify.icons.down_arrow"}}</v-icon>
                        </v-btn>
                        <v-menu activator="#user_header_btn" offset-y nudge-bottom="5">
                            <v-list>
                                <v-list-item>
                                    <v-icon-button-with-tool-tip
                                        :routeCible="{name: changePasswordRoute}"
                                        detail="Modification du mot de passe"
                                        :icon="'$vuetify.icons.key'"
                                        btnRound btnText btnSmall iconSmall tooltipBottom
                                        btnColorClass="transparent"
                                        iconColorClass="grey darken-1"
                                        detailButtonText="Modifier le mot de passe"
                                    />
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn text @click="onLogout">
                            <v-icon left size="22">{{"$vuetify.icons.sign_out"}}</v-icon>
                            Se déconnecter
                        </v-btn>
                    </v-toolbar-items>
                </v-row>
            </v-col>
            <v-col v-else align-self="center" cols="2" sm="1">
                <!-- Mobile Drawer -->
                <v-menu
                    offset-y
                    nudge-bottom="5"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="toggleDrawer">
                            <v-icon>{{ "$vuetify.icons.menu" }}</v-icon>
                        </v-btn>
                    </template>

                    <!-- Main List -->
                    <v-list dense style="width: 300px">
                        <v-list-item @click="downloadManuelUtilisateur">
                            <v-list-item-icon>
                                <v-icon left size="22">{{ "$vuetify.icons.downloadPersonalFile" }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Télécharger le manuel utilisateur</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="goToRapportVersion">
                            <v-list-item-icon>
                                <v-icon left size="22">{{ "$vuetify.icons.version" }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Rapport de version</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="goToResetPassword">
                            <v-list-item-icon>
                                <v-icon left size="22">{{ "$vuetify.icons.key" }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Modifier le mot de passe</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="onLogout">
                            <v-list-item-icon>
                                <v-icon left size="22">{{ "$vuetify.icons.sign_out" }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Se déconnecter</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-app-bar>
</template>
<script>

    import authService from '@/services/authService.js';
    import LogoSvgCompletBlanc from "@/components/assets/LogoSvgCompletBlanc.vue";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import VAboutDialog from "@/components/VAboutDialog.vue";

    export default {
        components: {
            LogoSvgCompletBlanc,
            loadingScreen,
            VIconButtonWithToolTip,
            VAboutDialog
        },
        data() {
            return {
                username: "",
                changePasswordRoute: "changePassword",
                appVersion: process.env.VUE_APP_VERSION,
                drawer: false, // Drawer state
            };
        },
        mounted() {
            this.getAuthenticatedUser();
            this.refreshCompanyName();
        },
        computed: {
            companyName() {
                return this.$store.getters.getCompanyName();
            },
            isMobile() {
                return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
            },
        },
        methods: {
            onLogout: function () {
                authService.logout();
            },
            getAuthenticatedUser: function () {
                authService.getUsername().then(
                    user => {
                        this.username = user
                    }
                );
            },
            downloadManuelUtilisateur: function () {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.manuelUtilisateur.getAll()
                    .then(response => {
                        this.$refs.loadingComponent.resetLoading();
                        let versionUnderscore = this.appVersion.replace(/\./g, "_");
                        Utils.downloadFile(
                            response,
                            "manuels_utilisateur_eziscop_" + versionUnderscore + ".zip",
                            Utils.zipMimetype,
                        );
                    })
                    .catch(err => {
                        console.error(err);
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            goToRapportVersion() {
                window.open("https://www.eziscop.fr/version-eziscop", "versionEziscop");
            },
            goToResetPassword() {
                this.$router.push({name: this.changePasswordRoute});
            },
            refreshCompanyName() {
                return internalApi.company.getCurrentCompany()
                    .then(result => {
                        this.$store.commit("SET_CURRENT_COMPANY", result);
                    });
            },
            toggleDrawer() {
                this.drawer = !this.drawer;
            },
        }
    };
</script>
<style scoped>
    .app-title {
        font-size: 22px;
    }
    .row.header-buttons {
        height: 64px;
    }

    @media (max-width: 600px) {
        .app-title {
            font-size: 16px;
        }
    }
</style>
