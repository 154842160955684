 <!-- VUETIFY2 - OK -->
<template>
    <fieldset>
        <legend>Adresse</legend>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <ValidationProvider vid="localNumeroVoie"
                                    name="Numéro de voie"
                                    rules="max:4|integer"
                                    v-slot="{ errors }">
                    <v-formatted-number-text-field v-model="localNumeroVoie"
                                  labelValue="Numéro de voie"
                                  :errors="errors"
                                  :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <v-select v-model="localTypeVoie"
                          :items="typeVoieChoices"
                          label="Type de la voie" clearable/>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" xxl="2">
                <ValidationProvider vid="localAddress1"
                                    name="Adresse 1"
                                    rules="max:80|required"
                                    v-slot="{ errors }">
                    <v-text-field v-model="localAddress1"
                                  :error-messages="errors"
                                  label="Adresse 1"
                                  :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" xxl="2">
                <ValidationProvider vid="localAddress2"
                                    name="Adresse 2"
                                    rules="max:80"
                                    v-slot="{ errors }">
                    <v-text-field v-model="localAddress2"
                                  :error-messages="errors"
                                  label="Adresse 2"
                                  :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <ValidationProvider vid="localZipCode"
                                    name="Code postal"
                                    :rules="{ regex: /^\w*$/, required: true, max:20}"
                                    v-slot="{ errors }">
                    <v-text-field v-model="localZipCode"
                                  :error-messages="errors"
                                  label="Code postal"
                                  :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <ValidationProvider vid="localCity"
                                    name="Ville"
                                    :rules="{ regex: /^([\xC0-\xFFa-zA-Z- '()]+)$/, max: 80, required: true}"
                                    v-slot="{ errors }">
                    <v-text-field v-model="localCity"
                                  label="Ville"
                                  :error-messages="errors"
                                  :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <ValidationProvider vid="localPays"
                                    name="Pays"
                                    rules="required"
                                    v-slot="{ errors }">
                    <v-countries-list v-model="localCountry"
                                      label="Pays"
                                      :errors="errors"
                                      :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
        </v-row>
    </fieldset>
</template>
<script>
    import VCountriesList from "@/components/VCountriesList";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";

    /**
     * Composant affichant un formulaire concernant les données de l'adresse d'une personne physique
     * @displayName Commun - VAdresse
     */
    export default {
        name: "v-address",
        components: {
            VCountriesList,
            VFormattedNumberTextField
        },
        data() {
            return {
                typeVoieChoices: [{
                    value: "b",
                    text: "Bis"
                }, {
                    value: "t",
                    text: "Ter"
                }, {
                    value: "q",
                    text: "Quator"
                }, {
                    value: "c",
                    text: "Quinquies"
                }]
            }
        },
        props: {
            /**
             * Première partie du nom de la rue
             */
            address1: {
                type: String
            },
            /**
             * Seconde partie du nom de la rue
             */
            address2: {
                type: String
            },
            /**
             * Code postal
             */
            zipCode: {
                type: String
            },
            /**
             * Ville
             */
            city: {
                type: String
            },
            /**
             * Numéro de la voie
             */
            numeroVoie: {
                type: Number
            },
            /**
             * Type de l'adresse (bis, ter, etc)
             */
            typeVoie: {
                type: String
            },
            /**
             * Pays
             */
            country: {
                type: String
            },
            disabled: {
                type: Boolean,
                required: false,
            }
        },
        methods: {
            /**
             * Méthode pour empecher une erreur de validation à l'ouverture de la page
             * En effet comme on passe par une valeur computed, vee-validate considère que le champ à été modifié
             * et passe donc la validation. Si le champ est null ou undefined, on le force à ""
             * @param initialValue
             * @return {string}
             */
            getReturnValueIfNull(initialValue) {
                let returnValue = "";
                if (!_.isNil(initialValue)) {
                    returnValue = initialValue;
                }
                return returnValue;
            }
        },
        computed: {
            localAddress1: {
                get: function () {
                    return this.getReturnValueIfNull(this.address1);
                },
                set: function (newvalue) {
                    this.$emit("update-address-1", newvalue);
                }
            },
            localAddress2: {
                get: function () {
                    return this.getReturnValueIfNull(this.address2);
                },
                set: function (newvalue) {
                    this.$emit("update-address-2", newvalue);
                }
            },
            localZipCode: {
                get: function () {
                    return this.getReturnValueIfNull(this.zipCode);
                },
                set: function (newvalue) {
                    this.$emit("update-zip-code", newvalue);
                }
            },
            localCity: {
                get: function () {
                    return this.getReturnValueIfNull(this.city);
                },
                set: function (newvalue) {
                    this.$emit("update-city", newvalue);
                }
            },
            localNumeroVoie: {
                get: function () {
                    return this.getReturnValueIfNull(this.numeroVoie);
                },
                set: function (newvalue) {
                    this.$emit("update-numero-voie", newvalue);
                }
            },
            localTypeVoie: {
                get: function () {
                    return this.getReturnValueIfNull(this.typeVoie);
                },
                set: function (newvalue) {
                    // Lors du clear du champ, la valeur settée doit être null et non undefined,
                    // sinon la modification n'est pas enregistrée
                    if (_.isNil(newvalue)) {
                        newvalue = null;
                    }
                    this.$emit("update-type-voie", newvalue);
                }
            },
            localCountry: {
                get: function () {
                    return this.getReturnValueIfNull(this.country);
                },
                set: function (newvalue) {
                    this.$emit("update-country", newvalue);
                }
            },
        }
    };
</script>