<!-- VUETIFY2 - OK -->
<template>
    <base-layout datatableExcludedSizeDefault="110px">
        <template v-slot:header>
            <h2 class="pl-3">Intérêts de rémunération</h2>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container class="px-0">
                <v-data-table :search="search"
                              :footer-props="footerProps"
                              :headers="headers"
                              :items="tmops"
                              fixed-header
                              :height="datatableSize"
                              class="elevation-1"
                              no-data-text="Aucune donnée"
                              ref="item_list"
                              multi-sort
                              :options.sync="pagination"
                              :loading="is_datatable_loading">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{item.annee | onlyYearFilter }}</td>
                            <td>{{item.semestre}}</td>
                            <td class="text-lg-right">{{item.tmop_applicable}} %</td>
                            <td>
                                <v-row>
                                    <v-tmop-edition :oldTmop="item"
                                                    @edit-tmop="(oldTmop, newTmop) => editTmop(oldTmop, newTmop)"
                                    />
                                    <v-custom-confirmation-dialog
                                        message="Êtes-vous sûr(e) de vouloir supprimer ce TMOP ?"
                                        :isIcon="true"
                                        :iconValue="'$vuetify.icons.delete'"
                                        @action-confirmee="deleteTmop(item)"
                                        tooltipDetailMessage="Supprimer le TMOP"/>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-container>
        </template>
        <template slot="footer">
            <v-row dense>
                <!-- Grand bouton -->
                <v-col md4 lg4 xl3>
                    <v-tmop-creation @add-tmop="addTmop($event)"/>
                </v-col>
                <v-spacer/>
                <!-- Petits boutons -->
                <v-col md6 lg6 xl4>
                    <ValidationObserver v-slot="{ validate, errors, reset }">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      @emit-cancel="cancel()"
                                                      @emit-valid-save="submit()"/>
                    </ValidationObserver>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation";
    import VTmopCreation from "@/components/tmop/VTmopCreation"
    import VTmopEdition from "./VTmopEdition";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog";

    /**
     * Composant affichant la liste des TMOP, avec possibilité de les modifier, les supprimer
     * ou d'en créer de nouveaux
     * @displayName Participation - TmopList
     */
    export default {
        name: "tmop-list",
        components: {
            VCustomConfirmationDialog,
            VTmopEdition,
            VSaveButonWithValidation,
            BaseLayout,
            loadingScreen,
            VTmopCreation
        },
        data() {
            return {
                tmops: [],
                tmopsIdsToDelete: [],
                search: "",
                is_datatable_loading: false,
                pagination: {
                    sortBy: ["annee", "semestre"],
                    sortDesc: [true, true]
                },
                headers: [
                    {text: "Année", sortable: true, value: "annee", align: "left", width: "10%"},
                    {text: "Semestre", sortable: true, value: "semestre", align: "left", width: "10%"},
                    {
                        text: "Taux Moyen de rendement des Obligations des sociétés Privées (TMOP)",
                        sortable: true,
                        value: "tmop_applicable",
                        width: "30%",
                        align: "right"
                    },
                    {text: "Actions", sortable: false, align: "left", width: "10%"},
                ],
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
            }
        },
        mounted() {
            this.fetchTmopList();
        },
        methods: {
            addTmop(tmop) {
                this.tmops.push(tmop);
            },
            /**
             * Supprimer un TMOP. S'il n'a pas encore été inséré en base (ie son id est undefined)
             * alors on le supprime simplement de la liste des tmops. Sinon, on ajoute son id dans
             * la liste des tmops à supprimer
             * @param tmop Le tmop à supprimer
             */
            deleteTmop(tmop) {
                if (!_.isNil(tmop.id)) {
                    this.tmopsIdsToDelete.push(tmop.id);
                }
                this.removeTmop(tmop);
            },
            /**
             * On supprime les anciennes données d'un tmop que l'on remplace par les nouvelles
             * @param oldTmop L'ancien tmop
             * @param newTmop Le nouveau tmop
             */
            editTmop(oldTmop, newTmop) {
                this.removeTmop(oldTmop);
                this.tmops.push(newTmop)
            },
            /**
             * On retire le tmop de la liste
             * @param tmop Le tmop à retirer
             */
            removeTmop(tmop) {
                for (let i = 0; i < this.tmops.length; i++) {
                    if (this.tmops[i] === tmop) {
                        this.tmops.splice(i, 1);
                    }
                }
            },
            /**
             * Enregistre les nouveaux tmop et ceux modifiés, puis actualise la table
             */
            commitTmops() {
                return internalApi.tauxMoyenRendementObli.postAllWithToastConfirmation(this.tmops)
                    .then(() => {
                        this.fetchTmopList();
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            /**
             * Supprime une liste de tmops. Créer une liste de requête (une requête pour chaque tmop
             * à supprimer) puis les envoie sous forme de promesses. La liste des tmops à supprimer
             * est remise à vide ensuite
             */
            commitTmopsToDelete() {
                const deletePromise = internalApi.tauxMoyenRendementObli.deleteTauxMoyenRendementObliById;
                let allPromises = [];
                for (let i = 0; i < this.tmopsIdsToDelete.length; i++) {
                    allPromises.push(deletePromise(this.tmopsIdsToDelete[i])
                        .then(res => {
                            Promise.resolve(res);
                        })
                    )
                }

                return Promise.all(allPromises)
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.tmopsIdsToDelete = [];
                    });
            },
            fetchTmopList() {
                this.is_datatable_loading = true;
                internalApi.tauxMoyenRendementObli.getAll()
                    .then(result => {
                        this.tmops = result;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.is_datatable_loading = false;
                    });
            },
            /**
             * Reset les infos : remet la table à jour avec les tmops existants puis
             */
            cancel() {
                this.tmopsIdsToDelete = [];
                this.fetchTmopList();
            },
            /**
             * Enregistre la liste des tmops créés et modifiés et supprime les tmops
             * présents dans tmopIdsToDelete
             */
            submit() {
                let promiseTmops = this.commitTmops();
                let promiseTmopsToDelete = this.commitTmopsToDelete();

                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                Promise.all([promiseTmops, promiseTmopsToDelete]).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                    this.fetchTmopList();
                });
            }
        }
    }
</script>