// <!-- VUETIFY2 - OK -->
import VDatatableRepartitionInte from "./VDatatableRepartitionInte";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import {TOAST} from "@/plugins/toast/definition";
import _ from "lodash";
import constantes from "@/utils/constantes";

/**
 * Composant affichant la répartition de l'intéressement d'un bénéficiaire connecté
 * @display_name Intéressement - VDatatable Répartition inte espace individuel
 */
export default {
  extends: VDatatableRepartitionInte,
  name: "v-datatable-repartition-inte-espace-individuel",
  data() {
    return {
        isDisplayFooter: false,
        isEspacePersonnel: true,
    };
  },
  methods: {
    updateDataTable(){
      this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
      internalApi.repartitionInte.getInteRepartitionIndividuelByInteExerciceId(this.selectedExerciceId).then(res => {
        if(res.warning_message){
          TOAST.warning(res.warning_message, Utils.EMPTY_STRING);
          this.localRepartitions = [];
        } else {
          this.localRepartitions = res.repartition;
          this.localNombreDistributions = res.nombre_distributions;
          this.localDetailCalcul = res.detail;
          this.localRepartitionUniformeActive = res.detail.repartition_uniforme.montant_total > 0;
          this.localRepartitionPresenceActive = res.detail.repartition_presence.montant_total > 0;
          this.localRepartitionSalaireActive = res.detail.repartition_salaire.montant_total > 0;
        }
      }).catch(err => {
        console.error("Error retrieving interéssement: " + err);
      }).finally(() => {
          this.headers = this.computeHeaders();
          this.$refs.loadingComponent.resetLoading();
      });
    },
  },
  watch: {
    selectedExercice: function(){
        this.updateDataTable();
    }
  }
};