<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }" ref="form">
        <base-layout headerSize="80px">
            <template slot="header">
                <h1>Accord de l’intéressement</h1>
            </template>
            <template slot="main">
                <loading-screen ref="loadingComponent"/>
                <form>
                    <v-container>
                        <versions-list 
                            :selectedVersion="inteCompany"
                            :versionsList="inteCompaniesEnumByName"
                            @update-selected-version="onSelectedVersionChanges($event)"
                            labelUnivers="Version de l'accord d'intéressement"/>
                            <br/>
                            <br/>
                        <v-row no-gutters>
                            <ValidationProvider vid="date_accord" name="date accord"
                                                rules="required" v-slot="{ errors }">
                                <DateField v-model="inteCompany.date_accord"
                                           class="flex md4"
                                           label="Date de l'accord"
                                           name="date_accord"
                                           :errors="errors"
                                />
                            </ValidationProvider>
                        </v-row>
                        <br/>
                        <br/>
                        <v-subheader>
                            <h2>Contrainte de répartition de l’intéressement</h2>
                            <v-col class="ml-3" md="1">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
                                    </template>
                                    <span>Le calcul de l’ancienneté d’un exercice prend en compte deux exercices glissants</span>
                                </v-tooltip>
                            </v-col>
                        </v-subheader>
                        <v-row no-gutters>
                            <v-col md="6" lg="6" xl="6">
                                <v-checkbox v-model="hasContrainteAnciennete"
                                            label="Contrainte d'ancienneté"
                                            @change="switchHasContrainteAnciennete"
                                            :disabled="isOldInteCompany"
                                />
                            </v-col>
                            <v-col md="4" lg="4" xl="4">
                                <ValidationProvider vid="jours_anciennete" name="jours anciennete"
                                                    :rules="{ required: hasContrainteAnciennete, between: {'min': 0, 'max': 90} }" v-slot="{ errors }">
                                    <v-formatted-number-text-field v-model="inteCompany.elig_anciennete"
                                                                    labelValue="Jours d'ancienneté"
                                                                    :errors="errors"
                                                                    :disabledValue="!hasContrainteAnciennete || isOldInteCompany"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <br/>
                        <br/>
                        <v-subheader>
                            <h2>Règles de répartition de l’intéressement aux bénéfices</h2>
                        </v-subheader>
                        <v-row no-gutters>
                            <h3 class="front-weigth-medium"> Total : {{ totalCles | roundPercentFilter}}</h3>
                        </v-row>
                        <br/>
                         <v-expansion-panels multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                    <v-row no-gutters>
                                        <h3>Répartition uniformisée</h3>
                                        <v-col class="ml-3" md="1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
                                                </template>
                                                <span>L'intéressement est réparti de manière
                                                    uniforme entre chaque bénéficiaire ayant travaillé au moins un jour sur l'exercice.</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row no-gutters>
                                        <v-col md="4" lg="4" xl="4">
                                            <ValidationProvider vid="clef_repart_uniforme" name="clef repart uniforme"
                                                                rules="required|between:0,100" v-slot="{ errors }">
                                                <v-formatted-number-text-field 
                                                    v-model="inteCompany.part_repartition_uniforme"
                                                    prefixValue="%"
                                                    :reverseValue="true"
                                                    labelValue="Clef de répartition"
                                                    :errors="errors"
                                                    :disabledValue="isOldInteCompany"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <clef-repartition-valorisee-interessement
                                :type="'salaire'"
                                :title="titleSalaire"
                                :inteCompanyId="inteCompany.id"
                                :isOldInteCompany="isOldInteCompany"
                                :partRepartitionValue="inteCompany.part_repartition_selon_salaire"
                                @update-part-repartie-value="inteCompany.part_repartition_selon_salaire = $event"                        
                            />
                            <clef-repartition-valorisee-interessement
                                :type="'presence'"
                                :title="titlePresence"
                                :inteCompanyId="inteCompany.id"
                                :isOldInteCompany="isOldInteCompany"
                                :partRepartitionValue="inteCompany.part_repartition_selon_presence"
                                @update-part-repartie-value="inteCompany.part_repartition_selon_presence = $event"                         
                            />
                        </v-expansion-panels>
                    </v-container>
                </form>
            </template>
            <template slot="footer">
                <v-row no-gutters>
                    <v-col md="4" lg="4" xl="3">
                        <new-accord-inte
                            v-if="isResponsable()"
                            :dialog="dialog"
                            :versionsList="inteCompaniesEnumByName"
                            :nouvelleVersion="inteCompany"
                            :disabledValue="isOldInteCompany"
                            @on-open-dialog="onOpenDialog($event)"
                            @on-version-created="updateInteComapny()"
                        />
                    </v-col>
                    <v-spacer/>
                    <v-col md="6" lg="6" xl="5">
                        <v-save-buton-with-validation 
                            :validateForm="validate"
                            :resetForm="reset"
                            :errorsList="errors"
                            @emit-cancel="undo()"
                            @emit-valid-save="saveAccord()"
                            :disabledValue="isOldInteCompany"
                        />
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>       
</template>
<script>
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VersionsList from "@/components/VersionsList";
    import DateField from "@/components/DateField";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import ClefRepartitionValoriseeInteressement from "@/components/ClefRepartitionValoriseeInteressement";
    import NewAccordInte from "@/components/versions/NewAccordInte";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import authService from "@/services/authService";
    import rolesList from "@/router/rolesList";

    export default {
        name: "AccordInteressement",
         components: {
            BaseLayout,
            loadingScreen,
            VersionsList,
            DateField,
            VFormattedNumberTextField,
            ClefRepartitionValoriseeInteressement,
            NewAccordInte,
            VSaveButonWithValidation,
         },
        data() {
            return {
                dialog: undefined,
                inteCompany: {
                    id: 0,
                    nom_version: "",
                    doc_accord_id: undefined,
                    elig_anciennete: 90,
                    date_debut: undefined,
                    date_fin: undefined,
                    part_repartition_uniforme: 0,
                    part_repartition_selon_salaire: 0,
                    part_repartition_selon_presence: 0,
                },
                isOldInteCompany: false,
                hasContrainteAnciennete: false,
                inteCompaniesEnumByName: [],
                titlePresence: "Répartition selon le temps de présence",
                titleSalaire: "Répartition selon le salaire"
            };
        },
        watch: {
            inteCompany: function (val) {
                // The list of statuts is sorted by date
                if (!_.isEmpty(this.inteCompaniesEnumByName)) {
                    this.isOldInteCompany = val.id !== this.inteCompaniesEnumByName[0].id;
                    this.hasContrainteAnciennete = this.inteCompany.elig_anciennete!=null
                }
            },
            inteCompaniesEnumByName: function (val) {
                // The list of statuts is sorted by date
                if (!_.isNil(this.inteCompany)) {
                    this.isOldInteCompany = this.inteCompany.id !== val[0].id;
                }
            },
        },
        computed: {
            totalCles() {
                return this.inteCompany.part_repartition_uniforme
                + this.inteCompany.part_repartition_selon_salaire
                + this.inteCompany.part_repartition_selon_presence
            }
        },
        mounted() {
            // Get inteCompany data when component mounted
            this.getAccordsInteCompanyEnumByName();
        },
        methods: {
            getAccordsInteCompanyEnumByName() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                return internalApi.inteCompany.getAccordsInteCompanyEnumByName()
                    .then(results => {
                        this.inteCompaniesEnumByName = results;
                        if (this.inteCompaniesEnumByName.length > 0) {
                            this.inteCompany.id = this.inteCompaniesEnumByName[0].id;
                            this.inteCompany.nom_version = this.inteCompaniesEnumByName[0].nom_version;
                        } else {
                            this.inteCompany = undefined;
                        }

                        if (! _.isNil(this.inteCompany)) {
                            this.getInteCompanyById(this.inteCompany.id);
                        }
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            getInteCompanyById(inte_company_id) {
                return internalApi.inteCompany.getById(inte_company_id)
                    .then(result => {
                        this.inteCompany = result;
                        this.hasContrainteAnciennete = this.inteCompany.elig_anciennete != null;
                    });
            },
            getCurrentInteCompany() {
                return internalApi.inteCompany.getCurrentInteCompany()
                    .then(result => {
                        this.inteCompany = result;
                    });
            },
            switchHasContrainteAnciennete() {
                if (!this.hasContrainteAnciennete) {
                    this.inteCompany.elig_anciennete = null;
                }
            },
            updateInteComapny() {
                this.getAccordsInteCompanyEnumByName();
                this.dialog = false;
            },
            onOpenDialog(newVal) {
                if (newVal) {
                    this.$refs.form.validate().then(validForm => {
                        this.dialog = validForm;
                    });
                } else {
                    this.dialog = false;
                }
            },
            saveAccord() {
                if (!this.hasContrainteAnciennete){
                    this.inteCompany.elig_anciennete = null;
                }
                let dataToSend = this.getInfosCompany();
                // Axios drop les paramètres undefined
                if (dataToSend.doc_accord_id === undefined){
                    dataToSend.doc_accord_id = null;
                }
                if (dataToSend.date_fin === undefined){
                    dataToSend.date_fin = null;
                }
                const id = dataToSend.id;
                delete dataToSend.id;
                internalApi.inteCompany
                .putByIdWithToastConfirmation(id, dataToSend)
                .then(() => {
                    this.updateInteComapny();
                });
            },
            
            onSelectedVersionChanges(newSelectedStatut) {
                this.inteCompany = newSelectedStatut;
                this.getInteCompanyWithLoading();
            },
            getInteCompanyWithLoading() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                this.getInteCompanyById(this.inteCompany.id)
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            getInfosCompany: function () {
                return _.cloneDeep(this.inteCompany);
            },
            undo: function () {
                this.getInteCompanyWithLoading();
            },
            isResponsable: function () {
                return authService.getCurrentRoles().includes(rolesList.RESPONSABLE);
            },
        },
    }
</script>