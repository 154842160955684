<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
        <base-layout headerSize="50px">
            <template v-slot:header>
                <h1 class="ml-3">{{ getTitle() }}</h1>
            </template>
            <template v-slot:main>
                <loading-screen ref="loadingComponent"/>
                <v-container fluid>
                    <v-date-associe-header :valueSelectedItem="souscription.associe_id"
                                           labelDate="Date de souscription"
                                           :valueDateMouvement="souscription.date_mouvement"
                                           :isDisabled="!isEditable"
                                           :canEditDate="canEditDate"
                                           @update-selected-associe="onUpdateSelectedAssocie($event)"
                                           @update-date-mouvement="onDateMouvementChange($event)"
                                           :allowedDateAfterLastExercice="allowedDateAfterLastExercice"
                                           ref="date_annu"
                    />
                    <v-portefeuille v-if="isEditable"
                                    ref="portefeuille"
                                    :isBrouillon="isBrouillon"
                                    :valueSelectedItem="souscription.associe_id"
                                    :valueDateMouvement="souscription.date_mouvement"
                    />
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <ValidationProvider vid="category_id" name="Catégorie de capital"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-categories-capital-list v-model="souscription.category_id"
                                                           :errors="errors"
                                                           :isDisabled="!isEditable"/>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <ValidationProvider vid="nb_parts_sociales" name="Nombre de parts souscrites"
                                                rules="required|numeric|min_value:1"
                                                v-slot="{ errors }">
                                <v-formatted-number-text-field v-model="souscription.nb_parts_sociales"
                                                               labelValue="Nombre de parts souscrites"
                                                               :disabledValue="!isEditable"
                                                               :errors="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="!isBrouillon">
                            <v-formatted-number-text-field v-model="montantSoucription"
                                                           prefixValue ="€"
                                                           :reverseValue="true"
                                                           labelValue="Montant de la souscription"
                                                           :disabledValue="true"
                                                           :hintValue="`La valeur de la part est de ${montantPartSouscription} €`"
                                                           :persistentHintValue="true"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="isBrouillon">
                            <ValidationProvider vid="date_signa_bulle" name="Date de signature du bulletin"
                                                rules="required"
                                                v-slot="{ errors }">
                                <DateField v-model="souscription.date_signa_bulle"
                                           ref="date_sign"
                                           label="Date de signature du bulletin"
                                           :errors="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="!isBrouillon">
                            <v-row align="center" no-gutters>
                                <v-col cols="11">
                                    <ValidationProvider vid="sous_type_souscription" name="Sous type"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                        <v-select-custom
                                            label="Sous type"
                                            v-model="souscription.sous_type_souscription"
                                            :items="selectValues.sous_type_souscription"
                                            :errors="errors"
                                            :isEditable="isEditable"
                                            :itemDisabled="isOptionDisabled"
                                            :itemTooltip="itemTooltip"
                                            tooltipRight
                                            @change="onOrigVersementChanges"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="1" ml-4 align-self="center">
                                    <v-tooltip
                                        v-if="souscription.sous_type_souscription === origine_versement_participation_benef"
                                        right
                                        :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                        :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>
                                            Les parts sociales acquises dans le cadre de l'affectation de la participation aux bénéfices sont bloquées 5 ans.
                                        </span>
                                    </v-tooltip>
                                    <v-tooltip
                                        v-if="souscription.sous_type_souscription === origine_versement_abondement"
                                        right
                                        :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                        :open-on-click="$vuetify.breakpoint.smAndDown"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>
                                            Les parts sociales acquises dans le cadre de l'affectation de l'abondement sont bloquées 5 ans.
                                        </span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="!isBrouillon">
                            <ValidationProvider vid="orig_fond" name="Origine des fonds"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-select-custom
                                    label="Origine des fonds"
                                    v-model="souscription.orig_fond"
                                    :items="selectValues.orig_fond"
                                    :errors="errors"
                                    :isEditable="isEditable"
                                    :itemDisabled="isOptionDisabled"
                                    :itemTooltip="itemTooltip"
                                    tooltipLeft

                                />

                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="canShowDateDisponibilite">
                            <ValidationProvider
                                            vid="date_dispo"
                                            name="Date de disponibilité"
                                            rules="required"
                                            v-slot="{ errors }">
                                <DateField v-model="souscription.date_disponibilite"
                                           label="Date de disponibilité"
                                           :clearable="false"
                                           :disabled="!isEditable"
                                           :errors="errors"/>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="!isBrouillon">
                            <ValidationProvider vid="part_sociale_liberee" name="Part(s) sociale(s) libérée(s)"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-checkbox type="checkbox"
                                            v-model="souscription.part_sociale_liberee"
                                            label="Part(s) sociale(s) libérée(s)"
                                            :error-messages="errors"
                                            :disabled="!isEditable"/>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
                <v-row v-if="isEditable" justify="end" dense>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      :showToast="false"
                                                      @emit-cancel="onBackClick"
                                                      @emit-valid-save="submit"/>
                    </v-col>
                </v-row>
                <v-row v-else justify="end" dense>
                    <!-- Petit bouton -->
                    <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                        <v-btn block color="primary" @click="onBackClick">Retour</v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import _ from "lodash";
import authService from "@/services/authService";
import rolesList from "@/router/rolesList";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import DateField from "@/components/DateField.vue";
import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
import VDateAssocieHeader from "@/components/VDateAssocieHeader.vue";
import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
import VPortefeuille from "@/components/VPortefeuille.vue";
import BaseLayout from "@/components/BaseLayout";
import loadingScreen from "@/components/LoadingScreen.vue";
import constantes from "@/utils/constantes";
import moment from "moment";
import VCategoriesCapitalList from "@/components/capital/VCategoriesCapitalList";
import VSelectCustom from "@/components/VSelectCustom.vue";

/**
 * Composant permettant l'édition d'une souscription
 * @displayName Capital - Souscription
 */
export default {
    components: {
        VCategoriesCapitalList,
        BaseLayout,
        DateField,
        VFormattedNumberTextField,
        VDateAssocieHeader,
        VSaveButonWithValidation,
        loadingScreen,
        VPortefeuille,
        VSelectCustom
    },
    data() {
        return {
            origine_versement_participation_benef: "parti_benef",
            origine_versement_abondement: "abondement",
            listOptionsToDisabled: ['debl_stock_pb', 'aff_pb', 'parti_benef'],
            disabledOptionTooltip: "Les montées au capital issues de la participation sont à saisir depuis l'univers Participation",
            selectValues: {},
            souscription: {
                associe_id: undefined,
                date_mouvement: undefined,
                date_disponibilite: undefined,
                part_sociale_liberee: true,
                date_signa_bulle: undefined
            },
            isEditable: false,
            isBrouillon: false,
            canEditDate: false,
            allowedDateAfterLastExercice: true,
            specificBackRoute: undefined,
            labels: [],
            title: undefined,
            montantPartSouscription: undefined,
            canSelectDisabledOption: false,
        };
    },

    mounted() {
        this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

        let promise_labels = this.fetchLabels();
        let promise_montant_souscription = this.getCapitalMontantSouscriptionPart();

        Promise.all([
            promise_labels,
            promise_montant_souscription,
        ]).finally(() => {
            this.$refs.loadingComponent.resetLoading();
        });
        const currentRoles = authService.getCurrentRoles();
        this.canSelectDisabledOption = currentRoles.includes(rolesList.ADMINISTRATEUR);
    },
    computed: {
        montantSoucription() {
            let montantSoucription =
                this.montantPartSouscription * this.souscription.nb_parts_sociales;
            return _.isFinite(montantSoucription) ? montantSoucription : "";
        },
        disabledOptions() {
            return this.calculateDisabledOptions()
        },
        canShowDateDisponibilite() {
            const sous_type_with_date_disponibilite = [this.origine_versement_participation_benef, this.origine_versement_abondement];
            return sous_type_with_date_disponibilite.includes(this.souscription.sous_type_souscription);    
        }
    },
    methods: {
        getCapitalMontantSouscriptionPart() {
            return internalApi.capCompany.getCurrentStatutCapCompany()
                .then(result => {
                    this.montantPartSouscription = result.capital_montant_souscription_part;
                });
        },
        onUpdateSelectedAssocie(eventValue) {
            this.souscription.associe_id = eventValue;
            this.$refs.portefeuille.onUpdateSelectedAssocie(eventValue);
        },
        onDateMouvementChange(newDate) {
            this.souscription.date_mouvement = newDate;
            this.compute_date_disponibilite();
            if (!_.isNil(newDate) && newDate !== "") {
                internalApi.capCompany.getStatutByDate(newDate).then(result => {
                        this.montantPartSouscription = result.capital_montant_souscription_part;
                    }
                );
                this.$refs.portefeuille.onUpdateDateMouvement(newDate);
            }
        },
        onOrigVersementChanges(newOrig) {
            this.compute_date_disponibilite();
        },
        fetchLabels() {
            return internalApi.souscription.getLabels().then(res => {
                this.selectValues = Object.assign({}, this.selectValues, {
                    sous_type_souscription: Utils.fromLabelToSelect(res.sous_type_souscription),
                    orig_fond: Utils.fromLabelToSelect(res.orig_fond)
                });
                
                this.labels = res;
                if (_.isNil(this.title)) {
                    this.title = "Souscription";
                }
            });
        },

        getSouscription() {
            internalApi.souscription.getById(this.souscriptionId).then(res => {
                this.souscription = res;
            });
        },

        /**
         * Process VeeValidate fields controls and then make API calls
         * to POST or PUT resource on backend
         */
        submit() {
            this.submitForm();
        },
        pushToMouvements() {
            this.$router.push({
                name: "mouvements"
            });
        },
        onBackClick() {
            if (_.isNil(this.specificBackRoute)) {
                this.pushToMouvements();
            } else {
                this.$router.push(JSON.parse(this.specificBackRoute));
            }
        },
        // Méthodes pouvant être surchargée par le composant enfant !
        getTitle() {
            return this.title;
        },

        compute_date_disponibilite() {
            // Ne calculer que si l'origine de versement est "Participation aux bénéfices" OU "Abondement"
            if (this.souscription.sous_type_souscription === this.origine_versement_participation_benef || this.souscription.sous_type_souscription === this.origine_versement_abondement) {
                this.souscription.date_disponibilite = moment(this.souscription.date_mouvement).add(5, "years").format("YYYY-MM-DD");
            } else {
                this.souscription.date_disponibilite = null;
            }

        },
        
        hasParticipationAccess: function () {
                return authService.hasAccessParticipation();
        },
        isOptionDisabled(item) {
            if (this.hasParticipationAccess() && !this.canSelectDisabledOption) {
                return this.listOptionsToDisabled.includes(item.value);
            } 
            return false;
        },

        itemTooltip(item) {
            if (this.isOptionDisabled(item)) {
                return this.disabledOptionTooltip;
            }
            return null;
        }
    }
};
</script>
