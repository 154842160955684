<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="administration" :withFooter="false" headerSize="60px">
        <template v-slot:header>
            <v-tabs v-model="currentTab">
                <v-tab key="attribution_droits_acces">Attribution des droits d'accès</v-tab>
                <v-tab key="gestion_droits_acces">Gestion des droits d'accès</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="attribution_droits_acces">
                    <ValidationObserver ref="formAdministration" v-slot="{ reset, validate }">
                        <base-layout :withHeader="false" :withFooter="true" footerSize="50px"
                                     datatableExcludedSizeDefault="200px">
                            <template v-slot:main="{ datatableSize }">
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <v-datatable-utilisateurs :userList="users" :height="datatableSize"
                                                                      :isLoading="isLoading"
                                                                      @reload-user-list="fetchUsers();"/>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <template v-slot:footer>
                                <v-row no-gutters>
                                    <!-- Grand bouton -->
                                    <v-col cols="3">
                                        <v-btn block dark color="primary" :to="{name: 'newUserAccess'}">
                                            Créer un accès utilisateur
                                        </v-btn>
                                    </v-col>
                                    <!-- Grand bouton -->
                                    <v-col cols="3" class="ml-2">
                                        <v-btn block dark color="primary" :to="{name: 'newUsersMassAccessCreation'}">
                                            Créer des accès en masse
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="ml-2">
                                        <v-btn dark color="primary" outlined @click="downloadExcel" block>
                                            <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                                            Télécharger excel
                                        </v-btn>
                                    </v-col>
                                    <v-spacer/>
                                    <!-- Petits boutons -->
                                    <v-col cols="3">
                                        <v-save-buton-with-validation :resetForm="reset"
                                                                      :validateForm="validate"
                                                                      @emit-cancel="cancelUsers()"
                                                                      @emit-valid-save="saveUsers()"/>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item key="gestion_droits_acces">
                    <v-gestion-droits-acces/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
import internalApi from "@/api/internalApi";
import VDatatableUtilisateurs from "@/components/administration/VDatatableUtilisateurs.vue"
import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue"
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";
import BaseLayout from "@/components/BaseLayout";
import _ from "lodash";
import authService from "@/services/authService";
import rolesList from "@/router/rolesList";
import XLSX from "xlsx";
import VGestionDroitsAcces from "@/components/droitsAcces/GestionDroitsAcces.vue"

export default {
    components: {
        BaseLayout,
        VDatatableUtilisateurs,
        VSaveButonWithValidation,
        VGestionDroitsAcces,
    },
    data() {
        return {
            currentTab: undefined,
            canUpload: false,
            users: [],
            roles: {},
            isLoading: true,
        }
    },
    mounted() {
        this.fetchUsers();
        const currentRoles = authService.getCurrentRoles();
        this.canUpload = !_.isEmpty(_.intersection([rolesList.ADMINISTRATEUR], currentRoles));
    },
    methods: {
        fetchUsers() {
            this.isLoading = true;
            internalApi.user.getAll().then(result => {
                this.splitUsersRoles(result);
                this.users = result;
                this.usersOld = result;
            }).catch(err => {
                console.log(err);
            }).finally(
                () => this.isLoading = false
            );
        },
        splitUsersRoles(users) {
            users.forEach(user => {
                user.is_tiers = user.roles.includes("tiers");
                user.is_responsable = user.roles.includes("responsable");
                user.is_gestionnaire = user.roles.includes("gestionnaire");
            })
        },
        saveUsers() {
            let respoExists = false;
            let usersToPut = _.cloneDeep(this.users);

            usersToPut.forEach(user => {
                const roles = [];
                if (user.roles.includes("admin")) {
                    roles.push("admin");
                }
                if (user.is_tiers) {
                    roles.push("tiers");
                }
                if (user.is_responsable) {
                    roles.push("responsable");
                    respoExists |= user.active;
                }
                if (user.is_gestionnaire) {
                    roles.push("gestionnaire");
                }

                user.roles = roles.join(",");

                delete user.is_tiers;
                delete user.is_responsable;
                delete user.is_gestionnaire;
            });

            if (respoExists) {
                const putPromises = [];
                usersToPut.forEach(user => putPromises.push(internalApi.user.putById(user.id, user)));

                Promise.all(putPromises).then(() => {
                    TOAST.success(TOAST_MESSAGE.success.put.defaultTitle, Utils.EMPTY_STRING);
                });
            } else {
                TOAST.error(
                    TOAST_MESSAGE.failure.put.defaultTitle,
                    "Au moins un utilisateur actif doit avoir le droit d'accès responsable."
                );
            }
        },

        cancelUsers() {
            this.fetchUsers();
        },
        getDataExcel() {
            const headers = this.buildHeadersForExcel();
            const rows = this.buildRowsForExcel();

            return _.concat(headers, rows);
        },
        buildHeadersForExcel() {
            const headers = ["Nom/Raison sociale", "Numéro de tiers", "Nom d'utilisateur", "Adresse mail", "Date de création", "Est actif"];

            return [headers];
        },
        buildRowsForExcel() {
            let rows = [];
            _.forEach(this.users, (user) => {
                const row = [user.name, user.numero_tiers, user.username, user.mail, Utils.dateIsoToFrFilterWithHourMin(user.date_creation), user.active];
                rows.push(row);
            });
            return rows;
        },
        downloadExcel: function () {
            const ws = XLSX.utils.aoa_to_sheet(this.getDataExcel());
            const wb = XLSX.utils.book_new();
            wb.props = {
                Title: "Liste des accès utilisateurs",
                Subject: "Liste des accès utilisateurs",
                Author: "EZIScop",
                CreatedDate: Date.now()
            };
            XLSX.utils.book_append_sheet(wb, ws, "Liste_utilisateurs");
            XLSX.writeFile(wb, "users_list.xlsx");
        },
    }
}
</script>
