<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" :headerSize="isEspacePersonnel ? '50px' : '96px'">
        <template v-slot:header>
            <loading-screen ref="loadingComponent"/>
            <v-personne-physique-header-nom-prenom
                class="pl-3"
                :civilite="personne_physique.civilite"
                :nom="personne_physique.last_name"
                :prenom="personne_physique.first_name"
                :numero_tiers="personne_physique.numero_tiers"
                :date_naissance="personne_physique.date_naissance"
                :email="personne_physique.email"
            />
            <v-tabs v-model="currentTab" v-show="!isEspacePersonnel">
                <v-tab key="donnees_collaborateur">Données tiers</v-tab>
                <v-tab key="docs" :disabled="!collaborateurId" v-if="!isEspacePersonnel">Documents du tiers</v-tab>
                <v-tab key="periodes_travail" :disabled="!modifier_donnees_salarie" v-if="!isEspacePersonnel">Périodes de travail</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="donnees_collaborateur">
                    <ValidationObserver v-slot="{ validate, errors, reset }">
                        <base-layout :withHeader="false">
                            <!-- Données Personnelles -->
                            <template v-slot:main>
                                <v-row v-if="!personne_physique.anonyme" class="mb-3 ml-3" no-gutters>
                                    <v-col cols="12">
                                        <h4 v-if="personne_physique.username">Nom d'utilisateur&nbsp;: {{ personne_physique.username }}</h4>
                                        <h4 v-else>Aucun accès utilisateur</h4>
                                    </v-col>
                                </v-row>
                                <v-row v-if="!personne_physique.anonyme && isEspacePersonnel" class="mb-3 ml-3" no-gutters>
                                    <v-col cols="12" style="color: rgb(136, 136, 136); font-size: 0.9em;">
                                        Comme défini par le RGPD, vous pouvez demander l'anonymisation de vos données. Pour se faire, veuillez vous rapprocher de votre responsable.
                                    </v-col>
                                </v-row>

                                <v-expansion-panels multiple v-model="openedPanels" class="mb-2">

                                    <!-- Informations personnelles -->
                                    <v-expansion-panel>
                                        <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                            <v-row no-gutters align="center">
                                                <v-col>
                                                    <h3>Informations personnelles</h3>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-donnees-personnelles
                                                :civilite="personne_physique.civilite"
                                                :first_name="personne_physique.first_name"
                                                :last_name="personne_physique.last_name"
                                                :numero_tiers="personne_physique.numero_tiers"
                                                :date_naissance="personne_physique.date_naissance"
                                                :commune_naissance="personne_physique.commune_naissance"
                                                :code_postal_naissance="personne_physique.code_postal_naissance"
                                                :pays_naissance="personne_physique.pays_naissance"
                                                :email="personne_physique.email"
                                                :accept_envoi_demat="personne_physique.accept_envoi_demat"
                                                :isEspacePersonnel="isEspacePersonnel"
                                                :infos_ville_naissance_required="infosVilleNaissanceRequired"
                                                :renonciation_pf="personne_physique.renonciation_pf"
                                                :exoneration_cs="personne_physique.exoneration_cs"
                                                @update-civilite="personne_physique.civilite = $event"
                                                @update-first-name="personne_physique.first_name = $event"
                                                @update-last-name="personne_physique.last_name = $event"
                                                @update-numero-tiers="personne_physique.numero_tiers = $event"
                                                @update-date-naissance="personne_physique.date_naissance = $event"
                                                @update-commune-naissance="personne_physique.commune_naissance = $event"
                                                @update-code-postal-naissance="personne_physique.code_postal_naissance = $event"
                                                @update-pays-naissance="personne_physique.pays_naissance = $event"
                                                @update-email="personne_physique.email = $event"
                                                @update-accept-envoi-demat="personne_physique.accept_envoi_demat = $event"
                                                @update-renonciation-pf="personne_physique.renonciation_pf = $event"
                                                @update-exoneration-cs="personne_physique.exoneration_cs = $event"
                                                ref="donneesPersonnelles"
                                            />
                                            <v-address
                                                :address1="personne_physique.adresse.address1"
                                                :address2="personne_physique.adresse.address2"
                                                :zipCode="personne_physique.adresse.zip_code"
                                                :city="personne_physique.adresse.city"
                                                :typeVoie="personne_physique.adresse.type_voie"
                                                :numeroVoie="personne_physique.adresse.numero_voie"
                                                :country="personne_physique.adresse.country"
                                                @update-address-1="personne_physique.adresse.address1 = $event"
                                                @update-address-2="personne_physique.adresse.address2 = $event"
                                                @update-zip-code="personne_physique.adresse.zip_code = $event"
                                                @update-city="personne_physique.adresse.city = $event"
                                                @update-numero-voie="personne_physique.adresse.numero_voie = $event"
                                                @update-type-voie="personne_physique.adresse.type_voie = $event"
                                                @update-country="personne_physique.adresse.country = $event"
                                                ref="adresse"
                                            />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!-- Informations bancaires -->
                                    <v-expansion-panel>
                                        <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                            <v-row no-gutters align="center">
                                                <v-col>
                                                    <h3>Informations bancaires</h3>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-informations-bancaire
                                                :nom_banque="personne_physique.infos_bancaire.nom_banque"
                                                :bic="personne_physique.infos_bancaire.bic"
                                                :iban="personne_physique.infos_bancaire.iban"
                                                @update-nom-banque="personne_physique.infos_bancaire.nom_banque = $event"
                                                @update-bic="personne_physique.infos_bancaire.bic = $event"
                                                @update-iban="personne_physique.infos_bancaire.iban = $event"
                                                ref="infosBancaires">
                                            </v-informations-bancaire>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!-- Rôle salarié(e) -->
                                    <v-expansion-panel v-if="!isEspacePersonnel">
                                        <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                            <v-row no-gutters align="center">
                                                <v-col md="4" lg="3" xl="2">
                                                    <h3>Rôle salarié(e)</h3>
                                                </v-col>
                                                <v-col md="1" lg="1" xl="1" v-if="is_already_salarie">
                                                    <v-tooltip top v-if="personne_physique.is_currently_salarie">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="{...on}">
                                                                {{"$vuetify.icons.squareCheck"}}
                                                            </v-icon>
                                                        </template>
                                                        <span>Ce tiers est salarié(e) actif(ve) à la date du jour</span>
                                                    </v-tooltip>
                                                    <v-tooltip top v-else>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="{...on}">
                                                                {{"$vuetify.icons.squareCheckCross"}}
                                                            </v-icon>
                                                        </template>
                                                        <span>Ce tiers n'est pas salarié(e) actif(ve) à la date du jour</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col md="3" lg="2" xl="2" v-else style="margin-bottom: -20px;">
                                                    <v-checkbox v-model="modifier_donnees_salarie" label="Activer le rôle" v-on:click.stop @change="onSalarieCheckboxChanges($event)"/>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-donnees-salarie
                                                :modifier_donnees_salarie="modifier_donnees_salarie"
                                                :sal_type_contrat="personne_physique.salarie.sal_type_contrat"
                                                :sal_temps_de_travail="personne_physique.salarie.sal_temps_de_travail"
                                                :sal_categorie_de_salarie="personne_physique.salarie.sal_categorie_de_salarie"
                                                :sal_role="personne_physique.salarie.sal_role"
                                                :is_interets_places="personne_physique.salarie.is_interets_places"
                                                @update-sal-type-contrat="personne_physique.salarie.sal_type_contrat = $event"
                                                @update-sal-temps-de-travail="personne_physique.salarie.sal_temps_de_travail = $event"
                                                @update-sal-categorie-de-salarie="personne_physique.salarie.sal_categorie_de_salarie = $event"
                                                @update-sal-role="personne_physique.salarie.sal_role = $event"
                                                @update-is-interets-places="setPersonnePhysiqueSalarieIsInteretsPlaces($event)"
                                                ref="salarie"
                                            />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!-- Rôle associé(e) -->
                                    <v-expansion-panel v-if="!isEspacePersonnel && hasCapitalAccess()">
                                        <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                            <v-row no-gutters align="center">
                                                <v-col md="4" lg="3" xl="2">
                                                    <h3>Rôle associé(e)</h3>
                                                </v-col>
                                                <v-col md="1" lg="1" xl="1" v-if="is_already_associe">
                                                    <icone-etat-associe
                                                        :is-associe="personne_physique.is_currently_associe"
                                                        :is-associe-by-dates="personne_physique.is_currently_associe_by_dates"
                                                        :is-associe-by-capital="personne_physique.is_currently_associe_by_capital"
                                                    />
                                                </v-col>
                                                <v-col md="3" lg="2" xl="2" v-else style="margin-bottom: -20px;">
                                                    <v-checkbox v-model="modifier_donnees_associe" label="Activer le rôle" v-on:click.stop @change="onAssocieCheckboxChanges($event)"/>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-donnees-associe
                                                :modifier_donnees_associe="modifier_donnees_associe"
                                                :disable_type_droit_de_vote="true"
                                                :date_acces_societariat="personne_physique.associe.date_acces_societariat"
                                                :date_evt_declencheur_societariat="personne_physique.associe.date_evt_declencheur_societariat"
                                                :lib_evt_declencheur_societariat="personne_physique.associe.lib_evt_declencheur_societariat"
                                                :date_perte_qualite_associe="personne_physique.associe.date_perte_qualite_associe"
                                                :motif_perte_qualite_associe="personne_physique.associe.motif_perte_qualite_associe"
                                                :renonciation_ips="personne_physique.associe.renonciation_ips"
                                                :type_droit_de_vote="personne_physique.associe.type_droit_de_vote"
                                                :blocage_souscriptions="personne_physique.associe.blocage_souscriptions"
                                                :exclu_documents_legaux="personne_physique.associe.exclu_documents_legaux"
                                                :scicCategorieAssocie="personne_physique.associe.scic_donnees_associe.code_scic_categorie"
                                                :scicSousCategorieAssocie="personne_physique.associe.scic_donnees_associe.code_scic_sous_categorie"
                                                :scicCollegeAssocie="personne_physique.associe.scic_donnees_associe.code_scic_college"
                                                @update-date-acces-societariat="personne_physique.associe.date_acces_societariat = $event"
                                                @update-date-evt-declencheur-societariat="personne_physique.associe.date_evt_declencheur_societariat = $event"
                                                @update-lib-evt-declencheur-societariat="personne_physique.associe.lib_evt_declencheur_societariat = $event"
                                                @update-date-perte-qualite-associe="personne_physique.associe.date_perte_qualite_associe = $event"
                                                @update-motif-perte-qualite-associe="personne_physique.associe.motif_perte_qualite_associe = $event"
                                                @update-renonciation-ips="personne_physique.associe.renonciation_ips = $event"
                                                @update-blocage-souscriptions="personne_physique.associe.blocage_souscriptions = $event"
                                                @update-exclu-documents-legaux="personne_physique.associe.exclu_documents_legaux = $event"
                                                @update-scic-categorie-associe="personne_physique.associe.scic_donnees_associe.code_scic_categorie = $event"
                                                @update-scic-sous-categorie-associe="personne_physique.associe.scic_donnees_associe.code_scic_sous_categorie = $event"
                                                @update-scic-college-associe="personne_physique.associe.scic_donnees_associe.code_scic_college = $event"
                                                ref="associe"
                                            />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </template>
                            <template v-slot:footer>
                                <v-row align="center" no-gutters>
                                    <template v-if="isResponsable() && !personne_physique.anonyme && !isEspacePersonnel">
                                        <v-col md="3" lg="3" xl="2">
                                            <v-custom-confirmation-dialog :message="anonymizationMsg"
                                                                          openButtonMessage="Anonymiser"
                                                                          title="Anonymiser un tiers"
                                                                          :isIcon="false"
                                                                          @action-confirmee="anonymize()"/>
                                        </v-col>
                                        <v-col md="1" lg="1" xl="1" class="ml-1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small left v-on="on">
                                                        {{"$vuetify.icons.info"}}
                                                    </v-icon>
                                                </template>
                                                <span>L’anonymisation permet de supprimer les informations personnelles d’un tiers</span>
                                            </v-tooltip>
                                        </v-col>
                                    </template>
                                    <v-spacer/>
                                    <!-- Petits boutons -->
                                    <v-col cols="12" md="6" lg="5" xl="4" xxl="3">
                                        <v-save-buton-with-validation :validateForm="validate"
                                                                      :resetForm="reset"
                                                                      :errorsList="errors"
                                                                      @emit-cancel="cancel"
                                                                      @emit-valid-save="saveData"/>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item key="docs" v-if="!isEspacePersonnel">
                    <v-documents-list :collaborateurId="collaborateurId"/>
                </v-tab-item>
                <v-tab-item key="periodes_travail" v-if="!isEspacePersonnel">
                    <salarie-history-list :salarie_id="personne_physique.salarie.id"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import VAddress from "@/components/VAddress.vue";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import VInformationsBancaire from "@/components/VInformationsBancaire.vue";
    import BaseLayout from "@/components/BaseLayout";
    import VDocumentsList from "@/views/documents/VDocumentsList.vue";
    import VDonneesSalarie from "@/components/tiers/VDonneesSalarie";
    import VDonneesAssocie from "@/components/tiers/VDonneesAssocie";
    import VDonneesPersonnelles from "@/components/tiers/VDonneesPersonnelles";
    import SalarieHistoryList from "@/components/SalarieHistoryList.vue";
    import VPersonnePhysiqueHeaderNomPrenom from "@/components/tiers/VPersonnePhysiqueHeaderNomPrenom.vue";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import authService from "@/services/authService";
    import rolesList from "@/router/rolesList";
    import IconeEtatAssocie from "@/components/IconeEtatAssocie.vue";

    /**
     * Composant pour afficher/modifier les informations d'une personne physique
     * @display_name Commun - Personne physique
     */
    export default {
        components: {
            VDonneesPersonnelles,
            VDonneesSalarie,
            VDonneesAssocie,
            loadingScreen,
            BaseLayout,
            VAddress,
            VSaveButonWithValidation,
            VInformationsBancaire,
            VDocumentsList,
            SalarieHistoryList,
            VPersonnePhysiqueHeaderNomPrenom,
            VCustomConfirmationDialog,
            IconeEtatAssocie,
        },
        data() {
            return {
                openedPanels: [0],
                salariePanelIndex: 2,
                associePanelIndex: 3,
                isEspacePersonnel: true,
                currentTab: undefined,
                infosVilleNaissanceRequired: false,
                //model in modification
                personne_physique: {
                    personne_type: "per_phy",
                    civilite: undefined,
                    first_name: undefined,
                    last_name: undefined,
                    numero_tiers: undefined,
                    date_naissance: undefined,
                    commune_naissance: undefined,
                    code_postal_naissance: undefined,
                    pays_naissance: "FRANCE",
                    email: undefined,
                    accept_envoi_demat: false,
                    is_currently_salarie: false,
                    is_currently_associe: false,
                    renonciation_pf: false,
                    exoneration_cs: false,
                    salarie: {
                        sal_type_contrat: undefined,
                        sal_temps_de_travail: undefined,
                        sal_categorie_de_salarie: undefined,
                        sal_role: undefined,
                        is_interets_places: true,
                    },
                    adresse: {
                        address1: undefined,
                        address2: undefined,
                        zip_code: undefined,
                        city: undefined,
                        type_voie: undefined,
                        numero_voie: undefined,
                        country: "FRANCE"
                    },
                    infos_bancaire: {
                        nom_banque: undefined,
                        bic: undefined,
                        iban: undefined
                    },
                    associe: {
                        date_acces_societariat: undefined,
                        date_evt_declencheur_societariat: undefined,
                        lib_evt_declencheur_societariat: undefined,
                        date_perte_qualite_associe: undefined,
                        motif_perte_qualite_associe: undefined,
                        renonciation_ips: false,
                        type_droit_de_vote: "unitaire",  // Le droit de vote est toujours unitaire pour les pp
                        blocage_souscriptions: false,
                        exclu_documents_legaux: false,
                        scic_donnees_associe: {
                            code_scic_categorie: undefined,
                            code_scic_sous_categorie: undefined,
                            code_scic_college: undefined,
                        },
                    },
                },
                //Object containing selectors
                selectItems: {},
                modifier_donnees_salarie: false,
                modifier_donnees_associe: false,
                is_already_salarie: false,
                is_already_associe: false,
                anonymizationDialog: false,
                anonymizationMsg: "L’anonymisation d’un tiers conduit à une suppression définitive des informations personnelles renseignées sur le profil tiers." +
                    "<br/>Confirmez-vous cette opération ?",
            };
        },
        // method call when page is opening
        mounted() {
            this.getSelectItems();
        },
        watch: {
            modifier_donnees_salarie() {
                this.computeInfosVilleNaissanceRequired();
            },
            modifier_donnees_associe() {
                this.computeInfosVilleNaissanceRequired();
            },
        },
        computed: {
            collaborateurId: function () {
                return this.$route.params.id;
            },
        },
        methods: {
            onSalarieCheckboxChanges(newValue) {
                if (newValue) {
                    this.openPanelSalarie();
                } else {
                    this.closePanelSalarie();
                }
            },
            onAssocieCheckboxChanges(newValue) {
                if (newValue) {
                    this.openPanelAssocie();
                } else {
                    this.closePanelAssocie();
                }
            },

            openPanelSalarie() {
                Utils.openExpandablePanel(this.openedPanels, this.salariePanelIndex);
            },
            closePanelSalarie() {
                Utils.closeExpandablePanel(this.openedPanels, this.salariePanelIndex);
            },
            openPanelAssocie() {
                Utils.openExpandablePanel(this.openedPanels, this.associePanelIndex);
            },
            closePanelAssocie() {
                Utils.closeExpandablePanel(this.openedPanels, this.associePanelIndex);
            },

            hasCapitalAccess() {
                return authService.hasAccessCapital();
            },
            /**
             * Set la valeur this.personne_physique.salarie.is_interets_places et déclenche les calculs appropriés
             */
            setPersonnePhysiqueSalarieIsInteretsPlaces(value) {
                this.personne_physique.salarie.is_interets_places = value;
                this.computeInfosVilleNaissanceRequired();
            },
            /**
             * Calcule si les champs relatifs à la ville de naissance sont requis ou optionnels
             */
            computeInfosVilleNaissanceRequired() {

                let is_salarie = null;
                let is_associe = null;
                if (this.isEspacePersonnel) {
                    is_salarie = this.personne_physique.salarie != null;
                    is_associe = this.personne_physique.associe != null && this.personne_physique.associe.date_acces_societariat != undefined;
                } else {
                    is_salarie = this.modifier_donnees_salarie;
                    is_associe = this.modifier_donnees_associe;
                }

                this.infosVilleNaissanceRequired = (
                    // Si associé
                    is_associe
                ) || (
                    // Si salarié avec intérêts versés
                    is_salarie
                    && !this.personne_physique.salarie.is_interets_places
                );

            },
            // get labels of personnes physiques for selector
            getSelectItems() {
                return internalApi.personne.getLabels().then(result => {
                    let {personne} = result;
                    this.selectItems = {
                        type_personne: Utils.fromLabelToSelect(personne.type_personne),
                    };
                });
            },
            updatePersonnePhysiqueBeforeSend: function () {
                let data_to_send = _.cloneDeep(this.personne_physique);

                if (!this.modifier_donnees_salarie) {
                    data_to_send.salarie = undefined;
                } else {
                    // le clearable de vuetify set la variable à undefined. Or Axios drop les paramètres undefined. on force donc ces valeur à null si besoin
                    if (data_to_send.salarie.sal_temps_de_travail === undefined) {
                        data_to_send.salarie.sal_temps_de_travail = null;
                    }
                    if (data_to_send.salarie.sal_categorie_de_salarie === undefined) {
                        data_to_send.salarie.sal_categorie_de_salarie = null;
                    }
                    if (data_to_send.salarie.sal_role === undefined) {
                        data_to_send.salarie.sal_role = null;
                    }
                    if (data_to_send.salarie.sal_type_contrat === undefined) {
                        data_to_send.salarie.sal_type_contrat = null;
                    }
                }

                if (!this.modifier_donnees_associe) {
                    data_to_send.associe = undefined;
                } else {
                    // le clearable de vuetify set la variable à undefined. Or Axios drop les paramètres undefined. on force donc ces valeur à null si besoin
                    if (data_to_send.associe.motif_perte_qualite_associe === undefined) {
                        data_to_send.associe.motif_perte_qualite_associe = null;
                    }
                    if (data_to_send.associe.scic_donnees_associe.code_scic_categorie === undefined) {
                        data_to_send.associe.scic_donnees_associe.code_scic_categorie = null;
                    }
                    if (data_to_send.associe.scic_donnees_associe.code_scic_sous_categorie === undefined) {
                        data_to_send.associe.scic_donnees_associe.code_scic_sous_categorie = null;
                    }
                    if (data_to_send.associe.scic_donnees_associe.code_scic_college === undefined) {
                        data_to_send.associe.scic_donnees_associe.code_scic_college = null;
                    }

                    data_to_send.associe.type_droit_de_vote = "unitaire";
                }

                if (_.isEmpty(data_to_send.infos_bancaire) || !this.$refs.infosBancaires.infosBancairesFull()) {
                    data_to_send.infos_bancaire = undefined;
                }

                if (_.isEmpty(data_to_send.commune_naissance)) {
                    data_to_send.commune_naissance = null;
                }

                if (_.isEmpty(data_to_send.code_postal_naissance)) {
                    data_to_send.code_postal_naissance = null;
                }

                delete data_to_send.personne_type;
                return data_to_send;
            },
            /**
             *  Rend la personne physique anonyme. Revient au menu associés si succès
             */
            anonymize: function () {
                internalApi.personnePhysique.anonymizePersonnePhysique(
                    this.personne_physique.id
                ).then(() => {
                    this.$router.push({name: "tiers"});
                }).catch(err => {
                    console.error(err);
                });
                this.anonymizationDialog = false;
            },
            /**
             * Détermine si l'utilisateur connecté est responsable
             * @returns {boolean}
             */
            isResponsable: function () {
                return authService.getCurrentRoles().includes(rolesList.RESPONSABLE);
            },
            /**
             * Calls form field validations and then call API to create / update
             * model if all of them filled with the right format.
             */
            saveData() {
                this.submitForm();
            },
            cancel() {
                this.$router.push({name: "tiers"});
            },
        }
    };
</script>
