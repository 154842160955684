<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-data-table :headers="headers"
                      :items="localAffectations"
                      :no-data-text="noDataTextMessage"
                      :loading="loadingPeriodes"
                      :server-items-length="total_items"
                      :height="height"
                      fixed-header
                      :options.sync="options"
                      :footer-props="footerProps"
                      :mobile-breakpoint="0"
                      :hide-default-footer="localAffectations.length < 10"
                      :dense="$vuetify.breakpoint.smAndDown"
                      class="elevation-1">
            <template v-for="h in headers" v-slot:[`header.${h.value}`]>
                <v-tooltip max-width="50%" v-if="h.tooltip" top :key="h.value" :open-on-hover="!$vuetify.breakpoint.smAndDown" :open-on-click="$vuetify.breakpoint.smAndDown">
                    <template v-slot:activator="{ on }">
                        <span class="ml-2">
                        <span>
                            {{ h.text }}
                        </span>
                            <v-icon small right v-on="{ ...on }" class="header-tooltip">
                                {{ "$vuetify.icons.info" }}
                            </v-icon>
                        </span>
                    </template>
                    <span v-html="h.tooltip"/>
                </v-tooltip>
                <span v-else :key="h.value">
                    {{ h.text }}
                </span>
            </template>
            <template v-slot:item="{ item }">
                <tr :class="{custom_gray_line: getReste(item)===0 && displayAffectedGrey}">
                    <td class="text-left grey lighten-2" v-if="!isEspacePersonnel">{{ item.beneficiaire_name }}</td>
                    <td class="text-left grey lighten-2" v-if="!isEspacePersonnel">{{ item.numero_tiers }}</td>
                    <td class="text-right grey lighten-2">{{ item.montant_net_inte | roundEuroFilter }}</td>
                    <template v-if="!selectedExercice.inte_exercice.is_affectation_inte_valide && !disabledValue">
                        <td class="text-right" v-if="allowsEditVersementImmediat">
                            <v-row align="center" justify="space-between" no-gutters>
                                <v-col cols="8" md="9" lg="10">
                                    <ValidationProvider :vid="'montant_brut_vers_immediat_bene' + item.beneficiaire_id"
                                                        :name="'Versement immédiat de ' + item.beneficiaire_name"
                                                        rules="required|currency|min_value:0"
                                                        v-slot="{ errors }">
                                        <v-formatted-number-text-field slot="input"
                                                                       v-model="item.montant_brut_vers_immediat"
                                                                       class="mt-0 pt-0"
                                                                       prefixValue="€"
                                                                       labelValue="Montant à affecter"
                                                                       :reverseValue="true"
                                                                       :isSingleLine="true"
                                                                       :isAutoFocus="false"
                                                                       :min="0"
                                                                       :errors="errors"
                                                                       :hideDetails="true"
                                                                       specificClass="v-text-field-inline-datatable-editing"
                                                                       @input="onChangeMontantBrutVersement(item)"
                                                                       :disabledValue="!canEditVersementImmediat"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <v-tooltip top v-if="!canEditVersementImmediat" :open-on-hover="!$vuetify.breakpoint.smAndDown" :open-on-click="$vuetify.breakpoint.smAndDown">
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.droitsAccesVerrouille" }}</v-icon>
                                        </template>
                                        <span v-html="`L'option d'affectation est verrouillée`"/>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </td>
                        <td class="text-right" v-for="inteSupport in localInteSupports" v-bind:key="inteSupport.id">
                            <v-row align="center" justify="space-between" no-gutters>
                                <v-col cols="8" md="9" lg="10">
                                    <ValidationProvider :vid="'placementsTemp_bene' + item.beneficiaire_id + '_inte_supp' + inteSupport.id"
                                                        :name="inteSupport.name + ' de ' + item.beneficiaire_name"
                                                        rules="required|currency|min_value:0"
                                                        v-slot="{ errors }">
                                        <v-formatted-number-text-field slot="input"
                                                                       v-model="placementsTemp[item.inte_repartition_id][inteSupport.id]"
                                                                       class="mt-0 pt-0"
                                                                       prefixValue="€"
                                                                       labelValue="Montant à affecter"
                                                                       :min="0"
                                                                       :reverseValue="true"
                                                                       :isSingleLine="true"
                                                                       :isAutoFocus="false"
                                                                       :errors="errors"
                                                                       :hideDetails="true"
                                                                       specificClass="v-text-field-inline-datatable-editing"
                                                                       @input="onChangeMontantPlacement(item, inteSupport.id, $event)"
                                                                       :disabledValue="!canEditAffectationSupports"/>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <v-tooltip top v-if="!canEditAffectationSupports" :open-on-hover="!$vuetify.breakpoint.smAndDown" :open-on-click="$vuetify.breakpoint.smAndDown">
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.droitsAccesVerrouille" }}</v-icon>
                                        </template>
                                        <span v-html="`L'option d'affectation est verrouillée`"/>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                    <template v-else>
                        <td class="text-right grey lighten-2" v-if="allowsEditVersementImmediat">
                            {{ item.montant_brut_vers_immediat | roundEuroFilter }}
                        </td>
                        <td class="text-right grey lighten-2" v-for="inteSupport in localInteSupports"
                            v-bind:key="inteSupport.id">
                            {{ placementsTemp[item.inte_repartition_id][inteSupport.id] | roundEuroFilter }}
                        </td>
                    </template>
                    <td class="text-right grey lighten-2">{{ getReste(item) | roundEuroFilter }}</td>
                </tr>
            </template>
            <template v-slot:[`body.append`]>
                <tr class="v-data-table__nohoverezi" v-if="localInteSupports.length > 0">
                    <td v-if="offset_url_support !== 0" :colspan="offset_url_support"/>
                    <td class="text-right" v-for="inteSupport in localInteSupports" v-bind:key="inteSupport.id" >
                        <v-dialog-external-link v-if="inteSupport.url_link !== null"
                                                :link="inteSupport.url_link"
                                                openButtonMessage="Informations complémentaires"
                        />
                    </td>
                    <td class="text-right"></td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="confirmationDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>Avertissement</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            Vous avez saisi des données qui n'ont pas été enregistrées, êtes-vous sûr(e) de vouloir
                            continuer ?
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" dense>
                        <!-- Bouton modal -->
                        <v-col cols="12" sm="6" md="4" xl="3" xxl="2">
                            <v-btn color="primary" @click="closePopUp" block>NON, ANNULER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="12" sm="6" md="4" xl="3" xxl="2">
                            <v-btn color="primary" @click="confirmChangePagination" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import authService from "@/services/authService";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VDialogExternalLink from "@/components/VDialogExternalLink.vue";
    import _ from "lodash";

    /**
     * Composant affichant une table d'affectations d'intéressement
     * @display_name Interessement - VDatatableAffectationInte
     */
    export default {
        name: "VDatatableAffectationInte",
        components: {
            VFormattedNumberTextField,
            loadingScreen,
            VDialogExternalLink,
        },
        props: {
            /**
             * Message si table vide
             */
            noDataTextMessage: {
                type: String,
                required: false,
                default: "Aucune donnée disponible.",
            },
            /**
             * L'exercice sélectionné
             */
            selectedExercice: {
                type: Object,
            },
            /**
             * Contenu de la table
             */
            affectations: {
                type: Array,
                required: true,
            },
            /**
             * Les supports d'inte affichés dans la table
             */
            inteSupports: {
                type: Array,
                required: true,
            },
            /**
             * Hauteur de la data-table
             */
            height: {
                type: String,
                required: false,
            },
            /**
             * Si les données ont été modifiées et non enregistrées
             */
            dataChanged: {
                type: Boolean,
                required: true,
            },
            /**
             * Désactiver la saisie
             */
            disabledValue: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                total_items: -1,
                loadingPeriodes: false,
                options: {
                    sortBy: ['beneficiaire_name'],
                    sortDesc: [false],
                    page: 1,
                    itemsPerPage: 10,
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                        100,
                        200,
                    ],
                },
                placementsTemp: {},
                displayAffectedGrey: true,
                confirmationDialog: false,
                nextPagination: {},
                url_link: undefined,
                description: undefined,
                allowsEditVersementImmediat: undefined,
                canEditVersementImmediat: true,
                canEditAffectationSupports: true,
                isEspacePersonnel: false,
            };
        },
        computed: {
            /**
             * Intitulés de colonne
             */
            headers() {
                let headers = [];

                if (!this.isEspacePersonnel) {
                    headers.push({
                        text: "Bénéficiaire",
                        value: "beneficiaire_name",
                        sortable: false,
                    });
                    headers.push({
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                        sortable: false,
                    });
                }

                headers.push({
                    text: "Montant net intéressement",
                    value: "montant_net_inte",
                    align: "right",
                    sortable: false,
                })

                if (this.allowsEditVersementImmediat) {
                    headers.push({
                        text: "Versement immédiat",
                        value: "montant_brut_vers_immediat",
                        align: "right",
                        sortable: false,
                    });
                }

                _.forEach(this.localInteSupports, inteSupport => {
                    headers.push({
                        text: inteSupport.name,
                        value: "placements_" + inteSupport.id,
                        align: "right",
                        sortable: false,
                        tooltip: inteSupport.description,
                    });
                });

                headers.push({
                    text: "Reste à affecter",
                    value: "reste_a_affecter",
                    align: "right",
                    sortable: false,
                });

                return headers;
            },
            offset_url_support() { 
                
                let colonnetable = 3;

                if (this.localInteSupports !== undefined) {
                    if (this.allowsEditVersementImmediat) {
                        colonnetable += 1;
                    }
                }

                if (this.isEspacePersonnel) {
                    colonnetable -= 2;
                }
                
                return colonnetable;
            },
            localAffectations: {
                get: function () {
                    return this.affectations;
                },
                set: function (newvalue) {
                    this.$emit("update-affectations", newvalue);
                }
            },
            localInteSupports: {
                get: function () {
                    return this.inteSupports;
                },
                set: function (newvalue) {
                    this.$emit("update-inte-supports", newvalue);
                }
            },
            localDataChanged: {
                get: function () {
                    return this.dataChanged;
                },
                set: function (newvalue) {
                    this.$emit("update-data-changed", newvalue);
                }
            }
        },
        watch: {
            selectedExercice(new_exercice, old_exercice) {
                if (! _.isNil(new_exercice.id)) {
                    this.getAllAffectationParBeneficiaireSync();
                }
            },
            /**
             *  Vérifie si les données ont été modifiées par l'utilisateur. Si c'est le cas, on revient à la
             *  pagination initiale et on enregistre la nouvelle pagination. Sinon on récupère l'affectation
             */
            options: {
                handler(newV, oldV) {
                    if (! _.isNil(this.selectedExercice) && ! _.isNil(this.selectedExercice.id)) {
                        if (!this.confirmationDialog) {
                            if (this.localDataChanged) {
                                this.confirmationDialog = true;

                                // On enregistre la pagination qui sera utilisée si l'utilisateur confirme
                                // le changement
                                this.nextPagination.sortBy = newV.sortBy;
                                this.nextPagination.sortDesc = newV.sortDesc;
                                this.nextPagination.page = newV.page;
                                this.nextPagination.itemsPerPage = newV.itemsPerPage;

                                // Cette assignation trigger une nouvelle fois le watch. Une condition a été
                                // rajoutée pour ne pas repasser par ici : if (!this.confirmationDialog)
                                this.options.sortBy = oldV.sortBy;
                                this.options.sortDesc = oldV.sortDesc;
                                this.options.page = oldV.page;
                                this.options.itemsPerPage = oldV.itemsPerPage;
                            }
                            else {
                                this.getAllAffectationParBeneficiaireSync();
                            }
                        }
                    }
                },
                deep: true
            }
        },
        methods: {
            /**
             * Récupère les affectations d'inte par pagination
             */
            getAllAffectationParBeneficiaireSync() {
                if (!_.isNil(this.selectedExercice) && ! _.isNil(this.selectedExercice.id)) {
                    this.updateDataTable().then(data => {
                        this.localAffectations = data.items;
                        this.total_items = data.total;
                    });
                }
            },
            /**
             * Ferme la pop up et récupère les affectations paginées à partir de la nouvelle pagination.
             * Met également le localDataChanged à false
             */
            confirmChangePagination() {
                this.options.sortBy = this.nextPagination.sortBy;
                this.options.sortDesc = this.nextPagination.sortDesc;
                this.options.page = this.nextPagination.page;
                this.options.itemsPerPage = this.nextPagination.itemsPerPage;

                this.closePopUp();
                this.getAllAffectationParBeneficiaireSync();
                this.localDataChanged = false;
            },
            /**
             * Ferme la pop up
             */
            closePopUp() {
                this.confirmationDialog = false;
            },
            updateDataTable() {
                this.loadingPeriodes = true;

                return new Promise((resolve, reject) => {
                    const {sortBy, sortDesc, page, itemsPerPage} = this.options;

                    internalApi.affectationInte.getInteAffectationByInteExerciceId(
                        this.selectedExercice.id,
                        {
                            "page": page,
                            "rows_per_page": itemsPerPage,
                            "sort_key": sortBy[0],
                            "descending": sortDesc[0],
                        }
                    ).then(res => {
                        this.localInteSupports = res.supports;
                        this.allowsEditVersementImmediat = res.allows_edit_versement_immediat;
                        this.initPlacementsTemp(res);
                        resolve({
                            'items': res.affectations,
                            'total': res.total_items
                        });
                    }).catch(err => {
                        console.error("Erreur lors de la récupération des répartitions d'inte : ", err);
                        reject({
                            'items': [],
                            'total': 0
                        });
                    }).finally(() => {
                        this.loadingPeriodes = false;
                    });
                });
            },
            /**
             * Méthode récursive vérifiant que seuls des nombres sont saisis
             */
            areCorrect(fields) {
                if (fields.length === 0)
                    return true;
                return _.isNumber(fields.pop()) && this.areCorrect(fields);
            },
            /**
             * Méthode calculant le reste à affecter
             */
            getReste(repartition) {
                let reste = repartition.montant_net_inte - repartition.montant_brut_vers_immediat;
                _.forEach(repartition.placements, placement => {
                    reste -= placement.montant;
                });
                return Utils.roundNumber(reste);
            },
            initPlacementsTemp(res) {
                _.forEach(res.affectations, affectation => {
                    this.placementsTemp[affectation.inte_repartition_id] = {};
                    _.forEach(res.supports, inteSupport => {
                        this.placementsTemp[affectation.inte_repartition_id][inteSupport.id] = this.getMontantPlacementSupport(affectation, inteSupport.id);
                    });
                });
            },
            getMontantPlacementSupport(affectation, inte_support_de_placement_id) {
                const placement = _.find(affectation.placements, placement => placement.inte_support_de_placement_id === inte_support_de_placement_id);
                return placement ? placement.montant : 0;
            },
            onChangeMontantBrutVersement(repartition) {
                this.localDataChanged = true;
            },
            onChangeMontantPlacement(repartition, inte_support_de_placement_id, montant) {
                const placement = _.find(repartition.placements, placement => placement.inte_support_de_placement_id === inte_support_de_placement_id);
                if (placement) {
                    placement.montant = montant;
                } else {
                    repartition.placements.push({
                        "inte_support_de_placement_id": inte_support_de_placement_id,
                        "montant": montant,
                    })
                }
                this.localDataChanged = true;
            },
        }
    }
</script>
