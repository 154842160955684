<template>
    <h2 v-if="raison_sociale">
        {{ raison_sociale }}
        <v-tooltip right v-if="numero_tiers || siret" :open-on-hover="!$vuetify.breakpoint.smAndDown" :open-on-click="$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on }">
                <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
            </template>
            <span>
                <table class="text-left">
                    <tr v-if="numero_tiers">
                        <th>Numéro de tiers</th><td>{{ numero_tiers }}</td>
                    </tr>
                    <tr v-if="siret">
                        <th>SIRET</th><td>{{ siret }}</td>
                    </tr>
                </table>
            </span>
        </v-tooltip>
    </h2>
</template>
<script>
    export default {
        name: "v-personnes-morale-header-raison-sociale",
        props: {
            raison_sociale: {
                type: String,
                required: false
            },
            numero_tiers: {
                type: String,
                required: false
            },
            siret: {
                type: String,
                required: false
            },
        },
    }
</script>