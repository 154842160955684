<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-data-table :headers="computedHeaders"
                      :items="localAttributions"
                      :no-data-text="noDataTextMessage"
                      :loading="isLoading"
                      :server-items-length="total_items"
                      :height="height"
                      :mobile-breakpoint="0"
                      fixed-header
                      :options.sync="options"
                      :footer-props="footerProps"
                      :hide-default-footer="localAttributions.length < 10"
                      :dense="$vuetify.breakpoint.smAndDown"
                      class="elevation-1">
            <template v-for="h in computedHeaders" v-slot:[`header.${h.value}`]>
                <v-tooltip max-width="50%"
                           v-if="h.tooltip"
                           top
                           :key="h.value"
                           :open-on-hover="!$vuetify.breakpoint.smAndDown"
                           :open-on-click="$vuetify.breakpoint.smAndDown"
                >
                    <template v-slot:activator="{ on }">
                        <span>
                            <span>
                                {{ h.text }}
                            </span>
                            <v-icon small right v-on="{ ...on }" class="header-tooltip">
                                {{ "$vuetify.icons.info" }}
                            </v-icon>
                        </span>
                    </template>
                    <span v-html="h.tooltip"/>
                </v-tooltip>
                <span v-else :key="h.value">
                    {{ h.text }}
                </span>
            </template>
            <template v-slot:top v-if="localProgressBarTotal > 0">
                <v-tooltip top
                           :key="h.value"
                           :open-on-hover="!$vuetify.breakpoint.smAndDown"
                           :open-on-click="$vuetify.breakpoint.smAndDown"
                >
                    <template v-slot:activator="{ on }">
                        <!-- Ajout de padding pour que la zone de hover du tooltip soit plus haute -->
                        <div v-on="{ ...on }" class="pb-2">
                            <v-progress-linear rounded :value="progressPercent" :color="progressBarColor">
                            </v-progress-linear>
                        </div>
                    </template>
                    <span>{{localProgressBarCompleted | roundNumberFilter}} attribution(s) complétée(s) sur {{localProgressBarTotal | roundNumberFilter}} ({{progressPercent | roundPercentFilter}})</span>
                </v-tooltip>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td class="text-left grey lighten-2" v-if="!isEspacePersonnel">
                        {{ item.associe_name }}
                        <v-tooltip max-width="50%"
                                   v-if="!item.is_eligible"
                                   top
                                   :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                   :open-on-click="$vuetify.breakpoint.smAndDown"
                        >
                            <template v-slot:activator="{ on }">
                                <v-icon small right v-on="{ ...on }" class="header-tooltip">
                                    {{ "$vuetify.icons.info" }}
                                </v-icon>
                            </template>
                            <span>L'associé(e) n'est pas éligible à cette campagne d'abondement</span>
                        </v-tooltip>
                        <v-tooltip max-width="50%"
                                   v-else-if="item.exoneration_cs && !item.is_personne_morale"
                                   top
                                   :open-on-hover="!$vuetify.breakpoint.smAndDown"
                                   :open-on-click="$vuetify.breakpoint.smAndDown"
                        >
                            <template v-slot:activator="{ on }">
                                <v-icon small right v-on="{ ...on }" class="header-tooltip">
                                    {{ "$vuetify.icons.info" }}
                                </v-icon>
                            </template>
                            <span>L'associé(e) est exonéré(e) de charges sociales</span>
                        </v-tooltip>
                    </td>
                    <td class="text-left grey lighten-2" v-if="!isEspacePersonnel">{{ item.numero_tiers }}</td>
                    <template v-if="!exercice.abo_exercice.is_attribution_valide && !disabledValue">
                        <td class="text-right">
                            <ValidationProvider :vid="'nb_parts_sociales_abondement_' + item.associe_id"
                                                :name="'nombre de parts sociales abondées de ' + item.associe_name"
                                                :rules="{ integer: true, min_value: 0, max_value: nbMaxPartsSocialesSouscriptibles}"
                                                v-slot="{ validate, errors }">
                                <v-formatted-number-text-field slot="input"
                                                               v-model="item.nb_parts_sociales"
                                                               labelValue="Nombre de parts à souscrire"
                                                               :isSingleLine="true"
                                                               :isAutoFocus="false"
                                                               :prefixValue="computePrefixValue(item.nb_parts_sociales)"
                                                               :reverseValue="true"
                                                               :min="0"
                                                               :errors="errors"
                                                               :hideDetails="hideDetails && errors.length == 0"
                                                               :disabledValue="!item.is_eligible"
                                                               specificClass="v-text-field-inline-datatable-editing"
                                                               @input="onChangeNbPartsSociales(item, validate, errors)"
                                />
                            </ValidationProvider>
                        </td>
                    </template>
                    <template v-else>
                        <td class="text-right grey lighten-2">
                            {{ item.nb_parts_sociales | roundNumberFilter }} {{ computePrefixValue(item.nb_parts_sociales) }}
                        </td>
                    </template>
                    <td class="text-right grey lighten-2">{{ item.montant_part_associe | roundEuroFilter }}</td>
                    <td class="text-right grey lighten-2">{{ item.montant_part_abondee | roundEuroFilter }}</td>
                    <td class="text-right grey lighten-2">{{ item.montant_csg_crds | roundEuroFilter }}</td>
                    <td class="text-right grey lighten-2">{{ item.montant_capital_total | roundEuroFilter }}</td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="confirmationDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>Avertissement</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            Vous avez saisi des données qui n'ont pas été enregistrées, êtes-vous sûr(e) de vouloir
                            continuer ?
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" dense>
                        <!-- Bouton modal -->
                        <v-col cols="12" sm="6" md="4" xl="3" xxl="2">
                            <v-btn color="primary" @click="closePopUp" block>NON, ANNULER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="12" sm="6" md="4" xl="3" xxl="2">
                            <v-btn color="primary" @click="confirmChangePagination" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";

    export default {
        name: "DatatableAboAttribution",
        components: {
            VFormattedNumberTextField,
        },
        props: {
            /**
             * L'exercice sélectionné
             */
            exercice: {
                type: Object,
                required: false,
            },
            /**
             * Champs de recherche et de filtres
             */
            searchAssocieByName: {
                type: String,
                required: false,
                default: undefined,
            },
            searchAssocieByEligibilite: {
                type: String,
                required: false,
                default: undefined,
            },
            searchAssocieShowIncompletsOnly: {
                type: Boolean,
                required: false,
                default: undefined,
            },
            /**
             * Contenu de la table
             */
            attributions: {
                type: Array,
                required: false,
            },
            /**
             * Désactiver la saisie
             */
            disabledValue: {
                type: Boolean,
                required: false,
                default: false,
            },
            /**
             * Hauteur de la data-table
             */
            height: {
                type: String,
                required: false,
            },
            /**
             * Si les données ont été modifiées et non enregistrées
             */
            dataChanged: {
                type: Boolean,
                required: true,
            },
            progressBarTotal: {
                type: Number,
                required: false,
                default: 0,
            },
            progressBarCompleted: {
                type: Number,
                required: false,
                default: 0,
            },
            /**
             * Afficher ou non les erreurs sous les champs (rend plus gros chaque ligne)
             */
            hideDetails: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        mounted() {
            const delayInMillis = 250;
            this.debouncedComputeAbondementForAttributionLine = _.debounce(this.computeAbondementForAttributionLine, delayInMillis);
        },
        data() {
            return {
                nbMaxPartsSocialesSouscriptibles: undefined,
                isLoading: false,
                total_items: 0,
                options: {
                    sortBy: ['associe_name'],
                    sortDesc: [false],
                    page: 1,
                    itemsPerPage: 10,
                },
                nextPagination: {},
                confirmationDialog: false,
                isEspacePersonnel: false,
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                        100,
                        200,
                    ],
                },
                headers: [
                    {
                        text: "Associé(e)",
                        value: "associe_name",
                    },
                    {
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                        sortable: false,
                    },
                    {
                        text: "Nombre de parts sociales abondées souscrites",
                        value: "nb_parts_sociales",
                        align: "right",
                        sortable: false,
                    },
                    {
                        text: "Montant souscrit",
                        value: "montant_part_associe",
                        align: "right",
                        sortable: false,
                    },
                    {
                        text: "Montant abondement entreprise",
                        value: "montant_part_abondee",
                        align: "right",
                        sortable: false,
                    },
                    {
                        text: "CSG-CRDS",
                        value: "montant_csg_crds",
                        align: "right",
                        sortable: false,
                    },
                    {
                        text: "Capital acquis",
                        value: "montant_capital_total",
                        align: "right",
                        sortable: false,
                    },
                ],
                headerValuesToHideOnPersonal: [
                    "associe_name",
                    "numero_tiers",
                ],
            };
        },
        computed: {
            computedHeaders () {
                return this.headers.filter(head => {
                    return !this.isEspacePersonnel || !this.headerValuesToHideOnPersonal.includes(head.value);
                });
            },
            localAttributions: {
                get: function () {
                    return this.attributions;
                },
                set: function (newvalue) {
                    this.$emit("update-attributions", newvalue);
                },
            },
            localDataChanged: {
                get: function () {
                    return this.dataChanged;
                },
                set: function (newvalue) {
                    this.$emit("update-data-changed", newvalue);
                },
            },
            localProgressBarTotal: {
                get: function () {
                    return this.progressBarTotal;
                },
                set: function (newvalue) {
                    this.$emit("update-progress-bar-total", newvalue);
                },
            },
            localProgressBarCompleted: {
                get: function () {
                    return this.progressBarCompleted;
                },
                set: function (newvalue) {
                    this.$emit("update-progress-bar-completed", newvalue);
                },
            },
            noDataTextMessage: function() {
                let textMessage = undefined;
                if (! _.isNil(this.exercice) && ! _.isNil(this.exercice.id)) {
                    // Un exercice a bien été fetché
                    if (this.searchAssocieShowIncompletsOnly && this.progressPercent == 100) {
                        // Si on affiche les associés n'ayant pas renseigné leurs attributions et que tous les associés ont renseignés leurs attributions
                        textMessage = "Tous les associés ont renseigné leurs souscriptions de parts sociales abondées.";
                    } else {
                        textMessage = "Aucun associé à afficher.";
                    }
                } else {
                    textMessage = "Aucun exercice n'est sélectionné.";
                }
                return textMessage;
            },
            progressPercent: function() {
                let result = 100;
                if (this.localProgressBarTotal != 0) {
                    result = this.localProgressBarCompleted / this.localProgressBarTotal * 100;
                }
                return result;
            },
            progressBarColor: function() {
                let color = undefined;
                if (this.exercice.abo_exercice.is_attribution_valide) {
                    color = "grey";
                } else if (this.progressPercent == 100) {
                    color = "green";
                }
                return color;
            },
        },
        watch: {
            exercice(new_exercice, old_exercice) {
                this.getAllAttributionsByAssocie().then(res => {
                    if (_.isNil(this.nbMaxPartsSocialesSouscriptibles)) {
                        this.getNbPartsSocialesAbondeesMax();
                    }
                });
            },
            /**
             *  Vérifie si les données ont été modifiées par l'utilisateur. Si c'est le cas, on revient à la
             *  pagination initiale et on enregistre la nouvelle pagination. Sinon on récupère l'affectation
             */
            options: {
                handler(newV, oldV) {
                    if (! _.isNil(this.exercice) && ! _.isNil(this.exercice.id)) {
                        if (!this.confirmationDialog) {
                            if (this.localDataChanged) {
                                this.confirmationDialog = true;

                                // On enregistre la pagination qui sera utilisée si l'utilisateur confirme
                                // le changement
                                this.nextPagination.sortBy = newV.sortBy;
                                this.nextPagination.sortDesc = newV.sortDesc;
                                this.nextPagination.page = newV.page;
                                this.nextPagination.itemsPerPage = newV.itemsPerPage;

                                // Cette assignation trigger une nouvelle fois le watch. Une condition a été
                                // rajoutée pour ne pas repasser par ici : if (!this.confirmationDialog)
                                this.options.sortBy = oldV.sortBy;
                                this.options.sortDesc = oldV.sortDesc;
                                this.options.page = oldV.page;
                                this.options.itemsPerPage = oldV.itemsPerPage;
                            } else {
                                this.getAllAttributionsByAssocie();
                            }
                        }
                    }
                },
                deep: true,
            },
        },
        methods: {
            computePrefixValue(fieldValue) {
                if (_.isNil(fieldValue)) {
                    return "";
                } else if (fieldValue <= 1) {
                    return "part"
                } else {
                    return "parts"
                }
            },
            getAllAttributionsByAssocie() {
                if (!_.isNil(this.exercice) && ! _.isNil(this.exercice.id)) {
                    return this.fetchAboAttributions().then(data => {
                        this.localAttributions = data.items;
                        this.total_items = data.total;
                        this.localProgressBarTotal = data.progress_bar_total;
                        this.localProgressBarCompleted = data.progress_bar_completed;
                    });
                } else {
                    // Si pas d'exercice sélectionné, retourner une promesse valide quand même
                    return new Promise((resolve, reject) => {
                        resolve({
                            'items': [],
                            'total_items': 0,
                            'progress_bar_total': 0,
                            'progress_bar_completed': 0,
                        });
                    });
                }
            },
            getNbPartsSocialesAbondeesMax() {
                if (!_.isNil(this.exercice) && ! _.isNil(this.exercice.id)) {
                    return internalApi.aboAttribution.getNbPartsMaxByAboExerciceId(this.exercice.id).then(data => {
                        this.nbMaxPartsSocialesSouscriptibles = data.nb_max_parts_sociales_souscriptibles;
                    });
                } else {
                    // Si pas d'exercice sélectionné, retourner une promesse valide quand même
                    return new Promise((resolve, reject) => {
                        resolve({
                            'nb_max_parts_sociales_souscriptibles': 0,
                        });
                    });
                }
            },
            /**
             * Ferme la pop up et récupère les affectations paginées à partir de la nouvelle pagination.
             * Met également le localDataChanged à false
             */
            confirmChangePagination() {
                this.options.sortBy = this.nextPagination.sortBy;
                this.options.sortDesc = this.nextPagination.sortDesc;
                this.options.page = this.nextPagination.page;
                this.options.itemsPerPage = this.nextPagination.itemsPerPage;

                this.closePopUp();
                this.getAllAttributionsByAssocie();
                this.localDataChanged = false;
            },
            /**
             * Ferme la pop up
             */
            closePopUp() {
                this.confirmationDialog = false;
            },
            fetchAboAttributions() {
                this.isLoading = true;

                return new Promise((resolve, reject) => {
                    const {sortBy, sortDesc, page, itemsPerPage} = this.options;

                    internalApi.aboAttribution.getAboAttributionByAboExerciceId(
                        this.exercice.id,
                        {
                            "page": page,
                            "rows_per_page": itemsPerPage,
                            "sort_key": sortBy[0],
                            "descending": sortDesc[0],
                            "search_by_name": this.searchAssocieByName,
                            "search_by_eligibilite": this.searchAssocieByEligibilite,
                            "search_by_incomplet": this.searchAssocieShowIncompletsOnly,
                        }
                    ).then(res => {
                        resolve({
                            'items': res.items,
                            'total': res.total_items,
                            'progress_bar_total': res.progress_bar_total,
                            'progress_bar_completed': res.progress_bar_completed,
                        });
                    }).catch(err => {
                        console.error("Erreur lors de la récupération des attributions d'abondement : ", err);
                        reject({
                            'items': [],
                            'total': 0,
                            'progress_bar_total': 0,
                            'progress_bar_completed': 0,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                });
            },
            onChangeNbPartsSociales(attribution, validate, errors) {
                this.localDataChanged = true;
                this.debouncedComputeAbondementForAttributionLine(attribution, validate, errors);
            },
            computeAbondementForAttributionLine(attributionLine, validate, errors) {
                validate().then(success => {
                    if (success.valid) {
                        return new Promise((resolve, reject) => {
                            if (!_.isNil(attributionLine.nb_parts_sociales) && _.isInteger(attributionLine.nb_parts_sociales)) {
                                internalApi.aboAttribution.computeSingleAttributionPreview(
                                    this.exercice.id,
                                    {
                                        nb_parts_sociales: attributionLine.nb_parts_sociales,
                                        exoneration_cs: attributionLine.exoneration_cs,
                                    }
                                ).then(result => {
                                    attributionLine.nb_parts_sociales = result.nb_parts_sociales;
                                    attributionLine.montant_part_associe = result.montant_part_associe;
                                    attributionLine.montant_part_abondee = result.montant_part_abondee;
                                    attributionLine.montant_csg_crds = result.montant_csg_crds;
                                    attributionLine.montant_capital_total = result.montant_capital_total;
                                    resolve(result);
                                }).catch(error => {
                                    reject(error);
                                });
                            } else {
                                attributionLine.montant_part_associe = null;
                                attributionLine.montant_part_abondee = null;
                                attributionLine.montant_csg_crds = null;
                                attributionLine.montant_capital_total = null;
                                resolve(attributionLine);
                            }
                        });
                    }
                });
            },
        },
    };
</script>
