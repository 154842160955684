<template>
    <h2 v-if="civilite && prenom && nom">
        {{ civilite }} {{ prenom }} {{ nom }}
        <v-tooltip right v-if="numero_tiers || date_naissance || email" :open-on-hover="!$vuetify.breakpoint.smAndDown" :open-on-click="$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on }">
                <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
            </template>
            <span>
                <table class="text-left">
                    <tr v-if="numero_tiers">
                        <th>Numéro de tiers</th><td>{{ numero_tiers }}</td>
                    </tr>
                    <tr v-if="date_naissance">
                        <th>Date de naissance</th><td>{{ date_naissance | dateIsoToFrFilter }}</td>
                    </tr>
                    <tr v-if="email">
                        <th>E-mail</th><td>{{ email }}</td>
                    </tr>
                </table>
            </span>
        </v-tooltip>
    </h2>
</template>
<script>
    export default {
        name: "v-personnes-physique-header-nom-prenom",
        props: {
            civilite: {
                type: String,
                required: false
            },
            nom: {
                type: String,
                required: false
            },
            prenom: {
                type: String,
                required: false
            },
            numero_tiers: {
                type: String,
                required: false
            },
            date_naissance: {
                type: String,
                required: false
            },
            email: {
                type: String,
                required: false
            },
        },
    }
</script>