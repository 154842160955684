const icons = {
  //concept
  annulation: "fa-reply",
  annulationTotale: "fa-reply-all",
  associe: "fa-handshake",
  associes: "fa-users",
  capital: "fa-chart-line",
  conformite: "fa-award",
  exercices: "far fa-calendar-alt",
  landmark: "fa-landmark",
  mouvement: "fa-exchange-alt",
  affectation_pb: "fa-coins",
  per_mor: "fa-university",
  per_phy: "fa-user",
  regulation: "fa-euro-sign",
  repartition: "fa-chart-pie",
  pie_graph: "fa-chart-pie",
  societe: "fa-gavel",
  souscription: "fa-cart-arrow-down",
  forbidden: "fa-ban",
  documents: "fa-file",
  personalInfos: "fa-id-card",
  settings: "fa-cogs",
  squareCheck: "far fa-check-circle",
  squareCheckCross: "far fa-times-circle",
  squareCheckWait: "far fa-circle",
  squareCheckQuestion: "far fa-question-circle",
  statuts: "fa-book",
  version: "fa-code-branch",
  key: "fa-key",
  down_arrow: "fas fa-caret-down",
  up_arrow: "fas fa-caret-up",
  chevronDown: "fa-chevron-down",
  piggy_bank: "fa-piggy-bank",
  hand_money: "fa-hand-holding-usd",
  ventilation: "fa-random",
  eye: "fa-eye",
  eyeSlash: "fa-eye-slash",
  droitsAcces: "fa-user-lock",
  droitsAccesDeverrouille: "fa-lock-open",
  droitsAccesVerrouille: "fa-lock",

  // action
  add: "add",
  backward: "fa-backward",
  close: "fa-close",
  compute: "fa-cogs",
  create: "fa-start",
  delete: "fa-trash-alt",
  detail: "fa-file-alt",
  doubleSuccess: "fa-check-double",
  download: "fa-download",
  upload: "fa-upload",
  edit: "fa-edit",
  error: "fa-exclamation-circle",
  forward: "fa-forward",
  help: "fa-question",
  home: "fa-home",
  info: "fa-info-circle",
  question: "fa-question-circle",
  link: "fa-external-link-alt",
  menu: "fa-ellipsis-v",
  option: "fa-cog",
  percentage: "fa-percentage",
  print: "fa-print",
  remove: "fa-minus",
  save: "fa-save",
  search: "fa-search",
  share: "fa-share-alt",
  success: "fa-check",
  view: "fa-eye",
  sign_out: "fa-sign-out-alt",
  warning: "fa-exclamation-triangle",
  downloadPersonalFile: "fa-book",
  expand: "fa-search-plus",
  // file
  excel: "fa-file-excel",
  pdf: "fa-file-pdf",
  txt: "fa-file-alt",
  image: "fa-file-image",
  bars: "fas fa-bars"
};

export default icons;
