<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="dashboard" :withFooter="false" headerSize="130px">
        <template v-slot:header>
            <loading-screen ref="loadingComponent"/>
            <v-tabs v-model="currentTab">
                <v-tab key="company" @click="onCompanyClick">Societé</v-tab>
                <v-tab key="beneficiaire" @click="onBeneficiaireClick">Bénéficiaire</v-tab>
                <v-tab key="disponibilites_beneficiaires" @click="onDisponibilitesClick">Disponibilités</v-tab>
                <v-tab key="rapports" @click="onRapportsClick">Rapports</v-tab>
            </v-tabs>
            <v-row class="mt-1">
                <v-col md="3" lg="3" xl="2" v-show="showDateReference">
                    <ValidationProvider vid="dashboard_date_ref" name="date de référence"
                                        rules="required" v-slot="{ valid, errors }">
                        <DateField ref="date_reference"
                                   v-model="dateDataCharts"
                                   label="Date de référence"
                                   :clearable="false"
                                   :disabled="false"
                                   :errors="errors"
                                   :success="valid"/>
                    </ValidationProvider>
                </v-col>
                <v-col cols="4" v-show="showBeneficiaires">
                    <ValidationProvider vid="beneficiaire_id"
                                        name="Bénéficiaire"
                                        v-slot="{ errors }">
                        <v-beneficiaires-list :value="beneficiaire_id"
                                              label="Bénéficiaire"
                                              :errors="errors"
                                              @input="onUpdateSelectedBeneficiaire($event)"/>
                    </ValidationProvider>
                </v-col>
                <v-spacer/>
                <v-col cols="3">
                    <v-taux-remu-for-deblocage  v-show="showTauxRemu"
                                                :tauxRemu="tauxRemu"
                                                :dateReferenceTauxRemu="dateDataCharts"
                                                @update-taux-remu="tauxRemu = $event"/>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="company">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <v-row justify="space-around" class="mb-4">
                                    <v-col cols="6">
                                        <conteneur-graphique type="pieorbar"
                                                             :title="syntheseRepartitionParticipationDataTitle"
                                                             :data="syntheseRepartitionParticipationData"
                                                             :errorMessage="errorMessage"
                                                             :date="dateDataCharts"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <conteneur-graphique type="line_pb" 
                                                             :title="evolutionParticipationDataTitle"
                                                             :data="evolutionParticipationData"
                                                             :errorMessage="errorMessage"
                                                             :date="dateDataCharts"/>
                                    </v-col>
                                </v-row>
                                <v-datatable-participation-interets-totaux/>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>
                <v-tab-item key="beneficiaire">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main="{  }">
                            <v-container>
                                <template v-if="beneficiaire_id">
                                    <v-row justify="space-around" class="mb-4">
                                        <v-col cols="6">
                                            <conteneur-graphique type="pieorbar"
                                                                 :title="syntheseRepartitionParticipationDataTitle"
                                                                 :data="syntheseRepartitionParticipationByBenefData"
                                                                 :date="dateDataCharts"/>
                                        </v-col>
                                        <v-col cols="6">
                                            <conteneur-graphique type="line_pb" :title="evolutionParticipationDataTitle"
                                                                 :data="evolutionParticipationByBenefData"
                                                                 :date="dateDataCharts"/>
                                        </v-col>
                                    </v-row>
                                    <v-datatable-mouvements-supports-simplifie-creation :dateOperation="dateDataCharts"
                                                                                        :tauxRemu="tauxRemu"
                                                                                        :beneficiaireId="beneficiaire_id"
                                                                                        class="mt-4 mb-9"
                                                                                        ref="mouvementsSupportsSimplifie"/>
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                                <h2>Détail des opérations</h2>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-datatable-mouvements-supports :dateOperation="dateDataCharts"
                                                                                 :beneficiaireId="beneficiaire_id"
                                                                                 ref="mouvementsSupports"/>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </template>
                                <template v-else>
                                    Veuillez sélectionner un(e) bénéficiaire(e) via le champ en haut de page.
                                </template>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>
                <v-tab-item key="disponibilites_beneficiaires">
                    <v-datatable-disponibilites-beneficiaires datatableExcludedSizeDefault="23vh"
                                                              ref="disponibilitesBeneficiaires"
                                                              :dateReference="dateDataCharts"/>
                </v-tab-item>
                <v-tab-item key="rapports">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <h2>Participation de la société</h2>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="12" lg="8" xl="6">
                                        <v-data-table-download-reports :rapports="rapports_items"/>
                                        <!-- Popups pouvant être appelées par les boutons du v-data-table-download-reports ci-dessus -->
                                        <v-popup-asynchrone
                                            documentsNamePlural="états de la participation"
                                            documentPdfName="etats_de_la_participation"
                                            :isDocumentGenderFeminine="false"
                                            :showButton="false"
                                            :getStateDocuments="getStateEtatsDeLaParticipation"
                                            :generateDocuments="generateEtatsDeLaParticipation"
                                            :downloadDocuments="downloadEtatsDeLaParticipationAsy"
                                            :showDateReferenceDernierTraitement="true"
                                            ref="popup_asynchrone_etats_de_la_participation"
                                        />
                                        <!-- --------------------------------------------------------------------------------------- -->

                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import constantes from "@/utils/constantes";
    import Utils from "@/utils.js";
    import DateField from "@/components/DateField.vue";
    import ConteneurGraphique from "@/components/ConteneurGraphique";
    import VBeneficiairesList from "@/components/VBeneficiairesList";
    import VTauxRemuForDeblocage from "@/components/tauxRemunerationSupport/VTauxRemuForDeblocage"
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import moment from "moment/moment";
    import VDatatableMouvementsSupportsSimplifieCreation
        from "@/components/mouvementsSupports/VDatatableMouvementsSupportsSimplifieCreation";
    import BaseLayout from "@/components/BaseLayout";
    import VDatatableParticipationInteretsTotaux from "@/components/exercice/VDatatableParticipationInteretsTotaux.vue";
    import VDatatableDisponibilitesBeneficiaires from "@/components/mouvementsSupports/VDatatableDisponibilitesBeneficiaires";
    import VDatatableMouvementsSupports from "@/components/mouvementsSupports/VDatatableMouvementsSupports";
    import VDataTableDownloadReports from "@/components/VDataTableDownloadReports.vue";
    import VPopupAsynchrone from "@/components/VPopupAsynchrone.vue";

    export default {
        name: "DashboardParticipation",
        components: {
            VDatatableMouvementsSupports,
            BaseLayout,
            DateField,
            VBeneficiairesList,
            VTauxRemuForDeblocage,
            loadingScreen,
            VDatatableMouvementsSupportsSimplifieCreation,
            ConteneurGraphique,
            VDatatableParticipationInteretsTotaux,
            VDatatableDisponibilitesBeneficiaires,
            VDataTableDownloadReports,
            VPopupAsynchrone,
        },
        data() {
            return {
                showDateReference: true,
                showBeneficiaires: false,
                showTauxRemu: false,
                currentTab: undefined,
                dateDataCharts: moment().format("YYYY-MM-DD"),
                beneficiaire_id: undefined,
                tauxRemu: undefined,
                syntheseRepartitionParticipationDataTitle: "Répartition de la participation",
                syntheseRepartitionParticipationData: undefined,
                evolutionParticipationDataTitle: "Evolution de la participation",
                evolutionParticipationData: undefined,
                syntheseRepartitionParticipationByBenefData: undefined,
                evolutionParticipationByBenefData: undefined,
                rapports_items: [
                    {
                        name: "Détail des opérations",
                        downloadFunction: this.downloadDetailsOperations,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "États de la participation",
                        downloadFunction: this.downloadEtatsDeLaParticipation,
                        icon: "$vuetify.icons.pdf",
                    },
                ],
                errorMessage: undefined,
                selectedAction: undefined,
                valueGenerate: "generate",
                valueDownload: "download",
            };
        },
        watch: {
            dateDataCharts: function () {
                this.resetCompanyData();
                this.onDateChartsChange();
            },
        },
        mounted() {
            this.callCompanyCharts();
        },
        methods: {
            onUpdateSelectedBeneficiaire(newBenefId) {
                this.beneficiaire_id = newBenefId;
                this.callBeneficiaireCharts();
            },
            onDateChartsChange() {
                this.callBeneficiaireCharts();
                this.callCompanyCharts();
            },
            onBeneficiaireClick() {
                this.callBeneficiaireCharts();
                this.showDateReference = true;
                this.showBeneficiaires = true;
                this.showTauxRemu = true;
            },
            onCompanyClick() {
                this.showDateReference = true;
                this.showBeneficiaires = false;
                this.showTauxRemu = false;
            },
            onDisponibilitesClick() {
                this.showDateReference = true;
                this.showBeneficiaires = false;
                this.showTauxRemu = false;
            },
            onRapportsClick() {
                this.showDateReference = true;
                this.showBeneficiaires = false;
                this.showTauxRemu = false;
            },
            callCompanyCharts() {
                if (!_.isNil(this.dateDataCharts)) {
                    internalApi.pbCharts.companyChartsParticipationData(this.dateDataCharts)
                        .then(result => {
                            this.errorMessage = undefined;
                            this.syntheseRepartitionParticipationData = result.synthese_repartition_participation;
                            this.evolutionParticipationData = result.data_evolution_participation;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                            this.errorMessage = err;
                        });
                }
            },
            callBeneficiaireCharts() {
                if (!_.isNil(this.beneficiaire_id) && !_.isNil(this.dateDataCharts)) {
                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");
                    internalApi.pbCharts.beneficiaireChartsData(this.beneficiaire_id, this.dateDataCharts)
                        .then(result => {
                            this.syntheseRepartitionParticipationByBenefData = result.synthese_repartition_participation;
                            this.evolutionParticipationByBenefData = result.data_evolution_participation;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            resetCompanyData() {
                this.syntheseRepartitionParticipationData = undefined;
                this.evolutionParticipationData = undefined;
                this.evolutionParticipationByBenefData = undefined;
            },
            downloadDetailsOperations() {
                if (!_.isNil(this.dateDataCharts)) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                    internalApi.operations.exportDetailOperations(this.dateDataCharts)
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            Utils.downloadFile(response, "detail_operations_" + this.dateDataCharts + ".xlsx", Utils.excelMimetype);
                        })
                        .catch(err => {
                            console.error(err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            downloadEtatsDeLaParticipation() {
                this.$refs.popup_asynchrone_etats_de_la_participation.openPopup();
            },
            getStateEtatsDeLaParticipation(){
                return internalApi.operations.getStateEtatsDeLaParticipation();
            },
            /**
             * Lance la génération des états de la participation
             */
            generateEtatsDeLaParticipation(){
                return internalApi.operations.generateEtatsDeLaParticipation(this.dateReference)
            },
            /** Télécharge les états de la participation */
            downloadEtatsDeLaParticipationAsy(){
                return internalApi.operations.getEtatsDeLaParticipation();
            },
        }
    }
</script>
