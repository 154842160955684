<!-- VUETIFY2 - OK -->
<template>
    <v-card>
        <v-toolbar dense color="#E5E5E5" class="mb-2 elevation-1">
            <v-toolbar-title class="toolbar-title" style="white-space: normal;">{{ title }}</v-toolbar-title>
            <v-spacer/>
            <v-select
                v-if="type === 'pieorbar' && !$vuetify.breakpoint.smAndDown"
                v-model="selectedTypeChart"
                :items="diagramType"
                hide-details
                style="width: 20%;"
            />
            
            <!-- Mobile Version: Render a single button that shows a menu -->
            <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>{{ "$vuetify.icons.menu" }}</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <!-- Action buttons turned into menu items on mobile -->
                    <v-list-item v-for="actionChart in actionsChartsItems" :key="actionChart.key" @click="actionChart.onClick()">
                        <v-list-item-icon>
                            <v-icon>{{ actionChart.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ actionChart.detail }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="type === 'pieorbar'">
                        <v-list-item-icon>
                            <v-icon>{{ "$vuetify.icons.pie_graph" }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Affichage
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="type === 'pieorbar'" style="margin-top: -20px">
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <v-radio-group v-model="selectedTypeChart" dense>
                                <v-radio v-for="type in diagramType" :key="type.value" :label="type.text" :value="type.value"/>
                            </v-radio-group>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-icon-button-with-tool-tip v-else v-for="actionChart in actionsChartsItems" :key="actionChart.key"
                                    @click.stop="actionChart.onClick()"
                                    :detail="actionChart.detail"
                                    :icon="actionChart.icon"
                                    btnIsIcon tooltipTop btnColorClass="theme--light"/>
        </v-toolbar>
        <v-row align="center" justify="center" v-if="data !== undefined">
            <v-col>
                <v-row align="center" justify="center" v-if="data === undefined">
                    <v-col cols="auto">
                        {{ selectMessage}}
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" v-else>
                    <v-col>
                        <component :is="getChart()" ref="chart" :data="data" :limit="limit"/>
                        <v-row v-if="type === 'pieorbar'" align="center" justify="center" no-gutters>
                            <v-col md="4" xl="4" v-if="data.limit">
                                <v-select v-model="limit" :items="getLimits()" item-text="value"
                                          item-value="key" label="Eléments max. affichés" hide-details/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-dialog v-if="fullscreen" v-model="fullscreen" max-width="90%">
                <v-container class="customDialog">
                    <component :is="getChart()" ref="chartFullscreen" :data="data" :limit="limit"
                               class="customChartsHeightBig"/>
                    <v-row v-if="type === 'pieorbar'" align="center" justify="center" dense>
                        <v-col cols="12" md="6">
                            <v-row justify="end" dense>
                                <v-col cols="12" sm="6" v-if="data.limit">
                                    <v-select v-model="limit" :items="getLimits()" item-text="value"
                                              item-value="key" label="Nombre maximum d'éléments"/>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div style="height: 50px" justify="end">
                                        <v-radio-group v-if="type === 'pieorbar'" v-model="selectedTypeChart" row>
                                            <v-radio v-for="typeChart in switchChartsType" :key="typeChart.key"
                                                     :label="typeChart.label" :value="typeChart.key"/>
                                        </v-radio-group>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="end" dense>
                        <v-col cols="12" sm="6" md="3" xl="2" xxl="1">
                            <v-btn @click="close" block>Fermer</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" xl="2" xxl="1">
                            <v-btn @click="downloadPngBig()" color="primary" outlined block>Télécharger image PNG</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-dialog>
        </v-row>
        <div class="pa-3" v-else>
            <v-skeleton-loader v-if="errorMessage === undefined" :color="`lighten-4`" class="mx-auto" max-width="90%" min-height="400" type="image, card">
            </v-skeleton-loader>
            <span v-else class="pa-3 red lighten-4" max-width="90%">Une erreur s'est produite lors de la récupération des graphiques.</span>
        </div>
    </v-card>
</template>

<script>

    import LineChartCap from "@/graphiques/graphique_line_cap.js";
    import LineChartPb from "@/graphiques/graphique_line_pb.js";
    import PieChart from "@/graphiques/graphique_pie.js";
    import BarChart from "@/graphiques/graphique_bar.js";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";

    /**
     * Conteneur pour un graphique affiché dans les dashboards.
     * @displayName Commun - Conteneur graphique
     */
    export default {
        components: {
            LineChartCap,
            LineChartPb,
            PieChart,
            BarChart,
            VIconButtonWithToolTip
        },
        props: {
            /**
             * Le type de conteneur
             * @values line, pieorbar, pie, bar
             */
            type: undefined,
            /**
             * Le titre du graphique
             */
            title: undefined,
            /**
             * Les données du graphique
             */
            data: undefined,
            /**
             * La date à laquelle récupérer les données à afficher
             */
            date: undefined,
            /**
             * Erreur lors de la récupération des graphiques,
             */
            errorMessage: undefined,
        },
        data() {
            return {
                limit: undefined,
                actionsChartsItems: [],
                fullscreen: false,
                selectMessage: "Veuillez sélectionner un(e) associé(e) et/ou une date",
                switchChartsType: [
                    {
                        label: "Circulaire",
                        key: "pie"
                    },
                    {
                        label: "Histogramme",
                        key: "bar"
                    },
                ],
                selectedTypeChart: "pie",
                diagramType: [
                    {
                        "value": "pie",
                        "text": "Circulaire",
                    },
                    {
                        "value": "bar",
                        "text": "Histogramme",
                    },
                ],
            };
        },
        watch: {
            data: function () {
                if (this.data !== undefined) {
                    this.limit = this.data.limit;
                }
            },
            // Watch for changes to data.limit and update the limit accordingly
            'data.limit': {
                handler: function(newLimit) {
                    if (newLimit !== undefined) {
                        this.limit = newLimit;
                    }
                },
                immediate: true
            }
        },
        mounted() {
            this.actionsChartsItems = [
                {
                    detail: "Agrandir",
                    key: "expand",
                    icon: "$vuetify.icons.expand",
                    onClick: this.switchFullScreen
                },
                {
                    key: "png",
                    detail: "Exporter au format PNG",
                    icon: "$vuetify.icons.image",
                    onClick: this.downloadPngSmall
                },
                {
                    key: "excel",
                    detail: "Exporter au format excel",
                    icon: "$vuetify.icons.excel",
                    onClick: this.downloadExcel
                }
            ]
        },
        methods: {
            getLimits() {
                let result = [];
                for (let i = 0; i < this.data.data.length; i++) {
                    result.push({"key": i, "value": i + 1});
                }
                return result;
            },
            downloadPngSmall() {
                this.$refs.chart.generatePng(this.title);
            },
            downloadPngBig() {
                this.$refs.chartFullscreen.generatePng(this.title);
            },
            switchFullScreen() {
                this.fullscreen = true;
            },
            close() {
                this.fullscreen = false;
            },
            downloadExcel() {
                this.$refs.chart.downloadExcelData(this.title, this.date);
            },
            getChart() {
                const pie = "pie";
                const bar = "bar";
                const lineCap = "line_cap";
                const linePb = "line_pb";
                const pieorbar = "pieorbar";
                if (this.type === lineCap) {
                    return LineChartCap;
                }
                if (this.type === linePb) {
                    return LineChartPb;
                }
                if (this.type === pie) {
                    return PieChart;
                }
                if (this.type === bar) {
                    return BarChart;
                }
                if (this.type === pieorbar) {
                    if (this.selectedTypeChart === pie) {
                        return PieChart;
                    }
                    if (this.selectedTypeChart === bar) {
                        return BarChart;
                    }
                }
            }
        }
    };
</script>
<style scoped>
    .customDialog {
        background-color: white;
        box-shadow: none;
        border-radius: 6px;
        color: whitesmoke;
    }

    .customChartsHeightBig {
        height: 70vh;
    }

    .customChartsHeightSmall {
        height: 45vh;
        display: table;
    }

    .verticalAlign {
        display: table-cell;
        vertical-align: middle;
    }

    @media (max-width: 600px) {
        .toolbar-title {
            font-size: 14px;
            text-overflow: clip;
        }
    }
</style>
