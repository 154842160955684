<!-- VUETIFY2 - OK -->
<template>
    <v-tooltip top
			   :open-on-hover="!$vuetify.breakpoint.smAndDown"
			   :open-on-click="$vuetify.breakpoint.smAndDown"
    >
        <template v-slot:activator="{ on }">
            <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
        </template>

        <v-data-table :headers="headers_detail_dette"
                      :items="[detail]"
                      class="vertical"
                      hide-default-header
                      hide-default-footer
                      dark
                      dense>
            <template v-slot:body="{items}">
                <tbody>
                    <tr v-for="header in headers_detail_dette"
                        :key="header.value">
                        <th>{{header.text}}</th>
                        <td class="text-right" v-for="item in items" :key="item.id">
                            <template v-if="header.value != 'montant_charges_sociales' || item[header.value] != null">{{item[header.value] | roundEuroFilter}}</template>
                            <template v-else>Exonéré(e)</template>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-tooltip>
</template>

<script>
export default {
    name: "VTooltipTableTaxe",
    props: {
        detail: Object
    },
    data() {
        return {
            headers_detail_dette: [
                {text: "Intérêts échus bruts", sortable: false, value: "montant_interets_echus_brut", align: "right", class: "th"},
                {text: "Intérêts courus bruts", sortable: false, value: "montant_interets_courus_brut", align: "right"},
                {text: "Charges sociales", sortable: false, value: "montant_charges_sociales", align: "right"},
                {text: "Prélèvement forfaitaire", sortable: false, value: "montant_prelevement_forfaitaire", align: "right"},
                {text: "Intérêts nets", sortable: false, value: "montant_total_interets_net", align: "right"},
            ],
        };
    },
}
</script>

<style scoped>
    div.v-tooltip__content {
        background-color: rgba(50, 50, 50, 0.9);
    }
    div.v-tooltip__content .theme--dark.v-data-table {
        background-color: rgba(0, 0, 0, 0);
        color: rgba(255, 255, 255, 0.9)
    }
</style>
