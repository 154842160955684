<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" :headerSize="$vuetify.breakpoint.xsOnly ? '130px' : '90px'">
        <template v-slot:header>
            <v-container>
                <v-row align="center" no-gutters>
                    <v-col cols="12" sm="6" xl="4" xxl="2">
                        Veuillez sélectionner une date afin de visualiser l'état de votre portefeuille à cette date&nbsp;:
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" xl="2" xxl="1">
                        <DateField ref="date_annu"
                                   v-model="dateDataCharts"
                                   label="Date de référence"
                                   :clearable="false"
                                   name="Date de référence"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <conteneur-graphique type="pieorbar" :title="syntheseRepartitionParticipationDataTitle"
                                             :data="syntheseRepartitionParticipationData"
                                             :date="dateDataCharts"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <conteneur-graphique type="line_pb" :title="evolutionParticipationDataTitle"
                                             :data="evolutionParticipationData"
                                             :date="dateDataCharts"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </base-layout>
</template>
<script>
    import moment from "moment/moment";
    import DateField from "@/components/DateField.vue";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import ConteneurGraphique from "@/components/ConteneurGraphique.vue";
    import BaseLayout from "@/components/BaseLayout";

    export default {
        components: {
            DateField,
            loadingScreen,
            ConteneurGraphique,
            BaseLayout,
        },
        $_veeValidate: {
            validator: 'new'
        },
        data() {
            return {
                dateDataCharts: moment().format("YYYY-MM-DD"),
                syntheseRepartitionParticipationDataTitle: "Répartition de la participation",
                syntheseRepartitionParticipationData: undefined,
                evolutionParticipationDataTitle: "Évolution de la participation",
                evolutionParticipationData: undefined,
            };
        },
        watch: {
            dateDataCharts: function () {
                this.callBeneficiaireCharts();
            }
        },
        mounted() {
            this.callBeneficiaireCharts();
        },
        methods: {
            callBeneficiaireCharts() {
                if (!_.isNil(this.dateDataCharts)) {
                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");
                    internalApi.pbCharts.beneficiaireChartsIndividuelData(this.dateDataCharts)
                        .then(result => {
                            this.$refs.loadingComponent.resetLoading();
                            this.syntheseRepartitionParticipationData = result.synthese_repartition_participation;
                            this.evolutionParticipationData = result.data_evolution_participation;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
        }
    };
</script>
