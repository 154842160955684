var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',[_c('legend',[_vm._v("Adresse")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localNumeroVoie","name":"Numéro de voie","rules":"max:4|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"labelValue":"Numéro de voie","errors":errors,"disabled":_vm.disabled},model:{value:(_vm.localNumeroVoie),callback:function ($$v) {_vm.localNumeroVoie=$$v},expression:"localNumeroVoie"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('v-select',{attrs:{"items":_vm.typeVoieChoices,"label":"Type de la voie","clearable":""},model:{value:(_vm.localTypeVoie),callback:function ($$v) {_vm.localTypeVoie=$$v},expression:"localTypeVoie"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localAddress1","name":"Adresse 1","rules":"max:80|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Adresse 1","disabled":_vm.disabled},model:{value:(_vm.localAddress1),callback:function ($$v) {_vm.localAddress1=$$v},expression:"localAddress1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localAddress2","name":"Adresse 2","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Adresse 2","disabled":_vm.disabled},model:{value:(_vm.localAddress2),callback:function ($$v) {_vm.localAddress2=$$v},expression:"localAddress2"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localZipCode","name":"Code postal","rules":{ regex: /^\w*$/, required: true, max:20}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Code postal","disabled":_vm.disabled},model:{value:(_vm.localZipCode),callback:function ($$v) {_vm.localZipCode=$$v},expression:"localZipCode"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localCity","name":"Ville","rules":{ regex: /^([\xC0-\xFFa-zA-Z- '()]+)$/, max: 80, required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ville","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.localCity),callback:function ($$v) {_vm.localCity=$$v},expression:"localCity"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"localPays","name":"Pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-countries-list',{attrs:{"label":"Pays","errors":errors,"disabled":_vm.disabled},model:{value:(_vm.localCountry),callback:function ($$v) {_vm.localCountry=$$v},expression:"localCountry"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }