<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false"
                 :headerSize="$vuetify.breakpoint.xsOnly ? '130px' : '80px'"
                 datatableExcludedSizeDefault="220px"
                 datatableExcludedSizeXsOnly="190px"
    >
        <template v-slot:header>
            <v-container>
                <v-row align="center" no-gutters>
                    <v-col cols="12" sm="6" xl="4" xxl="2">
                        Veuillez sélectionner une date afin d'afficher l'état des supports&nbsp;:
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" xl="2" xxl="1">
                        <DateField
                            ref="date_annu"
                            v-model="dateReference"
                            label="Date de référence"
                            :clearable="false"
                            name="Date de référence"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container>
                <v-datatable-mouvements-supports-simplifie-espace-beneficiaire
                    :columnsToHide="['interets_courus']"
                    :height="datatableSize"
                    :dateOperation="dateReference"
                    ref="mouvementsSupports"
                />
            </v-container>
        </template>
    </base-layout>
</template>
<script>
    import moment from "moment/moment";
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VDatatableMouvementsSupportsSimplifieEspaceBeneficiaire
        from "@/components/mouvementsSupports/VDatatableMouvementsSupportsSimplifieEspaceBeneficiaire";
    import DateField from "@/components/DateField.vue";


    export default {
        name: "EspaceBeneficiaireOperations",
        components: {
            loadingScreen,
            VDatatableMouvementsSupportsSimplifieEspaceBeneficiaire,
            BaseLayout,
            DateField,
        },
        data () {
            return {
                dateReference: moment().format("YYYY-MM-DD"),
            }
        }
    }
</script>
