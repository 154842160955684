<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-row no-gutters justify="start">
            <v-col cols="4" class="ml-5 pa-0">
                <v-text-field v-model="search"
                              append-icon="search"
                              label="Filtrer les tiers"
                              single-line/>
            </v-col>
        </v-row>
        <v-data-table :footer-props="{itemsPerPageOptions:[10,20,50,-1]}"
                      :headers="headers"
                      :items="userList"
                      :options.sync="pagination"
                      class="elevation-1"
                      fixed-header
                      :height="height"
                      :search="search"
                      :loading="isLoading">

            <template v-for="h in headers" v-slot:[`header.${h.value}`]>
                <v-tooltip v-if="h.tooltip" top :key="h.value">
                    <template v-slot:activator="{ on }">
                        <span class="ml-2">
                        <span>
                            {{ h.text }}
                        </span>
                            <v-icon small right v-on="{ ...on }" class="header-tooltip">
                                {{ "$vuetify.icons.info" }}
                            </v-icon>
                        </span>
                    </template>
                    <span v-html="h.tooltip"/>
                </v-tooltip>
                <span v-else :key="h.value">
                    {{ h.text }}
                </span>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.numero_tiers }}</td>
                    <td>{{ item.username }}</td>
                    <td>{{ item.mail }}</td>
                    <td>{{ item.date_creation | dateIsoToFrFilterWithHourMin}}</td>
                    <td>
                        <v-checkbox
                            v-model="item.active"
                            primary
                            class="align-center justify-center"
                            hide-details>
                        </v-checkbox>
                    </td>
                    <td>
                        <v-checkbox
                            v-model="item.is_tiers"
                            primary
                            hide-details
                            :disabled="!item.active">
                        </v-checkbox>

                    </td>
                    <td>
                        <v-checkbox
                            v-model="item.is_gestionnaire"
                            primary
                            hide-details
                            :disabled="!item.active"
                            @change="checkGestionnaire($event, item.id)">
                        </v-checkbox>
                    </td>
                    <td>
                        <v-checkbox
                            v-model="item.is_responsable"
                            primary
                            hide-details
                            :disabled="!item.active"
                            @change="checkResponsable($event, item.id)">
                        </v-checkbox>
                    </td>
                    <td>
                        <v-row justify="center">
                            <v-col md="1" lg="1" xl="1">
                                <v-password-reset-dialog :title="'Modification du mot de passe de ' + item.username"
                                                         @action-confirmee="onPasswordReset($event, item.id)"
                                                         :ref="'passwordResetDialog-'+ item.id"/>
                            </v-col>
                            <v-col md="1" lg="1" xl="1">
                                <v-custom-confirmation-dialog
                                    message="Etes vous sûr(e) de vouloir supprimer cet accès utilisateur ?"
                                    :isIcon="true"
                                    :iconValue="'$vuetify.icons.delete'"
                                    @action-confirmee="onDeleteUser(item.id)"
                                    tooltipDetailMessage="Supprimer l'accès utilisateur."/>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import rolesList from "@/router/rolesList.js"
import VPasswordResetDialog from "@/components/administration/VPasswordResetDialog"
import internalApi from "@/api/internalApi";
import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog";

export default {
    components: {VPasswordResetDialog,
        VCustomConfirmationDialog},
    props: {
        userList: {
            type: Array,
            required: true
        },
        height: {
            type: String,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            search: "",
            headers: [
                {
                    text: "Nom du tiers",
                    value: "name",
                },
                {
                    text: "Numéro de tiers",
                    value: "numero_tiers",
                },
                {
                    text: "Nom d'utilisateur",
                    value: "username",
                },
                {
                    text: "Email associé",
                    value: "mail",
                },
                {
                    text: "Date de création",
                    value: "date_creation",
                },
                {
                    text: "Actif",
                    value: "active",
                    align: "center",
                },
                {
                    text: "Accès personnel",
                    value: "is_tiers",
                    align: "center",
                    tooltip: "L’accès personnel permet aux tiers d’accéder à leur portail personnel depuis lequel ils peuvent consulter l’ensemble de leurs informations individuelles."
                },
                {
                    text: "Accès gestionnaire",
                    value: "is_gestionnaire",
                    align: "center",
                    tooltip: "L’accès gestionnaire permet à l’utilisateur d’accéder à l’ensemble de " +
                        "l’application excepté les fonctionnalités suivantes : <br/>" +
                        "Espace société<br/>" +
                        "Espace Administration <br/>" +
                        "Modification et suppression de mouvements<br/>" +
                        "Validation d’exercice<br/>" +
                        "Edition de fichiers SEPA<br/>" +
                        "Suppression des documents des associé(e)s<br/>"
                },
                {
                    text: "Accès responsable",
                    value: "is_responsable",
                    align: "center",
                    tooltip: "L’accès responsable permet à l’utilisateur d’accéder à la totalité des " +
                        "fonctionnalités de l’application"
                },
                {
                    text: "Actions",
                    value: rolesList.RESPONSABLE,
                    align: "center",
                    sortable: false,
                },
            ],
            pagination: {
                sortBy: ['name'],
            }
        };
    },
    methods: {
        checkGestionnaire(val, id) {
            if (val) {
                this.userList.forEach(user => {
                    if (user.id === id) {
                        user.is_responsable = false
                    }
                })
            }
        },
        checkResponsable(val, id) {
            if (val) {
                this.userList.forEach(user => {
                    if (user.id === id) {
                        user.is_gestionnaire = false
                    }
                })
            }
        },
        onPasswordReset(passwords, userId) {
            const body = {"password": passwords.new_password};
            internalApi.user.resetPasswordResponsable(userId, body)
                .then(res => {
                    const popupToClose = "passwordResetDialog-" + userId;
                    this.$refs[popupToClose].closeDialog();
                })
                .catch(err => {
                    console.error(err);
                });
        },
        onDeleteUser(userId) {
            internalApi.user.deleteByIdWithToastConfirmation(userId)
                .then(result => this.$emit("reload-user-list"))
                .catch(err => {
                    console.error(err);
                });
        }
    }
};
</script>

<style scoped>
.v-input__slot {
    justify-content: center;
    align-content: center;
}

.v-input--checkbox {
    margin: 0px;
}

th.column.sortable i.header-tooltip {
    opacity: 1;
}
</style>
