<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{}">
        <base-layout :withFooter="false" :headerSize="$vuetify.breakpoint.xsOnly ? '100px' : '80px'">
            <template v-slot:header>
                <v-container>
                    <v-row align="center" no-gutters>
                        <v-col cols="12" sm="6" xl="4" xxl="2">
                            Veuillez sélectionner un exercice afin de télécharger les documents correspondants&nbsp;:
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="2" xxl="1">
                            <ValidationProvider vid="selectedExercice" name="Exercice"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-exercices-list :value="selectedExercice"
                                                  @input="selectedExerciceChange($event)"
                                                  :errors="errors"
                                                  ref="exercices"/>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:main>
                <v-container>
                    <loading-screen ref="loadingComponent"/>
                    <v-row>
                        <v-col md="12" lg="8" xl="6">
                            <v-data-table-download-reports :rapports="rapports_items" :disabled="!selectedExercice"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </base-layout>
    </ValidationObserver>
</template>
<script>
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VExercicesList from "@/components/VExercicesList";
    import BaseLayout from "@/components/BaseLayout";
    import VDataTableDownloadReports from "@/components/VDataTableDownloadReports.vue";

    export default {
        name: "VScicEspaceAssocie",
        components: {
            loadingScreen,
            VExercicesList,
            BaseLayout,
            VDataTableDownloadReports,
        },
        data() {
            return {
                selectedExercice: undefined,
                rapports_items: [
                    {
                        name: "Attestation fiscale",
                        downloadFunction: this.downloadAttestationFiscale,
                        icon: "$vuetify.icons.pdf",
                    },
                ],
            }
        },
        methods: {
            downloadAttestationFiscale() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.scicDocuments.getAttestationFiscale(this.selectedExercice)
                    .then(response => {
                        this.$refs.loadingComponent.resetLoading();
                        Utils.downloadFile(response, "Attestation_fiscale.pdf", Utils.pdfMimetype);
                    })
                    .catch(err => {
                        console.error(err);
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            selectedExerciceChange(newSelectedExerciceId) {
                this.selectedExercice = newSelectedExerciceId;
            },
        }
    }
</script>
