// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import VPfu from "@/components/fiscalite/VPfu";
import Utils from "@/utils.js";
import constantes from "@/utils/constantes"
import {TOAST} from "@/plugins/toast/definition";
import _ from "lodash"

/**
 * Composant affichant les données PFU participation
 * @display_name Participation - PFU
 */
export default {
    mounted() {
        // Cannot mutate props directly
        this.$emit("update-selectable-exercice", false);
        this.$emit("update-selectable-year", true);
        this.$emit("update-selectable-month", true);
    },
    extends: VPfu,
    watch: {
        year () {
            if(!_.isNil(this.month) && this.selectedCerfa!==undefined){
                this.loadDataSync();
            }
        },
        month () {
            if(!_.isNil(this.month) && this.selectedCerfa!==undefined){
                this.loadDataSync();
            }
        },
        selectedCerfa() {
            if(!_.isNil(this.month) && this.selectedCerfa!==undefined){
                this.loadDataSync();
            }
        },
    },
    methods: {
        /**
         * Détermine les intitulés de colonne
         */
        computeHeaders(){
            return [
                {
                    text: "Libellé de taux",
                    value: "libelle_taux",
                    sortable: true,
                    align: "left",
                    width: "40%"
                },
                {
                    text: "Base imposable",
                    value: "base_imposable",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
                {
                    text: "Taux",
                    value: "taux",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
                {
                    text: "Code",
                    value: "code",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
                {
                    text: "Impôt",
                    value: "impot",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
            ];
        },
        /**
         * Charge les données dans les tableaux
         */
        loadData() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                internalApi.fiscalite_participation.pfu2777Infos(this.year, this.month, this.selectedCerfa.name)
                    .then(res => {
                        const dateFin = res.date_fin;
                        const total = res.total;
                        const titrePfuTableauPrelevementsForfaitaires = res.titre_pfu_tableau_prelevements_forfaitaires;
                        const detailPfuTableauPrelevementsForfaitaires = res.detail_pfu_tableau_prelevements_forfaitaires;
                        const titrePfuTableauChargesInterets = res.titre_pfu_tableau_charges_interets;
                        const detailPfuTableauChargesInterets = res.detail_pfu_tableau_charges_interets;
                        resolve({
                            "dateFin": dateFin,
                            "total": total,
                            "titrePfuTableauPrelevementsForfaitaires": titrePfuTableauPrelevementsForfaitaires,
                            "detailPfuTableauPrelevementsForfaitaires": detailPfuTableauPrelevementsForfaitaires,
                            "titrePfuTableauChargesInterets": titrePfuTableauChargesInterets,
                            "detailPfuTableauChargesInterets": detailPfuTableauChargesInterets
                        });
                    })
                    .catch(err => {
                        console.error("Erreur lors de la récupération des pfu : ", err);
                        reject({
                            "dateFin": undefined,
                            "total": undefined,
                            "titrePfuTableauPrelevementsForfaitaires": undefined,
                            "detailPfuTableauPrelevementsForfaitaires": undefined,
                            "titrePfuTableauChargesInterets": undefined,
                            "detailPfuTableauChargesInterets": undefined
                        });
                    }).finally(() => {
                        this.loading = false;
                    });
            });
        },
        /**
         * Contrôle supplémentaire avant de télécharger un pfu 2777 participation
         */
        checkCanDownloadPfuParticipation: function() {
            let checkVersion = this.checkCanDownloadPfu();
            if (_.isNil(this.month)){
                TOAST.error(constantes.toast_message.missingCerfaMonthTitle, constantes.toast_message.missingCerfaMonthBody);
                return false;
            }
            return checkVersion;
        },
        /**
         * Télécharge le cerfa 2777
         */
        downloadPfu2777PDF: function () {
            if(!this.checkCanDownloadPfuParticipation()){
                return;
            }
            this.$refs.loadingComponent.setLoading("Récupération du PDF en cours ...");
            internalApi.fiscalite_participation.pfu2777PDF(this.year, this.month, this.selectedCerfa.name)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "PDF_2777.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
          },
    }
};
