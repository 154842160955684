<!-- VUETIFY2 - NATIF -->
<template>
    <base-layout :withFooter="false" headerSize="80px">
        <template v-slot:header>
            <v-tabs 
                v-model="currentTab"
                scrollable
                show-arrows
                mobile-breakpoint="960"
            >
                <v-tab key="documents_partages">Documents partagés</v-tab>
                <v-tab key="documents_personnels">Documents personnels</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab" touchless>
                <v-tab-item key="documents_partages">
                    <v-container fluid>
                        <loading-screen ref="loadingComponent"/>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-document-pb-accord
                                    v-if="hasAccessParticipation()"
                                    :canUpload="false"
                                    :canDelete="false"
                                    :areDocumentsPersonnels="true"
                                />
                                <v-document-inte-accord
                                    v-if="hasAccessInteressement()"
                                    :canUpload="false"
                                    :canDelete="false"
                                    :areDocumentsPersonnels="true"
                                />
                                <v-document-statuts
                                    v-if="hasAccessCapital()"
                                    :canUpload="false"
                                    :canDelete="false"
                                    :areDocumentsPersonnels="true"
                                />
                            </v-col>
                        </v-row>
                        <v-datatable-docs-list
                            :documentsList="docs"
                            :collaborateurId="collaborateurId"
                            :typeDocument="typeDocumentGeneral"
                            :areDocumentsPersonnels="true"
                            :canDelete="false"
                            :canUpload="false"
                            :hasAccess="true"
                        />
                    </v-container>
                </v-tab-item>
                <v-tab-item key="documents_personnels">
                    <v-documents-list ref="documents" :canDelete="false" :canUpload="true"
                            :collaborateurId="collaborateurId" :areDocumentsPersonnels="true"/>
                </v-tab-item>
            </v-tabs-items>

        </template>
    </base-layout>
</template>

<script>
import authService from "@/services/authService";
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";
import BaseLayout from "@/components/BaseLayout";
import loadingScreen from "@/components/LoadingScreen.vue";
import VDocumentsList from "@/views/documents/VDocumentsList.vue";
import VDocumentPbAccord from "@/components/documents/VDocumentPbAccord";
import VDocumentInteAccord from "@/components/documents/VDocumentInteAccord";
import VDocumentStatuts from "@/components/documents/VDocumentStatuts";
import VDatatableDocsList from "@/views/documents/VDatatableDocsList.vue";


/**
 * Vue affichant les documents d'un utilisateur sur son espace personnel
 * @displayName Commun - Espace Personnel Documents
 */
export default {
    components: {
        BaseLayout,
        loadingScreen,
        VDocumentsList,
        VDocumentPbAccord,
        VDocumentInteAccord,
        VDocumentStatuts,
        VDatatableDocsList,
    },
    data() {
        return {
            currentTab: null,
            id: 0,
            docs: [],
            typeDocumentGeneral: "General",
        };
    },
    computed: {
        collaborateurId: function () {
            return authService.getIdPersonne();
        },
    },
    mounted() {
        this.getAllAutreDocuments(this.typeDocumentGeneral);
    },
    methods: {
        getAllAutreDocuments() {
            return internalApi.documents.getDocumentsGeneral()
            .then(res => {
                this.docs = res;
            });
        },

        hasAccessParticipation: function () {
            return authService.hasAccessParticipation();
        },
        hasAccessCapital: function () {
            return authService.hasAccessCapital();
        },
        hasAccessInteressement: function () {
            return authService.hasAccessInteressement();
        },
    }
}
</script>