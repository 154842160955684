var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errorsForm = ref.errors;
var reset = ref.reset;
return [_c('base-layout',{attrs:{"datatableExcludedSizeDefault":"50vh","datatableExcludedSizeXsOnly":"40vh","datatableExcludedSizeSmOnly":"20vh","headerSize":_vm.$vuetify.breakpoint.smAndDown ? '50px' : '60px'},scopedSlots:_vm._u([{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[(!_vm.isEspaceBeneficiaire)?_c('ValidationProvider',{attrs:{"vid":"beneficiaire_id","name":"Bénéficiaire","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-beneficiaires-list',{attrs:{"label":"Bénéficiaire","errors":errors,"disabled":!_vm.isEditable},model:{value:(_vm.deblocage.beneficiaire_id),callback:function ($$v) {_vm.$set(_vm.deblocage, "beneficiaire_id", $$v)},expression:"deblocage.beneficiaire_id"}})]}}],null,true)}):_c('v-text-field',{attrs:{"value":_vm.beneficiaire_name,"label":"Bénéficiaire","disabled":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"date_mouvement","name":"Date de l'opération","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":"Date de l'opération","errors":errors,"clearable":false,"disabled":!_vm.isEditable},model:{value:(_vm.deblocage.date_mouvement),callback:function ($$v) {_vm.$set(_vm.deblocage, "date_mouvement", $$v)},expression:"deblocage.date_mouvement"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('v-pb-supports-list',{attrs:{"withDefaultValue":"","label":"Support","supportFilter":'actif_placement_interne',"isEnabled":_vm.isEditable},model:{value:(_vm.deblocage.support_id),callback:function ($$v) {_vm.$set(_vm.deblocage, "support_id", $$v)},expression:"deblocage.support_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('v-taux-remu-for-deblocage',{attrs:{"tauxRemu":_vm.deblocage.taux_de_remuneration_interets_courus,"isEditable":_vm.isEditable,"dateReferenceTauxRemu":_vm.deblocage.date_mouvement},on:{"update-taux-remu":function($event){_vm.deblocage.taux_de_remuneration_interets_courus = $event}}})],1)],1),(_vm.isEditable)?_c('v-creation-mouvement-supports-simplifie',{ref:"mouvementsSupports",attrs:{"beneficiaireId":_vm.deblocage.beneficiaire_id,"supportId":_vm.deblocage.support_id,"dateOperation":_vm.deblocage.date_mouvement,"tauxRemu":_vm.deblocage.taux_de_remuneration_interets_courus,"aTerme":true,"isEditable":_vm.isEditable,"height":datatableSize}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"montant_debloque","name":"montant débloqué","rules":"required|currency|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"€","labelValue":"Montant débloqué","reverseValue":true,"errors":errors,"disabledValue":!_vm.isEditable},model:{value:(_vm.deblocage.montant),callback:function ($$v) {_vm.$set(_vm.deblocage, "montant", $$v)},expression:"deblocage.montant"}})]}}],null,true)})],1),_c('v-col',{staticClass:"ml-3 flex-grow-0"},[_c('v-tooltip',{attrs:{"bottom":"","open-on-hover":!_vm.$vuetify.breakpoint.smAndDown,"open-on-click":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","right":""}},Object.assign({}, on)),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',[_vm._v("Le montant débloqué est prélevé en priorité sur la participation placée, puis sur les intérêts les plus anciens")])])],1)],1)],1),(!_vm.isEspaceBeneficiaire && _vm.isEditable)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"montant_deblocage_net","name":"Montant net du déblocage","rules":"required|currency|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"€","labelValue":"Montant net du déblocage","reverseValue":true,"disabledValue":true},model:{value:(_vm.montantDeblocageNet),callback:function ($$v) {_vm.montantDeblocageNet=$$v},expression:"montantDeblocageNet"}})]}}],null,true)})],1),_c('v-col',{staticClass:"ml-3 flex-grow-0"},[_c('v-tooltip-table-taxe',{attrs:{"detail":_vm.detail}})],1)],1)],1):_vm._e()],1),(!_vm.isEspaceBeneficiaire && _vm.isEditable)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"3","xxl":"2"}},[_c('v-row',{attrs:{"dense":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.canComputeMontantNet,"open-on-hover":!_vm.$vuetify.breakpoint.smAndDown,"open-on-click":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canComputeMontantNet,"block":""},on:{"click":_vm.computeCurrentMontantNet}},[_vm._v(" Générer le montant net ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.textMissingDataMontantNet))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"text":"","color":"primary","block":""},on:{"click":_vm.solderPortefeuille}},[_vm._v(" Solder le portefeuille ")])],1)],1)],1)],1):_vm._e()],1)]}}],null,true)},[_c('template',{slot:"header"},[_c('h1',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.title))])]),_c('template',{slot:"footer"},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset,"errorsList":errorsForm,"disabledValue":!_vm.isEditable},on:{"emit-cancel":_vm.onBackClick,"emit-valid-save":_vm.submit}})],1)],1)],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }