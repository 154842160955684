<!-- VUETIFY2 - OK -->
<template>
    <base-layout datatableExcludedSizeDefault="35vh">
        <template slot="header">
            <h1>Détail de la dette</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container grid-list-md>
                <v-row>
                    <v-col cols="4">
                        <v-not-editable-text-information-display label="Origine" :message="dette.message_information"/>
                        <v-not-editable-text-information-display label="Bénéficiaire concerné"
                                                                 :message="dette.associe_name"
                        />
                        <v-not-editable-text-information-display label="Date de début de la dette"
                                                                         :message="dette.date_debut_dette|dateIsoToFrFilter"/>
                        <v-not-editable-text-information-display label="Date limite de règlement de la dette"
                                                                         :message="dette.date_limite_dette|dateIsoToFrFilter"/>
                        <v-not-editable-text-information-display label="Montant total de la dette"
                                                                         :message="dette.montant_a_regler|roundEuroFilter"/>
                        <v-not-editable-text-information-display label="Restant dû"
                                                                         :message="dette.restant_du|roundEuroFilter"/>
                    </v-col>
                    <v-col cols="8">
                        <tables-detail-dette :detail="dette.detail"
                                              :is_reliquat_conversion_part_sociale="dette.is_reliquat_conversion_part_sociale"
                                              v-if="dette.type_dette === 'dette_participation' || dette.type_dette === 'dette_participation_reliquat_part_sociale'"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table :search="search"
                                      :headers="table.headers"
                                      :items="dette.reglements"
                                      hide-default-footer
                                      :height="datatableSize"
                                      no-data-text="Aucun règlement."
                                      ref="liste_dettes"
                                      class="elevation-1"
                                      fixed-header>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-lg-left">
                                        {{ item.date_de_reglement | dateIsoToFrFilter}}
                                    </td>
                                    <td class="text-lg-right">
                                        {{ item.montant_reglement | roundEuroFilter }}
                                    </td>
                                    <td class="text-lg-left">
                                        {{ item.beneficiaire }}
                                    </td>
                                    <td class="text-lg-left">
                                        {{ item.detail_reglement }}
                                    </td>
                                    <td class="text-lg-left">
                                        {{ item.type_reglement }}
                                    </td>
                                    <td class="text-lg-left">
                                        {{ item.commentaire }}
                                    </td>
                                    <td class="text-lg-center">
                                        <v-custom-confirmation-dialog
                                            message="Êtes-vous sûr(e) de vouloir annuler ce règlement ?"
                                            :isIcon="true"
                                            :iconValue="'$vuetify.icons.delete'"
                                            @action-confirmee="onDeleteReglement(item.id)"
                                            tooltipDetailMessage="Supprimer le règlement"/>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template slot="footer">
            <v-row no-gutters>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2" class="mr-2" v-if="canDisplayDetail">
                    <v-btn dark color="primary" outlined @click="downloadDetailInterets" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Détails
                    </v-btn>
                </v-col>
                <v-spacer/>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2" class="mr-2">
                    <v-btn @click="retourEcranDettes" block>
                        Fermer
                    </v-btn>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn color="primary" @click="creerReglement" block>
                        Créer un règlement
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>

    import internalApi from "@/api/internalApi";
    import VNotEditableTextInformationDisplay from "@/components/VNotEditableTextInformationDisplay.vue"
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue"
    import loadingScreen from "@/components/LoadingScreen.vue";
    import TablesDetailDette from "@/components/dette/TablesDetailDette"
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import XLSX from "xlsx";
    import Utils from "@/utils";

    export default {
        components: {
            VNotEditableTextInformationDisplay,
            VCustomConfirmationDialog,
            TablesDetailDette,
            loadingScreen,
            BaseLayout
        },
        data() {
            return {
                reglementDetteRoute: "reglement-dette",
                retourEcranDetteRoute: "dettes",
                search: "",
                table: {
                    headers: [
                        {text: "Date", sortable: true, value: "date_de_reglement", align: "left", width: "10%"},
                        {text: "Montant", sortable: false, value: "montant_reglement", align: "right", width: "10%"},
                        {text: "Bénéficiaire", sortable: false, value: "beneficiaire", align: "left", width: "10%"},
                        {text: "Règlement", sortable: false, value: "detail_reglement", align: "left", width: "30%"},
                        {text: "Type de règlement", sortable: false, value: "type_reglement", align: "left", width: "10%"},
                        {text: "Commentaire", sortable: false, value: "commentaire", align: "center", width: "20%"},
                        {text: "Actions", sortable: false, value: "actions", align: "center", width: "10%"}
                    ]
                },
                dette: {
                    is_reliquat_conversion_part_sociale: false,
                    is_reprise: true,
                },
                isLoading: false,
                loadingMessage: undefined,
            };
        },
        mounted() {
            if (this.univers === "capital") {
                this.reglementDetteRoute = "cap-reglement-dette";
                this.retourEcranDetteRoute = "cap-dettes";
            }
            else if (this.univers === "participation") {
                this.reglementDetteRoute = "pb-reglement-dette";
                this.retourEcranDetteRoute = "pb-dettes";
            }
            else if (this.univers === "interessement") {
                this.reglementDetteRoute = "inte-reglement-dette";
                this.retourEcranDetteRoute = "inte-dettes";
            }
            this.fetchDette();
        },
        props: {
            univers: {
                type: String,
                required: true
            }
        },
        computed: {
            detteId() {
                return this.$route.params.id;
            },
            canDisplayDetail() {
                return (this.dette.type==='Déblocage à terme' || this.dette.type==='Déblocage anticipé') && !this.dette.is_reprise
            },
        },

        methods: {
            fetchDette() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                return internalApi.dettes.getById(this.detteId).then(result => {
                    this.dette = result;
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            retourEcranDettes: function () {
                this.$router.push({name: this.retourEcranDetteRoute});
            },
            creerReglement: function () {
                this.$router.push({
                    name: this.reglementDetteRoute,
                    params: {
                        id: this.detteId
                    }
                });
            },
            onDeleteReglement: function (id) {
                this.$refs.loadingComponent.setLoading("Suppression du règlement en cours");
                internalApi.reglements.deleteReglementById(id).then(result => {
                    // on rappelle le back pour maj les infos
                    this.dette.reglements = _.remove(this.dette.reglements, function (mvt) {
                        return mvt.id !== id;
                    });
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            downloadDetailInterets() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.dettes.downloadDetail(this.dette.id)
                    .then(response => {
                        Utils.downloadFile(response, "detail_dette.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
        }
    };
</script>
