<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <ValidationProvider vid="localNomBanque"
                                    name="Nom de la banque"
                                    :rules="{ required: (this.checkIsNotNull(this.localBic) || this.checkIsNotNull(this.localIban))}"
                                    v-slot="{ errors }">
                    <v-text-field v-model="localNomBanque"
                                  label="Nom de la banque"
                                  :error-messages="errors"
                                  :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <ValidationProvider vid="localBic"
                                    name="BIC"
                                    :rules="{ required: (this.checkIsNotNull(this.localIban) || this.checkIsNotNull(this.localNomBanque))}"
                                    v-slot="{ errors }">
                    <v-text-field v-model="localBic"
                                  label="BIC"
                                  :error-messages="errors"
                                  :disabled="disabled"
                    />
                </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <ValidationProvider vid="localIban"
                                    name="IBAN"
                                    :rules="{ required: (this.checkIsNotNull(this.localBic) || this.checkIsNotNull(this.localNomBanque))}"
                                    v-slot="{ errors }">
                    <v-text-field-masked  v-model="localIban"
                                  label="IBAN"
                                  :error-messages="errors"
                                  :disabled="disabled"
                                  mask="#### #### #### #### #### #### ###"/>
                </ValidationProvider>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import _ from "lodash";
    import VTextFieldMasked from "@/components/VTextFieldMasked";

    /**
     * Composant affichant les informations bancaires d'une personne physique
     * @displayName Commun - Informations bancaires
     */
    export default {
        name: "v-informations-bancaire",
        components: {
            VTextFieldMasked
        },
        props: {
            /**
             * Nom de la banque
             */
            nom_banque: {
                type: String
            },
            /**
             * Numéro BIC
             */
            bic: {
                type: String
            },
            /**
             * IBAN
             */
            iban: {
                type: String
            },
            disabled: {
                type: Boolean,
                required: false,
            }
        },
        methods: {
            /**
             * Méthode pour empecher une erreur de validation à l'ouverture de la page
             * En effet comme on passe par une valeur computed, vee-validate considère que le champ à été modifié
             * et passe donc la validation. Si le champ est null ou undefined, on le force à ""
             * @param initialValue
             * @return {string}
             */
            getReturnValueIfNull(initialValue) {
                let returnValue = "";
                if (!_.isNil(initialValue)) {
                    returnValue = initialValue;
                }
                return returnValue;
            },
            /**
             * Vérifie qu'aucune des champs bic, iban et nombanque n'est vide
             * @returns {boolean}
             */
            infosBancairesFull() {
                return this.checkIsNotNull(this.localBic)
                    && this.checkIsNotNull(this.localIban)
                    && this.checkIsNotNull(this.localNomBanque);
            },
            /**
             * Renforce la vérifie de isNil : vérifie également que la valeur n'est pas la
             * string vide
             * @param valueToCheck
             * @returns {boolean}
             */
            checkIsNotNull: function (valueToCheck) {
                return !_.isNil(valueToCheck) && valueToCheck !== "";
            },
        },
        computed: {
            localNomBanque: {
                get: function () {
                    return this.getReturnValueIfNull(this.nom_banque);
                },
                set: function (newvalue) {
                    this.$emit("update-nom-banque", newvalue);
                }
            },
            localBic: {
                get: function () {
                    return this.getReturnValueIfNull(this.bic);
                },
                set: function (newvalue) {
                    this.$emit("update-bic", newvalue);
                }
            },
            localIban: {
                get: function () {
                    return this.getReturnValueIfNull(this.iban);
                },
                set: function (newvalue) {
                    this.$emit("update-iban", newvalue);
                }
            },
        }
    };
</script>