<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="espacebeneficiaire" :withFooter="false" :headerSize="$vuetify.breakpoint.smAndDown ? '48px' : '60px'">
        <template v-slot:header>
            <v-tabs 
                v-model="currentTab"
                scrollable
                show-arrows
                mobile-breakpoint="960"
            >
                <v-tab key="graphiques">Portefeuille bénéficiaire</v-tab>
                <v-tab key="operations">Supports de participation</v-tab>
                <v-tab key="repartition">Participation aux bénéfices</v-tab>
                <v-tab key="deblocages">Déblocages</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab" touchless>
                <v-tab-item key="graphiques">
                    <portefeuille-espace-beneficiaire ref="portefeuilleBeneficiaire"/>
                </v-tab-item>
                <v-tab-item key="operations">
                    <operations-espace-beneficiaire ref="operations"/>
                </v-tab-item>
                <v-tab-item key="repartition">
                    <repartition-pb-espace-beneficiaire ref="repartition"/>
                </v-tab-item>
                <v-tab-item key="deblocages">
                    <deblocages-espace-beneficiaire ref="deblocages"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>

    import DeblocagesEspaceBeneficiaire from "@/views/participation/espaceBeneficiaire/DeblocagesEspaceBeneficiaire";
    import OperationsEspaceBeneficiaire from "@/views/participation/espaceBeneficiaire/OperationsEspaceBeneficiaire";
    import PortefeuilleEspaceBeneficiaire from "@/views/participation/espaceBeneficiaire/PortefeuilleEspaceBeneficiaire"
    import RepartitionPbEspaceBeneficiaire from "@/views/participation/espaceBeneficiaire/RepartitionPbEspaceBeneficiaire";
    import authService from "@/services/authService";
    import {TOAST} from "@/plugins/toast/definition";
    import internalApi from "@/api/internalApi";
    import BaseLayout from "@/components/BaseLayout";

    export default {
        components: {
            BaseLayout,
            DeblocagesEspaceBeneficiaire,
            OperationsEspaceBeneficiaire,
            PortefeuilleEspaceBeneficiaire,
            RepartitionPbEspaceBeneficiaire,
        },
        data() {
            return {
                currentTab: undefined,
            };
        },
        computed: {
            beneficiaireId: function () {
                return parseInt(authService.getIdBeneficiaire());
            },
        },
        mounted() {
            try {
                this.currentTab = parseInt(this.$route.params.activetab);
            } catch (err) {
                console.error("Erreur de parsing d'int sur l'espace participation");
            }
        },
        methods: {

        }
    };
</script>
