<!-- TODO : Modifier pour ne laisser que la saisie des forfaits sociaux -->
<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" dark block>
                Créer un taux de fiscalité
            </v-btn>
        </template>
        <v-card>
            <ValidationObserver v-slot="{ handleSubmit }" v-if="dialog === true">
                <v-card-title class="headline grey lighten-2" primary-title>Créer un taux de fiscalité</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <ValidationProvider name="Date d'entrée en vigueur" vid="date_debut"
                                                v-slot="{ errors }"
                                                :rules="{ required: true , date_after: dateDebutMin }">
                                <date-field v-model="tauxFiscalite.date_debut"
                                            label="Date d'entrée en vigueur"
                                            :errors="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <ValidationProvider name="Forfait social standard" vid="forfait_social_standard"
                                                v-slot="{ errors }"
                                                rules="required|between:0,100">
                                <v-formatted-number-text-field v-model="tauxFiscalite.forfait_social_standard"
                                                               labelValue="Forfait social standard"
                                                               prefixValue="%"
                                                               :reverseValue="true"
                                                               :errors="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="6">
                            <ValidationProvider name="Forfait social versement immédiat"
                                                vid="forfait_social_vers_immediat" v-slot="{ errors }"
                                                rules="required|between:0,100">
                                <v-formatted-number-text-field v-model="tauxFiscalite.forfait_social_vers_immediat"
                                                               labelValue="Forfait social versement immédiat"
                                                               prefixValue="%"
                                                               :reverseValue="true"
                                                               :errors="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <ValidationProvider name="Taux CSG-CRDS" vid="taux_remu" v-slot="{ errors }"
                                                rules="required|between:0,100">
                                <v-formatted-number-text-field v-model="tauxFiscalite.taux_csg_crds"
                                                               labelValue="Taux CSG-CRDS"
                                                               prefixValue="%"
                                                               :reverseValue="true"
                                                               :errors="errors"
                                                               :disabledValue="true"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="6">
                            <ValidationProvider name="Taux charges sociales" vid="taux_charges_sociales"
                                                v-slot="{ errors }"
                                                rules="required|between:0,100">
                                <v-formatted-number-text-field v-model="tauxFiscalite.taux_charges_sociales"
                                                               labelValue="Taux charges sociales"
                                                               prefixValue="%"
                                                               :reverseValue="true"
                                                               :errors="errors"
                                                               :disabledValue="true"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <ValidationProvider name="Taux prélèvement forfaitaire" vid="taux_prelevement_forfaitaire"
                                                v-slot="{ errors }"
                                                rules="required|between:0,100">
                                <v-formatted-number-text-field v-model="tauxFiscalite.taux_prelevement_forfaitaire"
                                                               labelValue="Taux prélèvement forfaitaire"
                                                               prefixValue="%"
                                                               :reverseValue="true"
                                                               :errors="errors"
                                                               :disabledValue="true"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" class="ma-0" dense>
                        <!-- Bouton modal -->
                        <v-col cols="4">
                            <v-btn @click="closePopUp" block>NON, ANNULER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="4">
                            <v-btn color="primary" @click="handleSubmit(submit)" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
    import Utils from "@/utils.js";
    import internalApi from "@/api/internalApi";
    import DateField from "@/components/DateField";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";

    export default {
        name: "VNewTauxFiscalite",
        components: {
            VFormattedNumberTextField,
            DateField
        },
        data() {
            return {
                dialog: false,
                tauxFiscalite: {
                    taux_csg_crds: undefined,
                    taux_charges_sociales: undefined,
                    taux_prelevement_forfaitaire: undefined,
                    forfait_social_standard: undefined,
                    forfait_social_vers_immediat: undefined,
                    date_debut: undefined,
                }
            }
        },
        props: {
            previousTauxFiscalite: {
                type: Object,
                required: false,
                default: null,
            },
        },
        watch: {
            dialog: function () {
                if (this.dialog && !_.isNil(this.previousTauxFiscalite)) {
                    this.dateDebutMin = Utils.addToIsoDate(this.previousTauxFiscalite.date_debut, 1);
                    this.tauxFiscalite.taux_csg_crds = this.previousTauxFiscalite.taux_csg_crds;
                    this.tauxFiscalite.taux_charges_sociales = this.previousTauxFiscalite.taux_charges_sociales;
                    this.tauxFiscalite.taux_prelevement_forfaitaire = this.previousTauxFiscalite.taux_prelevement_forfaitaire;
                    this.tauxFiscalite.forfait_social_standard = this.previousTauxFiscalite.forfait_social_standard;
                    this.tauxFiscalite.forfait_social_vers_immediat = this.previousTauxFiscalite.forfait_social_vers_immediat;
                }
            }
        },
        methods: {
            closePopUp() {
                this.dialog = false;
                this.tauxFiscalite.date_debut = undefined;
                this.tauxFiscalite.taux_csg_crds = undefined;
                this.tauxFiscalite.taux_charges_sociales = undefined;
                this.tauxFiscalite.taux_prelevement_forfaitaire = undefined;
                this.tauxFiscalite.forfait_social_standard = undefined;
                this.tauxFiscalite.forfait_social_vers_immediat = undefined;
                this.tauxFiscalite.date_fin = undefined;
            },
            submit() {
                internalApi.tauxFiscalite.postAllWithToastConfirmation(this.tauxFiscalite)
                    .then(result => {
                        this.$emit("taux-fiscalite-created");
                        this.closePopUp();
                    });
            }
        },
    }
</script>