var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var reset = ref.reset;
return [_c('base-layout',{attrs:{"headerSize":"80px"}},[_c('template',{slot:"header"},[_c('h1',[_vm._v("Accord de l’intéressement")])]),_c('template',{slot:"main"},[_c('loading-screen',{ref:"loadingComponent"}),_c('form',[_c('v-container',[_c('versions-list',{attrs:{"selectedVersion":_vm.inteCompany,"versionsList":_vm.inteCompaniesEnumByName,"labelUnivers":"Version de l'accord d'intéressement"},on:{"update-selected-version":function($event){return _vm.onSelectedVersionChanges($event)}}}),_c('br'),_c('br'),_c('v-row',{attrs:{"no-gutters":""}},[_c('ValidationProvider',{attrs:{"vid":"date_accord","name":"date accord","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateField',{staticClass:"flex md4",attrs:{"label":"Date de l'accord","name":"date_accord","errors":errors},model:{value:(_vm.inteCompany.date_accord),callback:function ($$v) {_vm.$set(_vm.inteCompany, "date_accord", $$v)},expression:"inteCompany.date_accord"}})]}}],null,true)})],1),_c('br'),_c('br'),_c('v-subheader',[_c('h2',[_vm._v("Contrainte de répartition de l’intéressement")]),_c('v-col',{staticClass:"ml-3",attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',[_vm._v("Le calcul de l’ancienneté d’un exercice prend en compte deux exercices glissants")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('v-checkbox',{attrs:{"label":"Contrainte d'ancienneté","disabled":_vm.isOldInteCompany},on:{"change":_vm.switchHasContrainteAnciennete},model:{value:(_vm.hasContrainteAnciennete),callback:function ($$v) {_vm.hasContrainteAnciennete=$$v},expression:"hasContrainteAnciennete"}})],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('ValidationProvider',{attrs:{"vid":"jours_anciennete","name":"jours anciennete","rules":{ required: _vm.hasContrainteAnciennete, between: {'min': 0, 'max': 90} }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"labelValue":"Jours d'ancienneté","errors":errors,"disabledValue":!_vm.hasContrainteAnciennete || _vm.isOldInteCompany},model:{value:(_vm.inteCompany.elig_anciennete),callback:function ($$v) {_vm.$set(_vm.inteCompany, "elig_anciennete", $$v)},expression:"inteCompany.elig_anciennete"}})]}}],null,true)})],1)],1),_c('br'),_c('br'),_c('v-subheader',[_c('h2',[_vm._v("Règles de répartition de l’intéressement aux bénéfices")])]),_c('v-row',{attrs:{"no-gutters":""}},[_c('h3',{staticClass:"front-weigth-medium"},[_vm._v(" Total : "+_vm._s(_vm._f("roundPercentFilter")(_vm.totalCles)))])]),_c('br'),_c('v-expansion-panels',{attrs:{"multiple":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":'$vuetify.icons.chevronDown'}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('h3',[_vm._v("Répartition uniformisée")]),_c('v-col',{staticClass:"ml-3",attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',[_vm._v("L'intéressement est réparti de manière uniforme entre chaque bénéficiaire ayant travaillé au moins un jour sur l'exercice.")])])],1)],1)],1),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('ValidationProvider',{attrs:{"vid":"clef_repart_uniforme","name":"clef repart uniforme","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"%","reverseValue":true,"labelValue":"Clef de répartition","errors":errors,"disabledValue":_vm.isOldInteCompany},model:{value:(_vm.inteCompany.part_repartition_uniforme),callback:function ($$v) {_vm.$set(_vm.inteCompany, "part_repartition_uniforme", $$v)},expression:"inteCompany.part_repartition_uniforme"}})]}}],null,true)})],1)],1)],1)],1),_c('clef-repartition-valorisee-interessement',{attrs:{"type":'salaire',"title":_vm.titleSalaire,"inteCompanyId":_vm.inteCompany.id,"isOldInteCompany":_vm.isOldInteCompany,"partRepartitionValue":_vm.inteCompany.part_repartition_selon_salaire},on:{"update-part-repartie-value":function($event){_vm.inteCompany.part_repartition_selon_salaire = $event}}}),_c('clef-repartition-valorisee-interessement',{attrs:{"type":'presence',"title":_vm.titlePresence,"inteCompanyId":_vm.inteCompany.id,"isOldInteCompany":_vm.isOldInteCompany,"partRepartitionValue":_vm.inteCompany.part_repartition_selon_presence},on:{"update-part-repartie-value":function($event){_vm.inteCompany.part_repartition_selon_presence = $event}}})],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[(_vm.isResponsable())?_c('new-accord-inte',{attrs:{"dialog":_vm.dialog,"versionsList":_vm.inteCompaniesEnumByName,"nouvelleVersion":_vm.inteCompany,"disabledValue":_vm.isOldInteCompany},on:{"on-open-dialog":function($event){return _vm.onOpenDialog($event)},"on-version-created":function($event){return _vm.updateInteComapny()}}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"6","lg":"6","xl":"5"}},[_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset,"errorsList":errors,"disabledValue":_vm.isOldInteCompany},on:{"emit-cancel":function($event){return _vm.undo()},"emit-valid-save":function($event){return _vm.saveAccord()}}})],1)],1)],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }