<!-- VUETIFY2 - OK -->
<template>
    <v-row style="margin-top: 4%;" justify="space-around" no-gutters>
        <v-col cols="11" sm="10" md="6" lg="4" xl="3" xxl="2">
            <v-card :class="$vuetify.breakpoint.xsOnly ? 'elevation-1' : 'elevation-10'">
                <v-toolbar dark color="primary">
                    <v-row no-gutters align="end">
                        <v-col cols="7" sm="4" md="7" lg="6">
                            <logo-svg-complet-blanc width="230px"/>
                        </v-col>
                        <v-col cols="5" sm="8" md="5" lg="6" class="text-end">
                            <span style="font-size:0.9em;">Version {{ appVersion }}</span>
                        </v-col>
                    </v-row>
                </v-toolbar> 
                <v-form @submit="connexion">
                    <v-card-text>
                            <v-text-field name="login"
                                        prepend-icon="person"
                                        label="Identifiant"
                                        v-model="username"
                            />
                            <v-password-visualiser
                                prepend-icon="lock"
                                label="Mot de passe"
                                v-model="password"
                                />
                        <v-forgot-password/>
                    </v-card-text>
                    <v-card-actions>
                        <v-row justify="end">
                            <!-- Grand bouton -->
                            <v-col cols="12" sm="4">
                                <v-btn type="submit" color="primary" block>Connexion</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    import authService from '@/services/authService.js';
    import LogoSvgCompletBlanc from "@/components/assets/LogoSvgCompletBlanc.vue";
    import VForgotPassword from "@/components/VForgotPassword";
    import VPasswordVisualiser from '@/components/VPasswordVisualiser';

    export default {
        components: {
            LogoSvgCompletBlanc,
            VForgotPassword,
            VPasswordVisualiser,
        },
        data() {
            return {
                password: "",
                username: "",
                showPassword: false,
                appVersion: process.env.VUE_APP_VERSION,
            };
        },
        methods: {
            connexion: function (e) {
                e.preventDefault();
                authService.login(this.username, this.password, this.$route.params.tenant);
            },
        }
    };
</script>
