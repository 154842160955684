import Vue from "vue";
import Utils from "@/utils";
import _ from "lodash";


/**
 * Renvoie l'input en string arrondi à 2 chiffres après la virgule, même .00, avec le symbole € à la fin
 * Renvoie "-" si l'input n'est pas défini
 */
Vue.filter("roundEuroFilter", Utils.roundEuroFilter);

/**
 * Renvoie l'input en string arrondi à X chiffres après la virgule, paramétrable via l'attribut digits. 2 par défaut
 * Renvoie "-" si l'input n'est pas défini
 */
Vue.filter("roundCurrencyFilter", function roundCurrencyFilter(valueToFormat, digits = 2) {
  let formatedValue = "-";
  if (!_.isNil(valueToFormat)) {
    formatedValue = Utils.formatInput(valueToFormat, Utils.formatCurrency(digits));
    formatedValue = Utils.remplacerPointParVirgule(formatedValue);
    formatedValue = Utils.separerMilierParEspace(formatedValue);
  }
  return formatedValue;
});


/**
 * Renvoie l'input en string arrondi à X chiffres après la virgule, paramétrable via l'attribut digits. 2 par défaut
 * Renvoie "" si l'input n'est pas défini
 */
Vue.filter("roundEuroFilterNotDisplayZero", function roundCurrencyFilter(valueToFormat, digits = 2) {
  let formatedValue = "-";
  if (!_.isNil(valueToFormat) && valueToFormat !== "0" && valueToFormat !== 0) {
    formatedValue = Utils.formatInput(valueToFormat, Utils.formatCurrency(digits), "€");
    formatedValue = Utils.remplacerPointParVirgule(formatedValue);
    formatedValue = Utils.separerMilierParEspace(formatedValue);
  }
  return formatedValue;
});

/**
 * Renvoie l'input en string arrondi à 2 chiffres après la virgule si différent de .00, l'entier sinon.
 * Ajoute le symbole % à la fin
 * Renvoie "" si l'input n'est pas défini
 */
Vue.filter("roundPercentFilter", function roundPercentFilter(valueToFormat, decimals_number=2, force_decimals=false) {
  const formatedInputAsString = _.toString(Utils.formatNumber(valueToFormat, decimals_number, force_decimals));
  return Utils.formatInput(formatedInputAsString, Utils.remplacerPointParVirgule, "%");
});

/**
 * Renvoie l'input en string arrondi à 2 chiffres après la virgule si différent de .00, l'entier sinon.
 * Renvoie "" si l'input n'est pas défini
 */
Vue.filter("roundNumberFilter", function roundNumberFilter(valueToFormat) {
  let valueToReturn = "-";
  if (!_.isNil(valueToFormat)) {
    valueToReturn = Utils.formatInput(valueToFormat, Utils.formatNumber);
    valueToReturn = Utils.remplacerPointParVirgule(valueToReturn);
    valueToReturn = Utils.separerMilierParEspace(valueToReturn);
  }
  return valueToReturn;
});

/**
 * Change une date au format ISO8610 (YYYY-MM-DD) en date au format française (DD/MM/YYYY)
 * @param (String) la date au format ISO8610 (YYYY-MM-DD)
 * @return (String) la date au format français (DD/MM/YYYY)
 */
Vue.filter("dateIsoToFrFilter", function dateIsoToFrFilter(dateToFormat) {
  return Utils.formatInput(dateToFormat, Utils.formatDateIsoToFr);
});



/**
 * Change une date au format ISO8610 (YYYY-MM-DD) en date au format française (DD/MM/YYYY)
 * @param (String) la date au format ISO8610 (YYYY-MM-DD)
 * @return (String) la date au format français (DD/MM/YYYY)
 */
Vue.filter("dateIsoToFrFilterWithHourMin", function dateIsoToFrFilterWithHourMin(dateToFormat) {
  return Utils.formatInput(dateToFormat, Utils.dateIsoToFrFilterWithHourMin);
});

/**
 * Change une date au format français (DD/MM/YYYY) en date au format ISO8610 (YYYY-MM-DD)
 * @param (String) la date au format français (DD/MM/YYYY)
 * @return (String) la date au format ISO8610 (YYYY-MM-DD)
 */
Vue.filter("dateFrToIsoFilter", function dateFrToIsoFilter(dateToFormat) {
  return Utils.formatInput(dateToFormat, Utils.formatDateFrToIso);
});

/**
 * Extract the year of a date in any format
 * @param {String} date in any format
 * @return {String} The year of the date
 */
Vue.filter("onlyYearFilter", function dateIsoToFrFilter(dateToFormat) {
  return Utils.formatInput(dateToFormat, Utils.formatYearFromDate);
});


Vue.filter("currencyDisplayFilter", function dateFrToIsoFilter(dateToFormat) {
  return Number(dateToFormat).toLocaleString("Fr-fr");
});


/**
 * Limite l'affihage d'un string à x caractères
 * @param {String} stringToCut Le string à couper si nécessaire
 * @param {Number} size La taille max du string
 * @return {String} Le string coupé avec une ellipsis (...)
 */
 Vue.filter("limitStr", function limitStr(stringToCut, size) {
  if (_.isNil(stringToCut)){
    return '';
  } 
  stringToCut = stringToCut.toString();

  if (stringToCut.length <= size) {
    return stringToCut;
  }
  return stringToCut.substr(0, size) + '...';
});


Vue.filter("toFrenchBool", function toFrenchBool(boolToTranslate) {
  if (boolToTranslate === true){
    return "Oui";
  }
  if (boolToTranslate === false){
    return "Non";
  }
  return boolToTranslate;
});


/**
 * Capitalizes the first letter of the provided string.
 *  @param {String} valueToCapitalize
 *  @returns {String} The string with the first letter capitalized.
 */
Vue.filter("capitalizeFirstLetterFilter", function capitalizeFirstLetterFilter(valueToFormat) {
  return Utils.capitalizeFirstLetter(valueToFormat);
});