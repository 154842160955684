<!-- VUETIFY2 - OK -->
<template>
    <v-row v-if="isActive" justify="center">
        <v-dialog v-model="value" persistent content content-class="centered-dialog">
            <v-container fill-height>
                <v-row justify="center" align="center">
                    <v-progress-circular indeterminate size="50"/>
                </v-row>
                <v-row class="text-center" justify="center" align="center">
                    <span class="subheading font-weight-medium">Veuillez patienter</span>
                </v-row>
                <v-row v-if="loadingMessage" class="text-center" justify="center" align="center">
                    <span class="body-1">{{loadingMessage}}</span>
                </v-row>
            </v-container>
        </v-dialog>
    </v-row>
</template>

<script>
    /**
     * Composant affichant la roue de chargement.
     * @displayName Technique - Loading screen
     */
    export default {
        data() {
            return {
                /**
                 * La roue est visible
                 */
                isActive: false,
                /**
                 * Le message de chargement
                 */
                loadingMessage: {
                    default: undefined,
                    type: String
                },
                value: true
            };
        },
        methods: {
            /**
             * Affiche la roue et bloque les écrans
             *
             * @param {string} message: le message à afficher
             * @public
             */
            setLoading(message) {
                this.loadingMessage = message;
                this.isActive = true;
            },
            /**
             * Masque la roue et redonne la main sur les écrans
             *
             * @public
             */
            resetLoading() {
                this.isActive = false;
                this.loadingMessage = undefined;
            },
        }
    };
</script>

<style scoped>
    .dialog.centered-dialog,
    .v-dialog.centered-dialog {
        background: #282c2dad;
        box-shadow: none;
        border-radius: 6px;
        width: auto;
        color: whitesmoke;
    }
</style>