<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <loading-screen ref="loadingComponent"/>
        <base-layout headerSize="50px" datatableExcludedSizeDefault="275px">
            <template slot="header">
                <h1>Attribution de l'abondement</h1>
            </template>
            <template v-slot:main="{ datatableSize }">
                <v-container fluid>
                    <v-row justify="space-between">
                        <v-col md="5" lg="5" xl="2">
                            <v-text-field v-model="exercice.libelle" :disabled="true" label="Exercice"/>
                        </v-col>
                        <v-col md="5" lg="5" xl="2">
                            <v-formatted-number-text-field
                                v-model="tauxFiscalite.taux_csg_crds"
                                prefixValue="%"
                                :reverseValue="true" :disabledValue="true"
                                labelValue="Taux de CSG - CRDS"/>
                        </v-col>
                    </v-row>
                    <v-row class="mb-0">
                        <v-col>
                            <v-text-field v-model="searchAssocieByName"
                                          label="Recherche d'associé(e)"
                                          hint="Nom, prénom, raison sociale ou numéro de tiers"
                                          append-icon="search"
                            />
                        </v-col>
                        <v-col>
                            <v-select v-model="searchAssocieByEligibilite"
                                      :items="searchAssocieByEligibiliteOptions"
                                      label="Eligibilité des associé(e)s"
                            />
                        </v-col>
                        <v-col>
                            <v-switch v-if="!exercice.abo_exercice.is_attribution_valide"
                                        v-model="searchAssocieShowIncompletsOnly"
                                        label="Afficher uniquement les attributions non-complétées"
                            />
                        </v-col>
                    </v-row>
                    <datatable-abo-attribution :exercice="exercice"
                                               :searchAssocieByName="searchAssocieByName"
                                               :searchAssocieByEligibilite="searchAssocieByEligibilite"
                                               :searchAssocieShowIncompletsOnly="searchAssocieShowIncompletsOnly"
                                               :dataChanged="dataChanged"
                                               :attributions="attributions"
                                               :progressBarTotal="progressBarTotal"
                                               :progressBarCompleted="progressBarCompleted"
                                               @update-data-changed="dataChanged = $event"
                                               @update-attributions="attributions = $event"
                                               @update-progress-bar-total="progressBarTotal = $event"
                                               @update-progress-bar-completed="progressBarCompleted = $event"
                                               :height="datatableSize"
                                               ref="datatableAboAttribution"
                    />
                </v-container>
            </template>
            <template slot="footer">
                <v-row dense>

                    <!-- Footer gauche -->
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" v-if="isParametageAboExerciceOk">
                        <v-btn dark color="primary" outlined @click="downloadExcel" block>
                            <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                            Télécharger
                        </v-btn>
                    </v-col>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" v-if="exercice.abo_exercice.is_attribution_valide">
                        <v-popup-asynchrone
                            documentsNamePlural="bulletins de souscription"
                            :isDocumentGenderFeminine="false"
                            documentPdfName="bulletins_de_souscription_abondement"
                            :getStateDocuments="getStateBulletinsSouscriptionAbondement"
                            :generateDocuments="generateBulletinsSouscriptionAbondement"
                            :downloadDocuments="downloadBulletinsSouscriptionAbondement"
                        />
                    </v-col>

                    <v-spacer/>

                    <!-- Footer droite -->
                    <template v-if="exercice.abo_exercice.is_attribution_valide || ! isParametageAboExerciceOk">
                        <!-- Répartition validée -->
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2">
                            <v-btn :to="{name: 'cap-exercices'}" block>Retour</v-btn>
                        </v-col>
                    </template>
                    <template v-else>
                        <!-- Répartition non-validée -->
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2">
                            <v-btn :to="{name: 'cap-exercices'}" block>Annuler</v-btn>
                        </v-col>
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2" class="px-1">
                            <v-btn block color="secondary" @click="saveAboAttribution(validate, errors)">
                                <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                                Enregistrer
                            </v-btn>
                        </v-col>
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2">
                            <v-custom-confirmation-dialog :message="confirmationMessage"
                                                          openButtonMessage="Valider"
                                                          title="Valider l'attribution de l'abondement"
                                                          :isIcon="false"
                                                          @action-confirmee="validateAboAttribution(validate, errors)"
                                                          @open-popup-click="openValidatePopUp()"
                                                          @cancel-popup-click="needsPopUpValidation=false;"
                                                          :externalValidateForm="validate"
                                                          :externalErrorsList="errors">
                                <template slot="custom-infos">
                                    <div v-if="needsPopUpValidation">
                                        <p v-if="dataChanged" style="color: orange; text-align:justify">
                                            > Données non actualisées, enregistrez pour avoir les montants corrects
                                        </p>
                                        <p>
                                            Financement des associé(e)s&nbsp;: {{ totalAbondementPartAssocie | roundEuroFilter }}
                                            <br/>
                                            Financement de la société&nbsp;: {{ totalAbondementPartSociete | roundEuroFilter }}
                                            <br/>
                                            Capital souscrit&nbsp;: {{ totalAbondementCapitalSouscrit | roundEuroFilter }}
                                        </p>
                                        <div>
                                            Veuillez sélectionner la catégorie de capital et la date de souscription
                                            qui feront l'objet des soucriptions d'abondement.
                                        </div>
                                        <v-row>
                                            <v-col cols="6">
                                                <ValidationProvider vid="selected_category_id"
                                                                    name="Catégorie de capital"
                                                                    rules="required"
                                                                    v-slot="{ errors }">
                                                    <v-categories-capital-list v-model="selected_category_id"
                                                                               :errors="errors"/>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="6">
                                                <ValidationProvider vid="date_souscription" name="Date de souscription"
                                                                    rules="required"
                                                                    v-slot="{ errors }">
                                                    <DateField v-model="date_souscription"
                                                               ref="date_souscription"
                                                               label="Date de souscription"
                                                               :errors="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </v-custom-confirmation-dialog>
                        </v-col>
                    </template>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import DatatableAboAttribution from "@/components/abondement/DatatableAboAttribution.vue"
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VCategoriesCapitalList from "@/components/capital/VCategoriesCapitalList";
    import DateField from "@/components/DateField";
    import VPopupAsynchrone from "@/components/VPopupAsynchrone.vue";
    import {TOAST} from "@/plugins/toast/definition";
    import TOAST_MESSAGE from "@/plugins/toast/messages";
    import Utils from "@/utils.js";

    export default {
        components: {
            BaseLayout,
            DatatableAboAttribution,
            loadingScreen,
            VCustomConfirmationDialog,
            VFormattedNumberTextField,
            VCategoriesCapitalList,
            DateField,
            VPopupAsynchrone,
        },
        data() {
            return {
                searchAssocieByEligibiliteOptions: [
                    {"value": "all", "text": "Tous"},
                    {"value": "true", "text": "Associé(e)s éligibles"},
                    {"value": "false", "text": "Associé(e)s non-éligibles"},
                ],
                searchAssocieByName: null,
                searchAssocieByEligibilite: "true",
                searchAssocieShowIncompletsOnly: false,
                isLoading: false,
                dataChanged: false,
                progressBarTotal: 0,
                progressBarCompleted: 0,
                exercice: {
                    id: undefined,
                    libelle: undefined,
                    abo_exercice: {
                        is_attribution_valide: false,
                        is_parametrage_complete: false,
                        date_fin_campagne: undefined,
                    },
                },
                tauxFiscalite: {
                    taux_csg_crds: undefined,
                },
                attributions: [],
                confirmationMessage: "Une fois l'attribution de l'abondement validée, les montants ne seront plus modifiables." +
                    "<br/>Confirmez-vous cette opération ?",
                totalAbondementPartAssocie: undefined,
                totalAbondementPartSociete: undefined,
                totalAbondementCapitalSouscrit: undefined,
                needsPopUpValidation: false,
                date_souscription: undefined,
                selected_category_id: undefined,
                
            };
        },
        mounted() {
            const delayInMillis = 250;
            this.debouncedDatatableGetAllAttributionsByAssocie = _.debounce(this.$refs.datatableAboAttribution.getAllAttributionsByAssocie, delayInMillis);

            const id = this.$route.params.exerciceId;
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            this.getAboExerciceById(id).then(() => {
                if (this.isParametageAboExerciceOk) {
                    this.getTauxFiscaliteByDate(this.exercice.abo_exercice.date_fin_campagne).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                } else {
                    this.$refs.loadingComponent.resetLoading();
                    TOAST.warning(
                        "Paramétrage de l'exercice incomplet",
                        "Les données \"Abondement\" du paramétrage de l'exercice doivent être complétées pour afficher cet écran.",
                    );
                }
            }).catch(error => {
                this.$refs.loadingComponent.resetLoading();
                console.error(error);
            });
        },
        computed: {
            isParametageAboExerciceOk() {
                return (
                    !_.isNil(this.exercice)
                    && !_.isNil(this.exercice.abo_exercice)
                    && this.exercice.abo_exercice.is_parametrage_complete
                );
            }
        },
        watch: {
            searchAssocieByName() {
                this.debouncedDatatableGetAllAttributionsByAssocie();
            },
            searchAssocieByEligibilite() {
                this.debouncedDatatableGetAllAttributionsByAssocie();
            },
            searchAssocieShowIncompletsOnly() {
                this.debouncedDatatableGetAllAttributionsByAssocie();
            },
        },
        methods: {
            /**
             * Display errors from the table
             */
            showErrorToast(raw_errors) {
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            },
            getAboExerciceById(aboExerciceId) {
                return internalApi.aboExercice.getById(aboExerciceId)
                    .then(result => {
                        this.exercice = result;
                    });
            },
            getTauxFiscaliteByDate(tauxFiscaliteDate) {
                return internalApi.tauxFiscalite.getTauxFiscaliteByDate(tauxFiscaliteDate)
                    .then(result => {
                        this.tauxFiscalite = result;
                    });
            },
            saveAboAttribution(validate, errors) {
                validate().then(success => {
                    if (success) {
                        this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                        // Cloner et retirer les infos inutiles de this.attributions
                        const attributions = _.cloneDeep(this.attributions);
                        for (let i=0; i<attributions.length; i++) {
                            delete attributions[i].montant_part_associe;
                            delete attributions[i].montant_part_abondee;
                            delete attributions[i].montant_csg_crds;
                            delete attributions[i].montant_capital_total;
                        }
                        return internalApi.aboAttribution.saveAboAttribution(this.exercice.abo_exercice.id, attributions)
                            .then(result => {
                                this.progressBarTotal = result.progress_bar_total;
                                this.progressBarCompleted = result.progress_bar_completed;
                                this.dataChanged = false;
                                // Si la sauvegarde a été effectuée alors que le filtre sur les associés ayant des
                                // souscriptions incomplètes est actif -> Recharger explicitement les données car
                                // la liste des items affichés pourrait changer
                                if (this.searchAssocieShowIncompletsOnly) {
                                    this.$refs.datatableAboAttribution.getAllAttributionsByAssocie();
                                }
                            })
                            .finally(() => {
                                this.$refs.loadingComponent.resetLoading();
                            });
                    } else {
                        this.showErrorToast(errors);
                    }
                });
            },
            validateAboAttribution(validate, errors) {
                validate().then(success => {
                    if (success) {
                        this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                        // Cloner et retirer les infos inutiles de this.attributions
                        const attributions = _.cloneDeep(this.attributions);
                        for (let i=0; i<attributions.length; i++) {
                            delete attributions[i].montant_part_associe;
                            delete attributions[i].montant_part_abondee;
                            delete attributions[i].montant_csg_crds;
                            delete attributions[i].montant_capital_total;
                        }
                        const dataToSend = {
                            "attributions": attributions,
                            "date_souscription": this.date_souscription,
                            "category_id": this.selected_category_id,
                        }
                        return internalApi.aboAttribution.validateAboAttribution(this.exercice.abo_exercice.id, dataToSend)
                            .then(result => {
                                this.progressBarTotal = result.progress_bar_total;
                                this.progressBarCompleted = result.progress_bar_completed;
                                this.dataChanged = false;
                                this.$refs.loadingComponent.resetLoading();
                                this.$router.push({
                                    name: "cap-exercices"
                                });
                            })
                            .catch(error => {
                                console.error(error);
                                this.$refs.loadingComponent.resetLoading();
                            });
                    } else {
                        this.showErrorToast(errors);
                    }
                });
            },
            openValidatePopUp(){
                this.needsPopUpValidation = true;
                this.getTotalAbondement();
            },
            getTotalAbondement(){
                return internalApi.aboAttribution.getTotauxAboAttribution(this.exercice.abo_exercice.id)
                    .then(result => {
                        this.totalAbondementPartAssocie = result.total_abondement_part_associe;
                        this.totalAbondementPartSociete = result.total_abondement_part_societe;
                        this.totalAbondementCapitalSouscrit = result.total_abondement_capital_souscrit;
                    });
            },
            downloadExcel() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement)
                internalApi.aboAttribution.downloadAboAttributionExcel(this.exercice.abo_exercice.id)
                    .then(response => {
                        Utils.downloadFile(response, "attribution_de_l_abondement.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    })
            },
            getStateBulletinsSouscriptionAbondement(){
                return internalApi.aboAttribution.getStateBulletinsSouscriptionAbondement(this.exercice.abo_exercice.id);
            },
            /**
             * Lance la génération des bulletins de souscription abondement
             */
            generateBulletinsSouscriptionAbondement(){
                return internalApi.aboAttribution.generateBulletinsSouscriptionAbondement(this.exercice.abo_exercice.id);
            },
            /** Télécharge les bulletins de souscription abondement */
            downloadBulletinsSouscriptionAbondement(){
                return internalApi.aboAttribution.downloadBulletinsSouscriptionAbondement (this.exercice.abo_exercice.id);
            },
        },
    };
</script>