<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="130px" datatableExcludedSizeDefault="90px">
        <template slot="header">
            <loading-screen ref="loadingComponent"/>
            <v-row no-gutters>
                <v-col md="3" lg="3" xl="3">
                    <v-text-field v-model="search"
                                  append-icon="search"
                                  label="Nom/prénom, raison sociale ou numéro de tiers"
                                  single-line
                                  @input="onSearchChange()"/>
                </v-col>
                <v-col md="1" lg="1" xl="1">
                    <v-tooltip top v-if="showCapitalData">
                        <template v-slot:activator="{ on }">
                            <v-icon small left v-on="on" class="mt-7 ml-2">{{'$vuetify.icons.info'}}</v-icon>
                        </template>
                        <span>Les personnes morales apparaissent à la fin de la liste des tiers</span>
                    </v-tooltip>
                </v-col>
                <v-col md="2" lg="2" xl="2">
                    <v-select
                        class="mx-2"
                        v-model="selectedSalarieState"
                        @change="filterTiers()"
                        label="Filtrer par statut de salarié"
                        :items="switchPersonneSalarieStates"
                    />
                </v-col>
                <v-col md="2" lg="2" xl="2" v-if="showCapitalData">
                    <v-select
                        class="mx-2"
                        v-model="selectedAssocieState"
                        @change="filterTiers()"
                        label="Filtrer par statut d'associé"
                        :items="switchPersonneAssocieStates"
                    />
                </v-col>
                <v-spacer/>
                <v-col md="4" lg="4" xl="3" align-self="center">
                    <v-row no-gutters v-if="showCapitalData">
                        <v-col align-self="center">
                            Édition de bulletins
                        </v-col>
                        <v-col cols="3" class="mr-2" align-self="center">
                            <v-import-export-file detail="Importer des bulletins en masse"
                                        :typesFile="getTypesExtensionsFiles().excel"
                                        @import-file="function(formData){onBulletinsMasseImport(formData)}"
                                        :allowedExport="showCapitalData"
                                        exportDetail="Télécharger le modèle d'import de bulletins en masse"
                                        @export-file="exportBulletinsMasse"
                                        :disabled="!showCapitalData"/>
                        </v-col>
                    </v-row>
                    <v-divider v-if="showCapitalData"/>
                    <v-row no-gutters>
                        <v-col align-self="center">
                            Tiers et périodes de travail
                        </v-col>
                        <v-col cols="3" class="mr-2" align-self="center">
                            <v-import-export-file detail="Importer des tiers et des périodes de travail"
                                        :typesFile="getTypesExtensionsFiles().excel"
                                        @import-file="importTiersPeriodesTravail"
                                        :allowedExport="true"
                                        exportDetail="Télécharger le modèle d'import des tiers et des périodes de travail"
                                        @export-file="exportModeleTiersPeriodesTravail"
                                        :showForceOptionTo="importTiersPeriodesTravailShowForceOptionTo"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main="{ datatableSize }">
            <v-container>
                <v-data-table
                    :headers="headers"
                    :items="personnesList"
                    :footer-props="footerOpts"
                    :height="datatableSize"
                    fixed-header
                    class="elevation-1"
                    :options.sync="pagination"
                    :server-items-length="total_items"
                    :loading="isLoading">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon size="35" v-on="on">{{ getIconByPersonneType(item) }}</v-icon>
                                    </template>
                                    <span>{{ getDetailByPersonneType(item) }}</span>
                                </v-tooltip>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.numero_tiers }}</td>
                            <td>
                                <v-row no-gutters justify="center" v-if="item.is_currently_salarie">
                                    <v-col md="3" lg="3" xl="3">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="{...on}">
                                                    {{"$vuetify.icons.squareCheck"}}
                                                </v-icon>
                                            </template>
                                            <span>Ce tiers est salarié(e) actif(ve) à la date du jour</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </td>
                            <td v-if="showCapitalData">
                                <v-row no-gutters justify="center">
                                    <v-col md="3" lg="3" xl="3">
                                        <icone-etat-associe
                                            :is-associe="item.is_currently_associe"
                                            :is-associe-by-dates="item.is_currently_associe_by_dates"
                                            :is-associe-by-capital="item.is_currently_associe_by_capital"
                                            :show-cross-if-not-associe="false"
                                        />
                                    </v-col>
                                </v-row>
                            </td>
                            <td class="text-lg-center">
                                <v-row no-gutters justify="center">
                                    <v-col md="4" lg="4" xl="3">
                                        <v-icon-button-with-tool-tip v-if="item.personne_type === 'per_phy'"
                                                                     :routeCible="{name: routeEditionPerPhy,params: {id: item.id}}"
                                                                     detail="Éditer les données du tiers"
                                                                     :icon="'$vuetify.icons.edit'"
                                                                     btnIsIcon btnRound btnText tooltipBottom
                                                                     btnColorClass="transparent"
                                                                     iconColorClass="grey darken-1"/>
                                        <v-icon-button-with-tool-tip v-else-if="item.personne_type === 'per_mor'"
                                                                     :routeCible="{name: routeEditionPerMor,params: {id: item.id}}"
                                                                     detail="Éditer les données du tiers"
                                                                     :icon="'$vuetify.icons.edit'"
                                                                     btnIsIcon btnRound btnText tooltipBottom
                                                                     btnColorClass="transparent"
                                                                     iconColorClass="grey darken-1"/>
                                    </v-col>
                                    <v-col md="4" lg="4" xl="3">
                                        <v-contextual-menu-tiers-documents  :personneNameAndNumeroTiers="item.name + ' (' + item.numero_tiers + ')'"
                                                                            :associeId="item.associe !==null ? item.associe.id : null"
                                                                            :beneficiaireId="item.beneficiaire ? item.beneficiaire.id: null"
                                                                            :showCapitalData="showCapitalData"
                                                                            :showParticipationData="showParticipationData"
                                                                            @on-download-etat-du-capital="downloadEtatDuCapital($event)"
                                                                            @on-download-etat-de-la-participation="downloadEtatDeLaParticipation($event)"/>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-container>
        </template>

        <template slot="footer">
        <!-- Espace entre les boutons -->
            <v-row dense>
                <!-- Petit bouton 'télécharger excel' -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn dark color="primary" outlined @click="exportTiersPeriodesTravail" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger
                    </v-btn>
                </v-col>
                <v-spacer/>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn color="primary" :to="{name: routeCreationPerPhy}" block>
                        <v-icon left>{{ "$vuetify.icons.per_phy" }}</v-icon>
                        Créer une personne physique
                    </v-btn>
                </v-col>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3" v-if="showCapitalData">
                    <v-btn color="primary" :to="{name: routeCreationPerMor}" block>
                        <v-icon left>{{ "$vuetify.icons.per_mor" }}</v-icon>
                        Créer une personne morale
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import BaseLayout from "@/components/BaseLayout";
    import _ from "lodash";
    import VImportExportFile from "@/components/VImportExportFile";
    import VContextualMenuTiersDocuments from "@/components/tiers/VContextualMenuTiersDocuments.vue"
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";
    import rolesList from "@/router/rolesList.js"
    import IconeEtatAssocie from "@/components/IconeEtatAssocie.vue";

    /**
     * Composant affichant la liste des tiers.
     * @displayName Capital - Datatable liste tiers
     */
    export default {
        components: {
            VIconButtonWithToolTip,
            BaseLayout,
            VImportExportFile,
            VContextualMenuTiersDocuments,
            loadingScreen,
            IconeEtatAssocie,
        },

        props: {
            /**
             * État du chargement des données
             */
            isLoading: {
                type: Boolean,
                required: false,
                default: false,
            },
            /**
             * Nom de la route ou mène le bouton de création d'une personne physique
             */
            routeCreationPerPhy: {
                type: String,
                required: true
            },
            /**
             * Nom de la route ou mène le bouton de création d'une personne morale
             */
            routeCreationPerMor: {
                type: String,
                required: true
            },
            /**
             * Nom de la route ou mène le bouton d'edition d'une personne physique
             */
            routeEditionPerPhy: {
                type: String,
                required: true
            },
            /**
             * Nom de la route ou mène le bouton d'edition' d'une personne morale
             */
            routeEditionPerMor: {
                type: String,
                required: true
            },
            /**
             * Nom de la route ou mène le bouton de création d'une personne morale
             */
            personnesList: {
                type: Array,
                required: true
            },
            /**
             * Affiche les éléments spécifiques à capital
             */
            showCapitalData: {
                type: Boolean,
                default: false
            },
            /**
             * Affiche les éléments spécifiques à participation
             */
            showParticipationData: {
                type: Boolean,
                default: false
            },
            /**
             * Nombre total d'éléments : utilisé pour la pagination
             */
            total_items: {
                type: Number,
                required: true,
                default: 0
            },
        },
        data() {
            return {
                headers: [],
                footerOpts: {itemsPerPageOptions: [10, 20, 50]},
                selectedAssocieState: constantes.filter.all,
                selectedSalarieState: constantes.filter.all,
                search: undefined,
                debouncedSearch: undefined,
                pagination: {
                    page: 1,
                    itemsPerPage: 10,
                },
                switchPersonneAssocieStates: [
                    {
                        text: "Tous",
                        value: constantes.filter.all,
                    },
                    {
                        text: "Associé(e)s",
                        value: constantes.filter.true,
                    },
                    {
                        text: "Non-associé(e)s",
                        value: constantes.filter.false,
                    },
                ],
                switchPersonneSalarieStates: [
                    {
                        text: "Tous",
                        value: constantes.filter.all,
                    },
                    {
                        text: "Salarié(e)s",
                        value: constantes.filter.true,
                    },
                    {
                        text: "Non-salarié(e)s",
                        value: constantes.filter.false,
                    },
                ],
                loadingMsgBulletinsMasse: "L’édition des bulletins nécessite un traitement susceptible de durer plusieurs minutes.",
                importTiersPeriodesTravailShowForceOptionTo: [rolesList.ADMINISTRATEUR],
            };
        },
        watch: {
            pagination: {
                handler() {
                    this.emitRefreshTiers();
                    this.storePaginationTiers();
                },
                deep: true
            }
        },
        mounted() {
            this.search = undefined;
            const currentFilters = this.$store.getters.getFiltresTiers();
            const currentPagination = this.$store.getters.getPaginationTiers();
            if (!_.isNil(currentFilters)) {
                if (currentFilters.selectedAssocieStateFilter) {
                    this.selectedAssocieState = currentFilters.selectedAssocieStateFilter;
                }
                if (currentFilters.selectedSalarieStateFilter) {
                    this.selectedSalarieState = currentFilters.selectedSalarieStateFilter;
                }
            }
            if (!_.isNil(currentPagination)) {
                this.pagination = currentPagination;
            }
            const delayInMillis = 1000;
            this.debouncedSearch = _.debounce(this.emitRefreshTiers, delayInMillis);
            this.emitRefreshTiers();
            this.computeHeaders();
        },
        methods: {
            /**
             * Génère les headers en fonction des univers à afficher
             */
            computeHeaders() {
                let headers = [
                    {
                        text: "",
                        value: "avatar",
                        sortable: false
                    },
                    {
                        text: "Tiers",
                        value: "name",
                        width: "30%",
                        sortable: false
                    },
                    {
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                        width: "30%",
                        sortable: false
                    },
                    {
                        text: "Salarié(e)",
                        value: "is_currently_salarie",
                        align: "center",
                        sortable: false
                    },
                ];
                if (this.showCapitalData) {
                    headers.push({
                        text: "Associé(e)",
                        value: "is_currently_associe",
                        align: "center",
                        sortable: false
                    });
                }
                headers.push({
                    text: "Actions",
                    value: "actions",
                    align: "center",
                    sortable: false
                });
                this.headers = headers;
            },
            /**
             * Retourne la constante des types d'extensions de fichiers
             */
            getTypesExtensionsFiles() {
                return constantes.extensions_fichiers;
            },
            /**
             * Affiche la page 1 de la datatable
             */
            resetPagination() {
                this.pagination.page = 1;
            },
            /**
             * Lorsque le mot recherché change, effectue une nouvelle recherche
             */
            onSearchChange() {
                this.resetPagination();
                this.debouncedSearch();
            },
            /**
             * Renvoie l'icône correspondant au type de la personne
             * @param personne
             * @returns {string}
             */
            getIconByPersonneType(personne) {
                return personne.personne_type === "per_phy" ? "fa-user" : "fa-university";
            },
            /**
             * Renvoie la description correspondante au type de la personne
             * @param personne
             * @returns {string}
             */
            getDetailByPersonneType(personne) {
                return personne.personne_type === "per_phy" ? "Personne physique" : "Personne morale";
            },
            /**
             * Met à jour le filtre du tiers à rechercher puis reset la pagination
             */
            filterTiers() {
                this.$store.commit("SET_FILTRES_TIERS", {
                    selectedAssocieStateFilter: this.selectedAssocieState,
                    selectedSalarieStateFilter: this.selectedSalarieState,
                });
                this.selected = [];
                this.resetPagination();
                this.emitRefreshTiers();
            },
            storePaginationTiers() {
                this.$store.commit(
                    "SET_PAGINATION_TIERS",
                    _.cloneDeep(this.pagination)
                );
            },
            /**
             * Emet un évènement refresh-tiers-list (rafraîchir la liste des tiers)
             */
            emitRefreshTiers() {
                this.$emit("refresh-tiers-list", this.pagination, this.search, this.selectedAssocieState, this.selectedSalarieState);
            },
            /**
             * Emet un évènement on-download-etat-du-capital (télécharger l'état du capital)
             * @param associe_id l'associé à qui télécharge l'état du capital
             */
            downloadEtatDuCapital(associe_id) {
                this.$emit("on-download-etat-du-capital", associe_id);
            },
            /**
             * Emet un évènement on-download-etat-de-la-participation (télécharger l'état de la participation)
             * @param benef_id le bénéficiaire à qui télécharge l'état de la participation
             */
            downloadEtatDeLaParticipation(benef_id) {
                this.$emit("on-download-etat-de-la-participation", benef_id);
            },
            /**
             * Export des tiers personnes physique, mmorales et périodes de travail
             */
            exportTiersPeriodesTravail() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.personne.exportTiersPeriodesTravailExcel()
                    .then(response => {
                        Utils.downloadFile(response, "export_tiers.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            exportModeleTiersPeriodesTravail() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.personne.exportModeleTiersPeriodesTravailExcel()
                    .then(response => {
                        Utils.downloadFile(response, "tiers_periodes_travail.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            importTiersPeriodesTravail(formData, options) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.importLong);
                internalApi.personne.importTiersPeriodesTravailExcel(formData, options)
                    .then(() => {
                        this.emitRefreshTiers();
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            exportBulletinsMasse() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.mouvement.exportBulletinsMasse()
                    .then(response => {
                        Utils.downloadFile(response, "bulletins_masse.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            onBulletinsMasseImport(formData) {
                this.$refs.loadingComponent.setLoading(this.loadingMsgBulletinsMasse);
                internalApi.mouvement.importBulletinsMasse(formData)
                    .then(response => {
                        Utils.downloadFile(response, "bulletins_masse.pdf", Utils.pdfMimetype);
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
        }
    };
</script>
