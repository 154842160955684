<!-- VUETIFY2 - OK -->
<template>
    <base-layout datatableExcludedSizeDefault="200px">
        <template slot="header">
            <h1>Déblocages</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <v-container fluid>
                <v-datatable-deblocages ref="deblocages_table" :height="datatableSize"/>
            </v-container>
        </template>
        <template slot="footer">
            <v-row dense>
                <!-- Petit bouton -->
                <v-col md="2" lg="2" xl="2">
                    <v-btn dark color="primary" outlined @click="downloadExcel" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger
                    </v-btn>
                </v-col>
                <v-spacer/>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn dark color="primary" :to="{name: 'deblocages-groupes'}" block>
                        Déblocages groupés
                    </v-btn>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn dark color="primary" :to="{name: 'deblocage-anticipe-creation'}" block>
                        Déblocage anticipé
                    </v-btn>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn dark color="primary" :to="{name: 'deblocage-terme-creation'}" block>
                        Déblocage à terme
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import Utils from "@/utils.js";
    import BaseLayout from "@/components/BaseLayout";
    import VDatatableDeblocages from "@/components/deblocages/VDatatableDeblocages"

    export default {
        name: "deblocages-list",
        components: {
            VDatatableDeblocages,
            BaseLayout,
        },
        methods: {
            downloadExcel: function () {
                let workbookProps = {
                    Title: "Liste des déblocages",
                    Subject: "Détail des déblocages",
                    Author: "eZIScop",
                    CreatedDate: Date.now()
                };
                let sheetTitle = "Déblocages";
                let fileTitle = "eziscop_deblocages.xlsx";
                Utils.writeXlsxFromDatatableRef(
                    this.$refs.deblocages_table.getRefListeDeblocages(),
                    workbookProps,
                    sheetTitle,
                    fileTitle
                );
            },
        }
    }
</script>