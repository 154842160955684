<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
        <base-layout headerSize="50px">
            <template v-slot:header>
                <h1 class="ml-3">{{ getTitle() }}</h1>
            </template>
            <template v-slot:main>
                <loading-screen ref="loadingComponent"/>
                <v-container fluid>
                    <v-date-associe-header :valueSelectedItem="annulationtotale.associe_id"
                                           labelDate="Date de mouvement"
                                           :valueDateMouvement="annulationtotale.date_mouvement"
                                           :isDisabled="isDisabled"
                                           :canEditDate="canEditDate"
                                           @update-selected-associe="onUpdateSelectedAssocie($event)"
                                           @update-date-mouvement="onUpdateDateMouvement($event)"
                                           :allowedDateAfterLastExercice="allowedDateAfterLastExercice"
                                           ref="date_annu"/>
                    <v-portefeuille
                        ref="portefeuille"
                        :isBrouillon="isBrouillon"
                        :valueSelectedItem="annulationtotale.associe_id"
                        :valueDateMouvement="annulationtotale.date_mouvement"
                    />
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="isBrouillon">
                            <ValidationProvider vid="date_signature_bulletin" name="Date de signature du bulletin"
                                                rules="required"
                                                v-slot="{ errors }">
                                <DateField ref="date_sign" label="Date de signature du bulletin"
                                           :errors="errors"
                                           v-model="date_signature_bulletin"/>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2" v-if="!isBrouillon">
                            <v-text-field v-model="annulationtotale.commentaire" label="Commentaire" counter="50"
                                          :disabled="isDisabled"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
                <v-row justify="end" dense>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      :showToast="false"
                                                      @emit-cancel="onBackClick"
                                                      @emit-valid-save="submit"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>

    import VDateAssocieHeader from "@/components/VDateAssocieHeader.vue";
    import VPortefeuille from "@/components/VPortefeuille.vue";
    import internalApi from "@/api/internalApi";
    import _ from "lodash";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import BaseLayout from "@/components/BaseLayout";
    import DateField from "@/components/DateField.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";

    /**
     * Composant affichant un formulaire contenant les données d'une annulation totale
     * @displayName Capital - AnnulationTotale
     */
    export default {
        components: {
            VDateAssocieHeader, VSaveButonWithValidation, BaseLayout, DateField, loadingScreen, VPortefeuille
        },
        data() {
            return {
                date_signature_bulletin: undefined,
                isDisabled: false,
                canEditDate: false,
                isButtonDisabled: true,
                specificBackRoute: undefined,
                isBrouillon: false,
                allowedDateAfterLastExercice: true,
                annulationtotale: {
                    associe_id: undefined,
                    date_mouvement: undefined,
                    commentaire: undefined,
                },
                annulationtotaleinformations: {
                    categories: [],
                },
                noDataText: undefined,
                noDataTextCases: {
                    default: "Veuillez sélectionner un(e) associé(e) et une date de mouvement.",
                    noPart: "Aucune part détenue par cet(te) associé(e).",
                },
            };
        },
        mounted() {
            this.noDataText = this.noDataTextCases.default;
        },

        computed: {},

        methods: {
            /**
             * Met à jour l'id de l'associé et les informations de la datatable le concernant
             * @param eventValue Le nouvel associé
             */
            onUpdateSelectedAssocie(eventValue) {
                this.annulationtotale.associe_id = eventValue;
                this.updateDataTable();
            },
            /**
             * Met à jour la date de mouvement de l'annulation totale et les informations de la
             * datatable liées à cette date de mouvement
             * @param eventValue La nouvelle date
             */
            onUpdateDateMouvement(eventValue) {
                this.annulationtotale.date_mouvement = eventValue;
                this.updateDataTable();
            },
            /**
             * Affiche la vue relative à la route spécifiée. Si null, retourne à la liste
             * des mouvements de parts sociales
             */
            onBackClick() {
                if (_.isNil(this.specificBackRoute)) {
                    this.pushToMouvements();
                } else {
                    this.$router.push(JSON.parse(this.specificBackRoute));
                }
            },
            /**
             * Met à jour les informations de la datatable
             */
            updateDataTable() {
                this.isButtonDisabled = (_.isNil(this.annulationtotale.associe_id) || _.isNil(this.annulationtotale.date_mouvement) || "" === this.annulationtotale.date_mouvement);
                if (!this.isButtonDisabled) {
                    internalApi.annulationtotale
                        .getByAssocieAndDate(this.annulationtotale.associe_id, this.annulationtotale.date_mouvement)
                        .then(res => {
                            this.annulationtotaleinformations = res;
                            if (this.annulationtotaleinformations.categories.length === 0) {
                                this.noDataText = this.noDataTextCases.noPart;
                            }
                        });
                } else {
                    this.annulationtotaleinformations = {
                        categories: []
                    };
                    this.noDataText = this.noDataTextCases.default;
                }
            },
            /**
             * Enregistre l'annulation totale
             */
            submit() {
                // methode gérée par les composants enfants
                this.submitForm();
            },
            /**
             * Revient à l'écran des mouvements
             */
            pushToMouvements() {
                this.$router.push({
                    name: "mouvements"
                });
            },
            /**
             * Récupère le titre du composant
             * @returns {*|string}
             */
            getTitle() {
                return this.computeTitle();
            },
            /**
             * Le titre de l'annulation totale
             * @returns {string}
             */
            computeTitle() {
                return "Annulation totale";
            },
        }
    };
</script>
