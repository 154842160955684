<!-- VUETIFY2 - FIXME - MASKS -->
<template>
    <ValidationObserver ref="formCompanyInformation" v-slot="{ errors, reset, validate }">
        <base-layout :withHeader="false">
            <template v-slot:main>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                            <!-- Informations juridiques -->
                        <v-expansion-panels inset multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                    <h2>Informations juridiques</h2>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content eager>
                                    <v-row wrap>
                                        <v-col md="12" lg="12" xl="6">
                                            <ValidationProvider vid="raison_sociale" name="Raison sociale" rules="required|max:100" v-slot="{ errors } ">
                                                <v-text-field v-model="localCompany.name" 
                                                            :error-messages="errors"
                                                            :counter="100"
                                                            label="Raison sociale"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="3" lg="3" xl="3">
                                            <ValidationProvider vid="siren" name="SIREN" rules="required|length:9" v-slot="{ errors } ">
                                                <v-text-field-masked v-model="localCompany.legal_siren" mask="### ### ###"
                                                            :error-messages="errors"
                                                            label="SIREN"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="5" lg="5" xl="3">
                                            <ValidationProvider vid="ville_rcs" name="Ville d'immatriculation au RCS" rules="required|max:40" v-slot="{ errors } ">
                                                <v-text-field v-model="localCompany.legal_rcs_town"
                                                            :error-messages="errors" :counter="40" 
                                                            label="Ville d'immatriculation au RCS"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="4" lg="4" xl="3">
                                            <ValidationProvider vid="siret" name="SIRET" rules="required|length:14" v-slot="{ errors } ">
                                                <v-text-field-masked v-model="localCompany.legal_siret" mask="### ### ### #####"
                                                            :error-messages="errors"
                                                            label="SIRET"/>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col md="3" lg="3" xl="3">
                                            <ValidationProvider vid="forme_juridique" name="Forme juridique" rules="required" v-slot="{ errors } ">
                                                <v-select v-model="localCompany.legal_form" :items="companyLabels.form"
                                                        :error-messages="errors"
                                                        label="Forme juridique"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="3" lg="3" xl="3">
                                            <ValidationProvider vid="type_scop" name="Type de SCOP" rules="required" v-slot="{ errors } ">
                                                <v-select v-model="localCompany.legal_coop_type" :items="companyLabels.coop_type"
                                                        :error-messages="errors"
                                                        label="Type de SCOP"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="2" lg="2" xl="2">
                                            <ValidationProvider vid="code_ape" name="Code APE" 
                                                                :rules="{required:true, length:5, regex:/^[\d]{4}[A-Z]$/}"
                                                                v-slot="{ errors } ">
                                                <v-text-field v-model="localCompany.legal_ape"
                                                            :error-messages="errors"
                                                            label="Code APE"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="4" lg="4" xl="3">
                                            <ValidationProvider vid="tva_intracommunautaire" name="TVA intracommunautaire"
                                                                :rules="{required: true, max:13,regex:/^[A-Z]{2}[\d]{11}$/}" v-slot="{ errors } ">
                                            <v-text-field-masked v-model="localCompany.legal_num_tva_intracom"
                                                        :error-messages="errors"
                                                        mask="## ## ### ### ###"
                                                        label="TVA intracommunautaire"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="3" lg="3" xl="3">
                                            <ValidationProvider vid="date_creation" name="Date de création" rules="required" v-slot="{ errors } ">
                                                <DateField label="Date de création"
                                                        :errors="errors"
                                                        v-model="localCompany.legal_date_creation"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="12" lg="12" xl="12">
                                            <ValidationProvider vid="Objet social" name="objet_social" rules="required|max:400" v-slot="{ errors } ">
                                                <v-textarea v-model="localCompany.legal_objet_social" rows="2"
                                                            :error-messages="errors" :counter="400"
                                                            label="Objet social"/>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <!-- Coordonnées -->
                            <v-expansion-panel>
                                <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                    <h2>Coordonnées</h2>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content eager>
                                    <v-row wrap>
                                        <v-col md="6" lg="6" xl="5">
                                            <v-text-field v-model="localCompany.nom_responsable"
                                                        label="Nom responsable"/>
                                        </v-col>
                                        <v-col md="6" lg="6" xl="5">
                                            <v-text-field v-model="localCompany.prenom_responsable"
                                                        label="Prénom responsable"/>
                                        </v-col>
                                    </v-row>
                                    <v-row wrap>
                                        <v-col md="3" lg="3" xl="2">
                                            <ValidationProvider vid="numero_telephone" name="Téléphone" :rules="{regex:/^[0]{1}[1-9]{1}[0-9]{8}$/, required: true}" v-slot="{ errors } ">
                                                <v-text-field-masked v-model="localCompany.phone"
                                                            label="Téléphone"
                                                            :error-messages="errors"
                                                            mask="## ## ## ## ##"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="6" lg="6" xl="5">
                                            <ValidationProvider vid="email_contact" name="E-mail contact" rules="required|email" v-slot="{ errors } ">
                                                <v-text-field v-model="localCompany.contact_email"
                                                            :error-messages="errors"
                                                            label="E-mail contact"/>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row wrap>
                                        <v-col md="3" lg="3" xl="2">
                                            <ValidationProvider vid="numero_fax" name="Fax" :rules="{regex:/^[0]{1}[1-9]{1}[0-9]{8}$/}" v-slot="{ errors } ">
                                                <v-text-field-masked v-model="localCompany.fax"
                                                            :error-messages="errors" label="Fax"
                                                            mask="## ## ## ## ##"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col md="6" lg="6" xl="5">
                                            <v-text-field v-model="localCompany.website"
                                                        label="Site internet"/>
                                        </v-col>
                                    </v-row>
                                <v-address :address1="localCompany.adresse.address1"
                                        :address2="localCompany.adresse.address2"
                                        :zipCode="localCompany.adresse.zip_code"
                                        :city="localCompany.adresse.city"
                                        :typeVoie="localCompany.adresse.type_voie"
                                        :numeroVoie="localCompany.adresse.numero_voie"
                                        :country="localCompany.adresse.country"
                                        @update-address-1="localCompany.adresse.address1 = $event"
                                        @update-address-2="localCompany.adresse.address2 = $event"
                                        @update-zip-code="localCompany.adresse.zip_code = $event"
                                        @update-city="localCompany.adresse.city = $event"
                                        @update-numero-voie="localCompany.adresse.numero_voie = $event"
                                        @update-type-voie="localCompany.adresse.type_voie = $event"
                                        @update-country="localCompany.adresse.country = $event">
                                </v-address>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <!-- coordonnees bancaires -->
                            <v-expansion-panel>
                                <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                                    <h2>Informations bancaires</h2>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content eager>
                                    <v-informations-bancaire :nom_banque="localCompany.infos_bancaire.nom_banque"
                                                            :bic="localCompany.infos_bancaire.bic"
                                                            :iban="localCompany.infos_bancaire.iban"
                                                            @update-nom-banque="localCompany.infos_bancaire.nom_banque = $event"
                                                            @update-bic="localCompany.infos_bancaire.bic = $event"
                                                            @update-iban="localCompany.infos_bancaire.iban = $event"
                                                            ref="infosBancaires">
                                    </v-informations-bancaire>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:footer>
                <v-row justify="end" dense>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation   :validateForm="validate"
                                                        :resetForm="reset"
                                                        :errorsList="errors"
                                                        @emit-cancel="undo()" 
                                                        @emit-valid-save="checkForUpdate()"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import DateField from "@/components/DateField.vue";
    import VAddress from "@/components/VAddress.vue";
    import VTextFieldMasked from "@/components/VTextFieldMasked";
    import VInformationsBancaire from "@/components/VInformationsBancaire.vue";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import _ from "lodash";
    import BaseLayout from "@/components/BaseLayout";
    import internalApi from "@/api/internalApi";

    /**
     * Composant affichant les informations de la société
     * @displayName Commun - CompanyInformations
     */
    export default {
        components: {
            BaseLayout,
            DateField,
            VAddress,
            VSaveButonWithValidation,
            VInformationsBancaire,
            VTextFieldMasked
        },
        // Data shared with Parent
        props: {
            company: {
                required: true
            },
        },
        data: () => ({
            valid: true,
            companyLabels: [],
        }),
        computed: {
            // Only get the value, no set because it is induced by saving the form
            localCompany() {
                return this.company;
            },
        },
        mounted() {
            this.getCompanyLabels();
        },
        methods: {
            /**
             * Obtenir les options de champs select sous forme d'énums
             * [{
             *      value: "valeur_technique",
             *      text: "Valeur d'affichage"
             *  }, ...]
             * À partir de
             * {"valeur_technique": "Valeur d'affichage", ...}
             *
             * @param object: L'objet à traiter
             */
            getSelectOptionsFromObject(object) {
                let options = [];
                for (let key in object) {
                    if (object.hasOwnProperty(key)) {
                        options.push({
                            value: key,
                            text: object[key]
                        });
                    }
                }
                return options;
            },
            getCompanyLabels() {
                return internalApi.company.getLabels()
                    .then(results => {
                        this.companyLabels = {
                            form: this.getSelectOptionsFromObject(results.company_code_label["company.legal_form"]),
                            coop_type: this.getSelectOptionsFromObject(results.company_code_label["company.type_coop"]),
                        };
                    });
            },
            checkForUpdate() {
                this.$emit("refresh-company-event");
            },
            undo() {
                this.$emit("undo-event");
            },
            getInfosCompany: function () {
                let data = _.cloneDeep(this.company);
                if (_.isEmpty(data.infos_bancaire) || !this.$refs.infosBancaires.infosBancairesFull()) {
                    data.infos_bancaire = null;
                }
                return data;
            }
        }
    };
</script>
