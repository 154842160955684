<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-data-table :headers="computeHeaders()"
                      :items="annulationtotaleinformations.categories"
                      hide-default-footer
                      :no-data-text="noDataText"
                      ref="recap_annulation_totale_dt"
                      fixed-header
                      class="elevation-1"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        {{ item.code}} - {{ item.libelle }}
                    </td>
                    <td class="text-right">
                        {{ item.nb_parts | roundNumberFilter }}
                    </td>
                    <td class="text-right" v-if="!isBrouillon">
                        <v-row>
                            <v-col md="10" lg="10" xl="10">
                                {{ item.valeur_remboursement | roundEuroFilter }}
                            </v-col>
                            <v-col md="2" lg="2" xl="2" v-if="item.detail && item.detail !== ''">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small rigth v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                    </template>
                                    <span>{{item.detail}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </td>
                    <td class="text-right" v-if="!isBrouillon">
                        {{ item.montant | roundEuroFilter }}
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-row v-if="annulationtotaleinformations.montant_total || annulationtotaleinformations.categories.length > 0">
            <v-col class="text-right">
                <span>
                    <b>Total&nbsp;: </b>{{annulationtotaleinformations.montant_total | roundEuroFilter}}
                </span>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import internalApi from "@/api/internalApi";
    import _ from "lodash";


    export default {
        name: "v-portefeuille",
        components: {},
        props: {
            /**
             * Détermine si on est dans une édition de bulletin ou non
             */
            isBrouillon: {
                type: Boolean
            },
            valueSelectedItem: {
                twoWay: true,
                type: Number
            },
            valueDateMouvement: {
                twoWay: true,
                type: String
            }
        },
        watch: {
            valueSelectedItem: {
                immediate: true,
                handler(newVal, oldVal) {
                    this.onUpdateSelectedAssocie(newVal);
                }
            },
            valueDateMouvement: {
                immediate: true,
                handler(newVal, oldVal) {
                    this.onUpdateDateMouvement(newVal);
                }
            }
        },
        data() {
            return {
                annulationtotale: {
                    date_mouvement: undefined,
                    motif: undefined,
                },
                table: {
                    headers: [
                        {
                            text: "Catégorie de capital",
                            sortable: false,
                            value: "libelle",
                            class: "text-left",
                            showInBrouillon: true,
                        },
                        {
                            text: "Nombre de parts",
                            sortable: false,
                            value: "nb_parts",
                            class: "text-right",
                            showInBrouillon: true,
                        },
                        {
                            text: "Valeur de remboursement",
                            sortable: false,
                            value: "valeur_remboursement",
                            class: "text-right",
                            showInBrouillon: false,
                        },
                        {
                            text: "Montant",
                            sortable: false,
                            value: "montant",
                            class: "text-right",
                            showInBrouillon: false,
                        },
                    ],
                },
                annulationtotaleinformations: {
                    categories: [],
                },
                noDataText: undefined,
                noDataTextCases: {
                    default: "Veuillez sélectionner un(e) associé(e) et une date de mouvement.",
                    noPart: "Aucune part détenue par cet(te) associé(e).",
                },
            };
        },
        mounted() {
            this.noDataText = this.noDataTextCases.default;
        },
        computed: {},
        methods: {
            onUpdateSelectedAssocie(eventValue) {
                this.annulationtotale.associe_id = eventValue;
                this.updateDataTable()
            },
            onUpdateDateMouvement(eventValue) {
                this.annulationtotale.date_mouvement = eventValue;
                this.updateDataTable()
            },
            updateDataTable() {
                this.isButtonDisabled = (_.isNil(this.annulationtotale.associe_id) || _.isNil(this.annulationtotale.date_mouvement) || "" === this.annulationtotale.date_mouvement);
                if (!this.isButtonDisabled) {
                    internalApi.annulationtotale
                        .getByAssocieAndDate(this.annulationtotale.associe_id, this.annulationtotale.date_mouvement)
                        .then(res => {
                            this.annulationtotaleinformations = res;
                            if (this.annulationtotaleinformations.categories.length === 0) {
                                this.noDataText = this.noDataTextCases.noPart;
                            }
                        });
                } else {
                    this.annulationtotaleinformations = {
                        categories: []
                    };
                    this.noDataText = this.noDataTextCases.default;
                }
            },
            computeHeaders() {
                const isBrouillon = this.isBrouillon;
                return _.filter(this.table.headers, function (h) {
                    return ((isBrouillon && h.showInBrouillon) || !isBrouillon)
                })
            }
        }
    };
</script>
